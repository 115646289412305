import { useEffect, useState, useContext } from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";
import { GraduationCap, BriefcaseBusiness, IndianRupee } from "lucide-react";
import Authcontext from "../Context/Auth/AuthContext";
import { useNavigate } from "react-router-dom";

const ViewDoctorProfile = () => {
  const { id } = useParams();
  const host = process.env.REACT_APP_Backend_URL;
  const { BookingData, setBookingData } = useContext(Authcontext);
  const navigate = useNavigate();
  const [doctor, setDoctor] = useState({
    user: {
      name: "",
    },
    Qualifications: [],
    profilepic: "",
    speciality: "",
    experience: "",
    workingClinics: [],
    about: "",
    Treatments: [],
    Procedures: [],
    WorkExperience: [],
  });
  const fetchDoctor = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/doctordetails?doctorId=${id}`
      );
      setDoctor(response);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDoctor();
  }, []);

  const submitBooking = () => {
    setBookingData({
      ...BookingData,
      doctor: doctor._id,
      date: "",
      slot: "",
      paymentcost: "",
      saved: false,
    });
    navigate("/book-appointment");
  };

  const [isFooterVisible, setIsFooterVisible] = useState(false);

  const checkFooterVisibility = () => {
    const footer = document.getElementsByTagName("footer")[0];
    // console.log(footer);
    if (footer) {
      const footerTop = footer.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;
      setIsFooterVisible(scrollPosition >= footerTop);
      // console.log(scrollPosition>=footerTop)
      // console.log(isFooterVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkFooterVisibility);
    window.addEventListener("resize", checkFooterVisibility);
    checkFooterVisibility();
    return () => {
      window.removeEventListener("scroll", checkFooterVisibility);
      window.removeEventListener("resize", checkFooterVisibility);
    };
  }, [doctor]);

  return (
    <div className="min-h-[100vh] flex flex-col relative">
      <Navbar />
      <div
        className={`${
          isFooterVisible ? `absolute bottom-[420px] z-2` : `fixed top-[150px]`
        } border-2 z-20  border-blue rounded-xl rounded-br-none bg-whiteshade py-8 px-6  right-[5%] w-[350px] hidden custom:block`}
      >
        <h1 className="text-2xl font-bold text-black">
          Book Appointment with{" "}
        </h1>
        <h1 className="text-2xl font-bold text-black">{doctor.user.name}</h1>
        <div className="py-4 flex gap-4 flex-col">
          <input
            type="text"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            value={BookingData.PatientName}
            onChange={(e) => {
              setBookingData({ ...BookingData, PatientName: e.target.value });
            }}
            placeholder="Name"
          />
          <input
            type="email"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            placeholder="Email"
            onChange={(e) => {
              setBookingData({ ...BookingData, email: e.target.value });
            }}
            value={BookingData.email}
          />
          <input
            type="text"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            placeholder="Phone Number"
            onChange={(e) => {
              setBookingData({ ...BookingData, phonenumber: e.target.value });
            }}
            value={BookingData.phonenumber}
          />
          {/* <input type="date" min={new Date().toISOString().split('T')[0]} max={new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0]}  className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
          onChange={
            (e)=>{setBookingData({...BookingData,date:e.target.value})}
          }  
          value={BookingData.date}
          placeholder="Select date"/> */}
          <div className="flex items-center gap-2">
            <input type="checkbox" className="w-4 h-4" />
            <label className="text-sm">
              I agree to the Hale Clinics terms and conditions & Privacy Policy
            </label>
          </div>
          <button
            onClick={submitBooking}
            className="bg-blueshade text-center text-white font-semibold p-2 rounded-lg"
          >
            Book Appointment
          </button>
        </div>
      </div>
      <div className="bg-blueshade">
        <div className="flex sm:flex-row flex-col sm:items-start sm:justify-start items-center justify-center  gap-2 w-[90%] mx-auto pb-1 p-6 relative">
          <div className="sm:absolute top-10">
            <img
              src={doctor.profilepic}
              className="rounded-xl   rounded-br-none lg:w-[150px] h-[175px]   w-[150px] xl:w-[180px] object-cover"
              alt=""
            />
          </div>
          <div className="rounded-xl sm:block hidden  rounded-br-none lg:w-[150px] lg:h-full w-[150px] xl:w-[180px] "></div>
          <div className="p-4 flex flex-col gap-1 sm:items-start sm:justify-start items-center justify-center h-[60%]">
          <h1 className="xl:text-4xl text-3xl font-bold text-white whitespace-nowrap">
  {doctor.user.name}
</h1>

            <h1 className="xl:text-3xl text-2xl font-bold text-white ">
              {doctor.speciality}
            </h1>
            {doctor.Qualifications.map((qualification, index) => (
              <div key={index} className="flex gap-2">
                <p className="text-lg  text-white    ">
                  {qualification.course} {qualification.speciality ? ` - ${qualification.speciality}` : ""} 
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-2 w-[90%] mx-auto pb-1 p-6 pt-0 relative">
          <div className="rounded-xl sm:block hidden  rounded-br-none lg:w-[150px] lg:h-full w-[150px] xl:w-[180px] "></div>
          <div className="flex gap-2 p-2 sm:w-[50%] w-[100%]  flex-wrap   ">
            <div className="flex flex-col gap-3 mr-6">
              <div className="flex gap-2  items-center">
                <img src="/assests/verified.svg" alt="" />
                <p className="font-semibold xl:text-lg lg:text-md text-sm ">
                  Medical Registration Verified
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <img src="/assests/experience.svg" alt="" />
                <p className="font-semibold xl:text-lg lg:text-md text-sm ">
                  {doctor.experience} Years Experience
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 items-center">
                <img src="/assests/location.svg" alt="" />

                <div className="flex  gap-2">
                  {doctor.workingClinics.map((clinics, index) => (
                    <p
                      key={index}
                      className="font-semibold xl:text-lg lg:text-md text-sm "
                    >
                      {clinics.clinic.location}
                    </p>
                  ))}
                </div>
              </div>
              <div className="flex gap-2 items-center">
                {/* <img src="/assests/fee.svg" alt="" /> */}
                <IndianRupee size={20} color="#f17a20" />
                <p className="font-semibold xl:text-lg lg:text-md text-sm ">
                  Consultation Fee : ₹500
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  gap-2 flex-col w-[90%] mx-auto pb-6 p-6 pt-0">
        <hr className="border-t-2 border-lightshade sm:w-[60%] w-[100%]" />
        <div className="sm:w-[60%] gap-6 w-[100%] flex flex-col">
          <div className="flex flex-col  ">
            <div className="flex gap-2">
              <GraduationCap color="#1651C6" size={33} />

              <h1 className="xl:text-2xl text-2xl font-bold text-black ">
                Qualifications
              </h1>
            </div>
            <div className="flex gap-2">
              <p className="text-lg pl-10 text-black">
              {doctor.Qualifications.map((qualification, index) => (
              <div key={index} className="flex gap-2">
                <p className="text-lg text-black    ">
                  {qualification.course} {qualification.speciality ? ` - ${qualification.speciality}` : ""} 
                </p>
              </div>
            ))}
              </p>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="flex gap-2">
              <BriefcaseBusiness color="#1651C6" size={33} />

              <h1 className="xl:text-2xl text-2xl font-bold text-black ">
                Experience
              </h1>
            </div>
            <p className="text-lg pl-10   text-black    ">
              {doctor.experience} Years Experience as {doctor.speciality}
            </p>
          </div>
          <div className="flex flex-col ">
            <div className="flex gap-2">
              <GraduationCap color="#1651C6" size={33} />

              <h1 className="xl:text-2xl text-2xl font-bold text-black ">
                Practicing At
              </h1>
            </div>
            {doctor.WorkExperience.map((experience, index) => (
              <div key={index} className="flex gap-2">
                <p className="text-lg pl-10   text-black    ">
                  {experience.Place}
                </p>
              </div>
            ))}
          </div>
        </div>
        <hr className="border-t-2 border-lightshade sm:w-[60%] w-[100%]" />
        <div className="custom:w-[60%] gap-4 w-[100%] flex flex-col">
          <h1 className="xl:text-3xl text-2xl font-bold text-black ">
            About Doctor
          </h1>
          <p className="w-[100%]">{doctor.about}</p>
        </div>
        <hr className="border-t-2 border-lightshade sm:w-[60%] w-[100%]" />
        <div className="w-full custom:w-[60%] gap-6 flex flex-col ">
          <h1 className="text-2xl xl:text-3xl font-bold text-black">
            Availability Info
          </h1>
          {doctor.workingClinics.map((clinicData, clinicIndex) => (
            <div
              className="grid grid-cols-1 sm:grid-cols-3 gap-4 bg-white py-2 rounded-lg sm:shadow-none shadow-lg p-4 sm:p-0"
              key={clinicIndex}
            >
              {/* Clinic Name and Location */}
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-black">
                  {clinicData.clinic.clinicname}
                </p>
                <p className="text-sm text-gray-500">
                  {clinicData.clinic.location}
                </p>
              </div>
              {/* Working Days and Times */}
              <div className="flex flex-col gap-2">
                {Object.entries(clinicData.workingdays)
                  .filter(([_, times]) => times.length > 0) // Only include days with available times
                  .map(([day, times], dayIndex) => (
                    <div key={dayIndex} className="flex items-start">
                      {/* Day Name */}
                      <p
                        className="font-medium text-sm sm:text-base"
                        style={{ width: "120px" }}
                      >
                        {day}:
                      </p>

                      {/* Times */}
                      <div className="flex flex-col gap-1">
                        {times.map((time, timeIndex) => (
                          <p key={timeIndex} className="text-sm text-gray-800">
                            {time.startTime} - {time.endTime}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>

              {/* Fee Section */}
              <div className="flex  justify-start ">
                <p className="text-lg font-semibold text-black">
                  Fee: ₹{clinicData.Consultationfee}
                </p>
              </div>
            </div>
          ))}
        </div>

        <hr className="border-t-2 border-lightshade sm:w-[60%] w-[100%]" />
        <div className="sm:w-[60%] gap-4 w-[100%] flex flex-col">
          <h1 className="xl:text-3xl text-2xl font-bold text-black ">
            Treatments Offered
          </h1>
          <ul className="list-disc list-inside">
            {doctor.Procedures.length !== 0 &&
              doctor.Procedures.map((treatment, index) => (
                <li key={index} className="text-lg    text-black    ">
                  {treatment}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className="custom:hidden block w-[100%] h-[50px] "
        style={{ position: "fixed", bottom: 0 }}
      >
        <button
          onClick={submitBooking}
          className="bg-orangeshade w-full text-white text-xl font-bold h-full "
        >
          Book Appointment
        </button>
      </div>
      <Footer />
      <div className="custom:hidden block w-[100%] h-[50px] "></div>
    </div>
  );
};

export default ViewDoctorProfile;
