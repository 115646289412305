import "./DoctorCard.css";
import { Link } from "react-router-dom";



const DoctorCard = (props) => {
  const { doctor } = props;
  return (
    <div className="doctorcard rounded bg-white p-5 items-start ">
      <img className=" xl:h-[116px] xl:w-[116px] sm:h-[200px] sm:w-[200px] h-[89px] w-[89px] rounded rounded-br-none rounded-tr-none" alt="" src={doctor.profilepic}/>
      <div className="doctorcontent-wrapper">
        <h1 className="text-blueshade font-bold text-[18px] sm:text-[26px]">{doctor.user.name}</h1>
        <h4 className="font-bold  text-[16px] sm:text-[20px]">{doctor.speciality}</h4>
        <div className="flex gap-2 items-center">
            <img src="/assests/experience.svg" alt="" className="h-5 w-5" />
            <p className=" xl:text-lg lg:text-md text-sm ">
              {doctor.experience} Years Experience
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <img src="/assests/location.svg" alt="" className="h-5 w-5" />
            
            <div className="flex  gap-2">
              <p  className=" xl:text-lg lg:text-md text-sm ">
                {doctor?.workingClinics[0]?.clinic?.location}
              </p>
          
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <img  src="/assests/fee.svg" alt="" className="h-5 w-5" />
            <p className=" xl:text-lg lg:text-md text-sm ">
              Consultation Fee : <b>{doctor?.workingClinics[0]?.Consultationfee}</b>
            </p>
          </div>
        <div className="flex gap-2 flex-row  flex-wrap mr-3 ">
        
        <Link to={`/doctors/${doctor._id}`} className="bg-[#1651C6] xl:text-lg lg:text-md text-sm w-[150px]  text-white text-center p-1 rounded-lg rounded-br-none">
            View Profile
          </Link>
          <Link to="/" className="bg-[#1651C6] xl:hidden sm:block hidden  xl:text-lg lg:text-md text-sm  w-[150px]  text-white text-center p-1 rounded-lg rounded-br-none">
            Book 
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
