import React from "react";
import { Link } from "react-router-dom";


const BlogCard = ({ blog }) => {
    return (
        <Link className="flex flex-col rounded-3xl  items-center justify-stretch cursor-pointer  border-blue border-2" to={`/blogs/${blog._id}`}>
        {/* <img src= alt={blog.title} /> */}
        <img src={blog.Image} className="rounded-3xl h-[250px] w-[100%] rounded-b-none" alt="blog" />
        <h3 className="text-xl font-bold break-all flex-1 bg-blueshade text-white text-center p-3 w-[100%]">{blog.Title.length>60 
        ? `${blog.Title.slice(0, 60)}.....`
        : blog.Title
         }</h3>
        <p className="text-center p-3 text-lg break-all whitespace-normal">{blog.Description.length > 150
            ? `${blog.Description.slice(0, 250)}.....`
            : blog.Description} </p>
        </Link>
    );
}

export default BlogCard;