import React, { useEffect } from "react";
import { useContext ,useState} from "react";
import AuthContext from "../../Context/Auth/AuthContext";
import {
    makeUnauthenticatedPOSTRequest
  } from "../../Services/Servicehelper";
import { toast } from "react-toastify";

const Login = () => {   

    const {role,getuser}= useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if(role === "Admin"){
            window.location.href = "/admin/dashboard";
        }
    }, [role]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const response = await fetch("http://localhost:5000/api/auth/login", {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({email,password})
        // });
        // const json = await response.json();
        // localStorage.setItem('token',json.data.token);
        // console.log(json);
        // getuser();
        const host = process.env.REACT_APP_Backend_URL;
        const response = await makeUnauthenticatedPOSTRequest(`${host}/api/auth/login`, { email, password });
        console.log(response);
        if (response.status==="success") {
            localStorage.setItem('token', response.data.token);
            getuser();
        } else {
            toast.error(response.message);
        }
    }


    return (
        <div className="bg-blueshade min-h-[100vh] flex items-center justify-center">
            <div className="w-[400px] h-[300px] bg-whiteshade rounded-lg shadow-sm flex items-center justify-between  flex-col p-4">
                <h1 className="text-2xl font-bold">Admin Login</h1>
                <input type="email" onChange={(e)=>{setEmail(e.target.value)}} className="w-[100%] h-[60px] bg-[#c0c2c2] rounded-lg p-3 my-2 "placeholder="Email" />
                <input type="password" onChange={(e)=>{setPassword(e.target.value)}} className="w-[100%] h-[60px] bg-[#c0c2c2] rounded-lg p-3 my-2 "placeholder="Enter Password" />
                <button onClick={handleSubmit} className="w-[100%] h-[60px] bg-blueshade rounded-lg text-white text-xl p-3 my-2 font-bold">Login</button>
            </div>
        </div>
    );
}

export default Login;