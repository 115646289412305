import React from "react";
import { Facebook, Instagram, Youtube } from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-indigo pt-12 mt-auto  ">
      <div className="p-6 xl:px-0  max-w-6xl w-full mx-auto">
        <img
          src="/assests/Logo1.svg"
          alt="Hale Clinics"
          className="w-48 md:w-64 mb-4"
        />
        <div className="flex items-start justify-start custom:gap-14 gap-8 custom:flex-row flex-col">
          <div className="w-[100%] custom:w-[440px]">
            <p className="text-white text-[17px]  text-left">
              Hale Clinics, a medical co-working spaces company for doctors,
              focuses mainly on individual medical practitioners helping them
              build a sustainable business ecosystem with all the essence of the
              own clinic without actually owning a clinic. we are running
              successfully in 7 Locations in Hyderabad with 50+ Doctors And
              institutions partnered with us.
            </p>
            
          </div>
          <div className=" custom:mx-auto grid grid-cols-1 custom1:grid-cols-3 gap-6 w-[100%] custom:w-[50%] ">
            <div className="flex flex-col items-start">
              <p
                className="text-white mb-4 font-bold sm:mb-2"
              >
                Haleclinics
              </p>
              <div className="">
                <Link to="/" className="text-white block mb-2 hover:underline">
                  Home
                </Link>
                <Link to="/treatments" className="text-white block mb-2 hover:underline">
                  Treatments
                </Link>
                <Link to="/doctors" className="text-white block mb-2 hover:underline">
                  Our Doctors
                </Link>
                <Link to="/contact" className="text-white block mb-2 hover:underline">
                  Contact Us
                </Link>
                <Link to="/for-doctors" className="text-white block mb-2 hover:underline">
                  For Doctors
                </Link>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start">
                <h1 className="text-white mb-4 font-bold sm:mb-2">Menu</h1>
                <div className="">
                  <Link to="/blogs" className="text-white block mb-2 hover:underline">
                    Blog
                  </Link>
                  <Link to="/" className="text-white block mb-2 hover:underline">
                    Careers
                  </Link>
                  <Link to="/" className="text-white block mb-2 hover:underline">
                    FAQs
                  </Link>
                  {/* <Link to="/" className="text-white block mb-2 hover:underline">
                    Pricing
                  </Link> */}
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-white mb-4 font-bold sm:mb-2">Contact</h1>
              <div className="flex flex-col items-start">
                <a href="tel:8800644744" className="text-white mb-2">+91 8800644744</a>
                <a href="https://mail.google.com/mail/u/0/?view=cm&amp;fs=1&amp;tf=1&amp;source=mailto&amp;to=info@haleclinics.in" target="_blank" className="text-white mb-2">info@haleclinics.in</a>
                <div className="flex gap-4">
          <a href="https://www.instagram.com/" target="_blank" ><Instagram color="white" /></a>
          <a href="https://www.facebook.com/" target="_blank"><Facebook color="white" /></a>
          <a href="https://www.youtube.com/" target="_blank"><Youtube color="white" /></a>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F6F8FF] ">
        <div className="max-w-6xl w-full mx-auto flex p-3 xl:px-0 justify-center xl:justify-between items-center  flex-wrap gap-5">
        <p className="  text-[#353535]">© 2024 Hale Clinics. All Rights Reserved</p>
        <div>

        <a className=" text-[#353535]" href="/">
          Privacy Policy
        </a>
        <span  className="  text-[#353535]"> / </span>
        <a className="  text-[#353535]" href="/">
          Terms of Service
        </a>
        </div>
        <p className="  text-[#353535] ">
              A Product Of Hale Life Sciences Pvt Ltd
            </p>
            </div>
      </div>
    </footer>
  );
};

export default Footer;
