import React, { useRef } from "react";
import "./Testimonials.css";
import Testimonial from "./Testimonial";

const Testimonials = (props) => {
  const containerRef = useRef(null);
  const { data } = props;

  return (
    <div className="container-content" ref={containerRef}>
      <div className="testimonial-items">
        {data.map((item, index) => (
          <Testimonial
            key={index}
            author={item.name}
            text={item.content}
            rating={item.rating}
          />
        ))}
      </div>
      <div className="testimonial-items">
        {data.map((item, index) => (
          <Testimonial
            key={index}
            author={item.name}
            text={item.content}
            rating={item.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
