import "./SpecalityCard.css";
import { useContext } from "react";
import Authcontext from "../../Context/Auth/AuthContext";
import { Link } from "react-router-dom";

const SpecalityCard = (props) => {
  const { treatment } = props;
  const { setSearchvalue } = useContext(Authcontext);
  const handleonclick = (name) => {
    setSearchvalue(name);
  };

  return (
    // <div className="specalitycard">
    //   <div className="img-wrapper">
    //     <img className="image-3" alt="" src="/assests/card.png" />
    //   </div>
    //   <div className="specalitycontent-wrapper">
    //     <p className="text-xl font-bold" style={{ textAlign: "center" }}>{name}</p>
    //     <Link 
    //       to="/doctors"
    //       onClick={() => handleonclick(name)}
    //     >
    //       <img alt="" src="/assests/arrow.svg" />
    //     </Link>
    //   </div>
    // </div>
    <Link to="/doctors" onClick={() => handleonclick(treatment.Specialityname)} className="flex p-2 flex-row items-center  rounded-br-none gap-3 bg-white pr-3  rounded-[20px] border border-[#1651C6] hover:bg-whiteshade cursor-pointer">
        <img src={treatment.Logo} alt={treatment.Specialityname} className="h-20 object-cover  " />
        <div className='mx-1'>
        <h1 className="text-[20px] font-bold my-2">{treatment.Specialityname}</h1>
        <p className=" text-[15px] mb-3 ">{treatment.Description}</p>
        </div>
        </Link>
  );
};

export default SpecalityCard;
