import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../Context/Auth/AuthContext";
import { useHistory } from "react-router-dom";
import Navbar from "../Components/HomePageComponents/Navbar";
import BookDoctorCard from "../Components/DoctorPageComponents/BookDoctorCard";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  makeUnauthenticatedGETRequest,
  makeAuthenticatedGETRequest,
  makeAuthenticatedPOSTRequest,
} from "../Services/Servicehelper";
import Authcontext from "../Context/Auth/AuthContext";
import Footer from "../Components/HomePageComponents/Footer";
import { toast } from "react-toastify";
import BookingConfiration from "../Components/AppointmentComponents/BookingConfirmation";
import Loader from "../Components/Loader";
import { LineWave } from "react-loader-spinner";

const BookAppointment = () => {
  const { role, BookingData, setBookingData } = useContext(Authcontext);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  const id = BookingData.doctor;
  const host = process.env.REACT_APP_Backend_URL;
  const [slots, setSlots] = useState([]);
  const [dates, setDates] = useState([]);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [BookingConfirmationModal, setBookingConfirmationModal] =
    useState(false);
  const [doctor, setDoctor] = useState({
    user: {
      name: "",
    },
    Qualifications: [],
    profilepic: "",
    speciality: "",
    experience: "",
    workingClinics: [],
    about: "",
    Treatments: [],
  });
  const [confirmationdata, setConfirmationData] = useState({});

  const toggleConfirmation = () => {
    setBookingConfirmationModal(!BookingConfirmationModal);
  };

  const fetchDoctor = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/doctordetails?doctorId=${id}`
      );
      console.log(response);
      setDoctor(response);
      if (!BookingData.saved) {
        setBookingData({
          ...BookingData,
          clinic: response.workingClinics[0]?.clinic._id,
          date: formatdate(new Date()),
          paymentcost: response.workingClinics[0]?.Consultationfee,
          slot: "",
        });
      } else {
        setBookingData({
          ...BookingData,
        });
      }
      // console.log(BookingData);
      // setBookingData({ ...BookingData, clinic: response.workingClinics[0]?.clinic._id });
      // if (!BookingData.clinic) {
      //   setBookingData((prevData) => ({
      //     ...prevData,
      //     clinic: response.workingClinics[0]?.clinic._id,
      //   }));
      // }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const formatdate = (date) => {
    // console.log(date);
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    // console.log(newDate);
    return newDate;
    // setBookingData({ ...BookingData, date: newDate });
  };

  const fetchslots = async () => {
    if (!BookingData.date || !BookingData.clinic) {
      // alert('Please select date and clinic to view slots');
      return;
    }
    setSlotsLoading(true);
    try {
      setBookingData({ ...BookingData, slot: "" });
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/slots?doctor=${id}&clinic=${BookingData.clinic}&date=${BookingData.date}`
      );
      // console.log(response);
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const availableslots = response.data.filter((slot) => {
        if (slot.status !== "Available") return false;

        const [time, period] = slot.time.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (period.toLowerCase() === "pm" && hours !== 12) {
          hours += 12;
        } else if (period.toLowerCase() === "am" && hours === 12) {
          hours = 0;
        }

        if (BookingData.date === formatdate(new Date())) {
          return (
            hours > currentHours ||
            (hours === currentHours && minutes > currentMinutes)
          );
        }
        return true;
      });

      setSlots(availableslots);
      // const availableslots = response.data.filter(
      //   (slot) => slot.status === "Available"
      // );
      // setSlots(availableslots);
      setSlotsLoading(false);
    } catch (error) {
      console.log(error);
      setSlotsLoading(false);
    }
  };

  useEffect(() => {
    // const token=localStorage.getItem('token');
    // if (!token) {
    //   alert("You need to login as a patient to book an appointment");
    //   navigate("/login");
    // }
    if (role != "Patient") {
      toast.warn("You need to login as a patient to book an appointment");
      navigate("/login");
    }
    if (!BookingData.doctor) {
      navigate("/doctors");
      return;
    }
    // const savedBookingData = JSON.parse(localStorage.getItem("BookingData"));
    // if (BookingData.saved) {
    //   setBookingData(savedBookingData);
    //   localStorage.removeItem("BookingData");
    // } else {
    //   setBookingData((prevData) => ({
    //     ...prevData,
    //     date: formatdate(new Date()),
    //   }));
    // }

    for (let i = 0; i < 10; i++) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      setDates((dates) => [...dates, date]);
    }

    fetchDoctor();
    // console.log(BookingData);
  }, [BookingData.doctor]);

  useEffect(() => {
    fetchslots();
    const selectedClinic = doctor.workingClinics.find(
      (clinic) => clinic.clinic._id === BookingData.clinic
    );
    if (selectedClinic) {
      setBookingData((prevData) => ({
        ...prevData,
        paymentcost: selectedClinic.Consultationfee,
        clinic: selectedClinic.clinic._id,
      }));
    } else {
      setBookingData((prevData) => ({
        ...prevData,
        paymentcost: doctor.workingClinics[0]?.Consultationfee,
        clinic: doctor.workingClinics[0]?.clinic._id,
      }));
    }
  }, [BookingData.date, BookingData.clinic]);

  // useEffect(() => {

  //   fetchslots();
  //   const selectedClinic = doctor.workingClinics.find(
  //     (clinic) => clinic.clinic._id === BookingData.clinic
  //   );
  //   // console.log(BookingData)
  //   if (selectedClinic) {
  //     // setBookingData({...BookingData,paymentcost:selectedClinic.Consultationfee,clinic:selectedClinic.clinic._id});
  //     setBookingData((prevData) => ({
  //       ...prevData,
  //       paymentcost: selectedClinic.Consultationfee,
  //       clinic: selectedClinic.clinic._id,
  //     }));
  //   }
  //   // console.log(BookingData);
  // }, [BookingData.date, BookingData.clinic, doctor.workingClinics]);

  // useEffect(() => {
  //   console.log(BookingData);
  // }, [BookingData]);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const bookAppointment = async () => {
    if (!token) {
      // localStorage.setItem("BookingData", JSON.stringify(BookingData));
      toast.warn("You need to login as a patient to book an appointment");
      setBookingData({ ...BookingData, saved: true });
      navigate("/login");
      return;
    }
    if (
      !BookingData.PatientName ||
      !BookingData.phonenumber ||
      !BookingData.clinic ||
      !BookingData.date ||
      !BookingData.slot ||
      !BookingData.paymentcost
    ) {
      console.log(BookingData);
      toast.error("Please fill all the details");
      return;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(BookingData.phonenumber)) {
      toast.error("Please Enter Valid Phone Number");
      return;
    }
    setBookingLoading(true);
    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/patient/appointment`,
        BookingData,
        token
      );
      console.log(response);
      if (response.status === "success") {
        setConfirmationData(response.data);
        toggleConfirmation();
        toast.success("Appointment Booked Successfully");
      }
      setBookingLoading(false);
      // navigate("/patient/appointments");
      // window.location.href = "/";
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
      setBookingLoading(false);
    }
  };

  const btnnext = () => {
    let datesslider = document.querySelector(".datesslider");
    let dateboxes = document.querySelectorAll(".datebox");
    datesslider.scrollLeft =
      datesslider.scrollLeft + dateboxes[0].clientWidth + 20;
  };

  const btnprev = () => {
    let datesslider = document.querySelector(".datesslider");
    let dateboxes = document.querySelectorAll(".datebox");
    datesslider.scrollLeft =
      datesslider.scrollLeft - dateboxes[0].clientWidth - 20;
  };

  return (
    <div>
      {role === "Patient" && (
        <div>
          <Navbar />
          <div>
            {!bookingLoading && (BookingConfirmationModal && (
              <BookingConfiration
                open={toggleConfirmation}
                details={confirmationdata}
              />
            ))}
          </div>
          {bookingLoading && (
            <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 flex justify-center items-center backdrop-blur-sm">
              <Loader color="#FF6B3B" />
            </div>
          )}
          <div className="px-6 py-4 my-10 mx-auto mb-6 max-w-6xl w-full">
            <h1 className="text-2xl font-bold mb-2">Book Your Appointment</h1>
            <BookDoctorCard bookingbtn={false} doctor={doctor} />
            {/* <div>
              <h1 className="text-xl font-bold mb-2">Doctor Details</h1>
              <div className="flex sm:flex-row flex-col items-center">
                <img
                  src={doctor.profilepic}
                  className="rounded-xl   rounded-br-none lg:w-[150px]   w-[150px] xl:w-[180px] object-cover"
                  alt=""
                />
                <div className="my-auto flex sm:items-start items-center flex-col ">
                  <h1 className="sm:text-xl text-md font-bold text-blueshade">
                    {doctor.user.name}
                  </h1>
                  <h1 className="sm:text-xl text-md font-bold text-blueshade">
                    {doctor.speciality}
                  </h1>
                  <h1 className="sm:text-xl text-md font-bold text-blueshade block">
                    Locations:{" "}
                  </h1>
                  <div className="flex flex-row items-center">
                    {doctor.workingClinics.map((clinic, index) => (
                      <h1
                        key={index}
                        className="sm:text-xl text-md font-bold text-blueshade"
                      >
                        {clinic.clinic.location}
                      </h1>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="mt-10 flex flex-col gap-2 lg:flex-row justify-between">
              <div className="lg:w-[30%] my-6 flex gap-2 flex-col ">
                <div>
                  <h1 className="text-xl font-bold mb-2">Patient Name:</h1>
                  <input
                    type="text"
                    className="border border-gray-900 p-2 rounded-md w-full"
                    value={BookingData.PatientName}
                    onChange={(e) =>
                      setBookingData({
                        ...BookingData,
                        PatientName: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <h1 className="text-xl font-bold mb-2">
                    Patient Email(Optional):
                  </h1>
                  <input
                    type="email"
                    className="border border-gray-900 p-2 rounded-md w-full"
                    value={BookingData.email}
                    onChange={(e) =>
                      setBookingData({ ...BookingData, email: e.target.value })
                    }
                  />
                </div>
                <div>
                  <h1 className="text-xl font-bold mb-2">Patient Phone:</h1>
                  <input
                    type="text"
                    className="border border-gray-900 p-2 rounded-md w-full"
                    value={BookingData.phonenumber}
                    onChange={(e) =>
                      setBookingData({
                        ...BookingData,
                        phonenumber: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <h1 className="text-xl font-bold mb-2"> Select Clinic :</h1>
                  <select
                    name="Location"
                    className="border border-gray-900 p-2  rounded-md w-full"
                    value={BookingData.clinic}
                    onChange={(e) =>
                      setBookingData({ ...BookingData, clinic: e.target.value })
                    }
                  >
                    {/* <option value="">Select Clinic</option> */}
                    {doctor.workingClinics.map((clinic, index) => (
                      <option key={index} value={clinic.clinic._id}>
                        {clinic.clinic.location}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="md:w-[40%] md:mx-auto md:my-auto flex justify-center flex-col md:items-center">
                <h1 className="text-xl font-bold mb-2">Select Date:</h1>

                <Calendar
                  onChange={(value) => {
                    formatdate(value);
                  }}
                  value={BookingData.date ? new Date(BookingData.date) : new Date()}
                />
              </div> */}
              <div className="lg:w-[60%] my-auto flex justify-center items-start flex-col">
                <div className="flex flex-row w-full items-center justify-center my-6 relative">
                  <div onClick={btnprev}>
                    <ChevronLeft />
                  </div>
                  <div className="flex-grow flex flex-row no-scrollbar overflow-x-scroll overflow-hidden scroll-smooth gap-[20px] datesslider">
                    {dates.map((date, index) => (
                      <div
                        onClick={() => {
                          setBookingData({
                            ...BookingData,
                            date: formatdate(date),
                          });
                        }}
                        key={index}
                        className={`p-2 datebox cursor-pointer flex flex-col justify-center items-center min-w-[70px] h-[70px] rounded-lg border border-black ${
                          BookingData.date === formatdate(date)
                            ? "bg-blueshade text-white"
                            : "bg-whiteshade"
                        }`}
                      >
                        <h1 className="text-lg font-bold">{date.getDate()}</h1>
                        <p>{days[date.getDay()]}</p>
                      </div>
                    ))}
                  </div>
                  <div onClick={btnnext}>
                    <ChevronRight />
                  </div>
                </div>
                <hr className="border-1 w-full mb-3 border-black" />

                <h1 className="text-xl font-bold mb-2">Avialable Slots</h1>
                <div className="w-full h-[200px] no-scrollbar border rounded-lg flex flex-wrap items-center overflow-y-scroll border-black mb-2 gap-2 p-2">
                  {slotsLoading && (
                    <div className="flex w-full justify-center items-center">
                      <LineWave
                        visible={true}
                        height="150"
                        width="150"
                        color="#FF6B3B"
                        ariaLabel="line-wave-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        firstLineColor=""
                        middleLineColor=""
                        lastLineColor=""
                      />
                    </div>
                  )}
                  {!slotsLoading &&
                    (slots.length == 0 ? (
                      <h1 className="text-lg text-center  w-full  mb-2">
                        Select Date And Clinic / No Slots Available on Selected
                        Clinic and Date{" "}
                      </h1>
                    ) : (
                      slots.map((slot, index) => (
                        <div
                          onClick={() => {
                            setBookingData({ ...BookingData, slot: slot._id });
                          }}
                          key={index}
                          className={`flex flex-row  cursor-pointer p-2 rounded-lg  border border-blueshade ${
                            BookingData.slot === slot._id
                              ? "bg-blueshade text-white"
                              : "bg-whiteshade"
                          }`}
                        >
                          <h1 className="text-md ">{`${parseInt(
                            slot.time.split(" ")[0].split(":")[0],
                            10
                          )}:${
                            slot.time.split(" ")[0].split(":")[1]
                          } ${slot.time.split(" ")[1].toUpperCase()}`}</h1>
                        </div>
                      ))
                    ))}
                </div>

                <div className="flex flex-row gap-2">
                  <h1 className="text-xl font-bold mb-2">Consultation Fee:</h1>

                  <h1 className="text-xl font-bold text-blueshade">
                    {BookingData.paymentcost}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-6xl w-full  justify-end mx-auto my-4 px-6 ">
            <button
              onClick={bookAppointment}
              className="bg-[#FF6B3B] text-white px-4 py-2 rounded-md block"
            >
              Book Appointment
            </button>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default BookAppointment;
