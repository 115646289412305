import "./Testimonial.css";

const Testimonial = (props) => {
  const { author, text, rating } = props;

  const stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(
      <img key={i} style={{ width: "30px",display:"inline-block" }} src="/assests/star.svg" alt="star" />
    );
  }

  return (
    <div className="testimonial">
      <div className="testimonial__content">
        <h3 className="text-blueshade text-center font-bold text-2xl"> {author}</h3>
        <p className="font-bold text-center my-2">"{text}"</p>
      </div>
      <div className="rating">
        <p className="text-center">{stars}</p>
      </div>
    </div>
  );
};

export default Testimonial;
