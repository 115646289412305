import React,{useState,useEffect} from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";

const ViewBlog = () => {
const [isFooterVisible, setIsFooterVisible] = useState(false);
const host = process.env.REACT_APP_Backend_URL;
  let { id } = useParams();

  const [Blog, setBlog] = useState({
    Heading: "",
    Description: "",
    Content: "",
  });

const checkFooterVisibility = () => {
  const footer = document.getElementsByTagName('footer')[0];
  if (footer) {
    const footerTop = footer.getBoundingClientRect().top; 
      const isVisible = footerTop <= window.innerHeight;
      setIsFooterVisible(isVisible);
  }
};

useEffect(() => {
  window.addEventListener('scroll', checkFooterVisibility);
  window.addEventListener('resize', checkFooterVisibility);
  checkFooterVisibility();

  return () => {
    window.removeEventListener('scroll', checkFooterVisibility);
    window.removeEventListener('resize', checkFooterVisibility);
  };
}, [Blog]);

const getblog = async () => {
  try {
    const response = await makeUnauthenticatedGETRequest(
      `${host}/api/auth/getblogdetails?blogid=${id}`
    );
    console.log(response);
      
    if (response) {
      setBlog(response.data);
      checkFooterVisibility();
    }
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  getblog();
}, []);


  return (
    <div className="min-h-[100vh] flex flex-col relative">
      <Navbar />
      <div
        className={`${
          isFooterVisible ? `absolute bottom-[420px] z-2` : `fixed top-[150px]`
        } border-2  border-blue rounded-xl rounded-br-none bg-whiteshade py-8 px-6  right-[5%] w-[350px] hidden custom:block`}
      >
        <h1 className="text-2xl font-bold text-black">Health Blog NewsLetter</h1>
        <p>Join newsletter to recieve the latest updates in your inbox.</p>
        <div className="py-4 flex gap-4 flex-col">
          <input
            type="text"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            placeholder="Enter your email"
            // onChange={handleChange}
            name="name"
            // value={callbackdetails.subject.name}
          />
          
          <button
            // onClick={handleSubmit}
            className="bg-blueshade text-center text-white font-semibold p-2 rounded-lg"
          >
            Subscribe
          </button>
        </div>
      </div>
      <div className="bg-blueshade h-[80px] ">

      </div>
      <div >
        <div className="flex flex-col items-start  min-h-[650px] gap-2 max-w-6xl w-full m-auto   xl:px-0 p-6">
          <div className="w-[100%] custom:w-[57%] xl:w-[70%]">
            <img
              src={Blog.Image}
              className=" sm:w-[48%] object-cover  md:block my-3"
              alt="blog"
            />
            <h1 className="xl:text-4xl mb-2  text-3xl font-bold  ">
              {/* Heading */}
              {Blog.Title}
            </h1>
            <p className="xl:text-lg lg:text-md text-sm ">
              
              {Blog.Description}
            </p>
          </div>
          <div className="flex flex-col items-start  gap-2 max-w-6xl w-full m-auto   xl:px-0 p-6">
        <div
          className="w-[100%] custom:w-[57%] min-h-[500px] xl:w-[70%]"
          dangerouslySetInnerHTML={{ __html: Blog.Content }}
        >
        </div>
      </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewBlog;
