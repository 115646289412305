import { CalendarDays, CheckCircle, X } from "lucide-react";
import React, { useContext } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Authcontext from "../../Context/Auth/AuthContext";

const BookingConfirmation = ({ open, details }) => {
  const modelref = useRef();
  // const closeModal = (e) => {
  //   if (modelref.current === e.target) {
  //     open();
  //   }
  // };
  const { userdata } = useContext(Authcontext);

  console.log(details);
  return (
    <div
      // ref={modelref}
      // onClick={closeModal}
      className="fixed inset-0 flex items-center justify-center flex-col m-auto bg-black bg-opacity-30 backdrop-blur-sm z-50 "
    >
      <div className="max-w-lg mx-auto sm:block hidden bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
        {/* Header */}
        <div className="bg-blueshade text-white flex flex-col items-start text-center p-4">
          <h2 className="text-lg font-semibold">
            Your appointment is confirmed
          </h2>
          <p className="text-sm">
            This appointment is{" "}
            <span className="text-[#f17a20] font-bold">guaranteed</span> by
            HaleClincs
          </p>
        </div>

        {/* Body */}
        <div className="p-5">
          <p className="text-gray-700 font-medium">
            Hello <span className="font-semibold">{userdata.name}</span>,
          </p>
          <p className="text-gray-600 text-sm mt-1">
            Thanks for booking an appointment on HaleClinics. Here are the
            details of your appointment:
          </p>

          {/* Details */}
          <div className="mt-4 border rounded-lg p-4 text-sm bg-gray-100">
            <div className="flex justify-between py-1">
              <span className="text-gray-500">Doctor's Name:</span>
              <span className="font-semibold">
                {details.doctor?.user?.name}
              </span>
            </div>
            <div className="flex justify-between py-1">
              <span className="text-gray-500">Date and Time:</span>
              <span className="font-semibold">
                {new Date(details?.date)
                  .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .replace(/\//g, "-")}{" "}
                |{" "}
                {`${parseInt(
                  details?.slot?.time.split(" ")[0].split(":")[0],
                  10
                )}:${
                  details?.slot?.time.split(" ")[0].split(":")[1]
                } ${details?.slot?.time.split(" ")[1].toUpperCase()}`}
              </span>
            </div>
            <div className="py-1 flex justify-between">
              <span className="text-gray-500">Clinic's Details:</span>
              <div className="flex flex-col items-end">
                <div className="font-semibold">
                  {details?.slot?.clinic?.clinicname}
                </div>
                <a
                  href="tel:+919972668484"
                  className="text-blue-600 hover:underline"
                >
                  +91{details?.slot?.clinic?.Clinicphone}
                </a>
                <div>{details?.slot?.clinic?.location}</div>
              </div>
            </div>
            <div className="flex justify-between py-1">
              <span className="text-gray-500">Appointment ID:</span>
              <span className="font-semibold">{details._id}</span>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-5 flex justify-between">
            <Link
              to="/Account/Appointments"
              className="bg-blueshade text-white px-4 py-2 rounded-lg hover:bg-blue-600 w-full ml-2 text-center"
            >
              My Appointments
            </Link>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center text-xs text-gray-500 p-3">
          Manage your appointments better by visiting{" "}
          <Link to="/Account/Appointments" className="text-blue-600 hover:underline">
            My Appointments
          </Link>
        </div>
      </div>
      <div className="max-w-[365px]  sm:hidden  mx-auto bg-white shadow-xl rounded-2xl p-6 text-center border border-gray-200">
        {/* Calendar Icon */}
        <div className="flex justify-center">
          <CheckCircle size={48} className="text-blue-500" />
        </div>

        {/* Confirmation Message */}
        <p className="text-gray-600 mt-4 text-lg">
          <span className="font-semibold">{userdata.name}</span> , we’ve got you
          confirmed for your appointment.
        </p>

        {/* Time & Doctor */}
        <h2 className="text-2xl font-bold text-gray-900 mt-3">
          {`${parseInt(details?.slot?.time.split(" ")[0].split(":")[0], 10)}:${
            details?.slot?.time.split(" ")[0].split(":")[1]
          } ${details?.slot?.time.split(" ")[1].toUpperCase()}`}{" "}
          <span className="text-blue-500">
            | Dr. {details.doctor?.user?.name}
          </span>
        </h2>

        {/* Date & Location */}
        <p className="text-gray-500 text-l mt-2">
          {new Date(details?.date)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-")}{" "}
          <br />
          {details?.slot?.clinic?.clinicname}| {details?.slot?.clinic?.location}
        </p>

        {/* Button */}
        <div className="mt-2 flex justify-between">
        <Link to='/Account/Appointments' className="mt-5 bg-blueshade text-white w-full py-3 rounded-full shadow-md hover:bg-blue-600 transition">
          MY APPOINTMENTS
        </Link>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
