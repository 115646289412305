import react from "react";

const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    );
}

export default Dashboard;