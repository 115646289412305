import React from "react";
import { Edit, Minus, Plus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  makeAuthenticatedGETRequest,
  makeAuthenticatedPATCHRequest,
} from "../../Services/Servicehelper";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

const EditService = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    Speciality: "",
    Procedures: [],
  });
  const [specialityoptions, setSpecialityOptions] = useState([]);

  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  let { id } = useParams();

  const getdata = async () => {
    if (!token || token === "undefined") {
      toast.warn("Please login to continue");
      return;
    }

    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getservicedetails?serviceid=${id}`,
        token
      );
      //   console.log(response)
      if (response.statusCode === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSpecialities = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallspecialities`,
        token
      );
      // console.log(response);
      setSpecialityOptions(
        response.data.map((speciality) => {
          return {
            value: speciality.Specialityname,
            label: speciality.Specialityname,
          };
        })
      );
      
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
    fetchSpecialities();
  }, []);

  const submithandler = async (e) => {
    if (!token || token === "undefined") {
      toast.warn("Please login to continue");
      return;
    }
    try {
      const response = await makeAuthenticatedPATCHRequest(
        `${host}/api/admin/updateservice?serviceid=${id}`,
        data,
        token
      );
    //   console.log(response);
      if (response.statusCode === 200) {
        toast.success("Service Updated Successfully");
        navigate("/admin/services");
        // window.location.href = "/admin/clinics";
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addProcedure = () => {
    setData((prev) => {
      return {
        ...prev,
        Procedures: [...prev.Procedures, ""],
      };
    });
  };

  const removeProcedure = (index) => {
    setData((prev) => {
      return {
        ...prev,
        Procedures: prev.Procedures.filter((_, i) => i !== index),
      };
    });
  };

  const InputChange = (e, index) => {
    const value = e.target.value;
    setData((prev) => {
      return {
        ...prev,
        Procedures: prev.Procedures.map((procedure, i) =>
          i === index ? value : procedure
        ),
      };
    });
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold">Add Service</h1>
      <div className="mt-5 grid grid-cols-1 gap-2">
        <div className="w-full flex flex-col ">
          <label htmlFor="Speciality">Speciality Name</label>
          <Select
                  options={specialityoptions}
                  value={{
                    value: data?.Speciality,
                    label: data?.Speciality,
                  }}
                  className="w-full border border-gray-900 rounded-md"
                  onChange={(e) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        Speciality: e.value,
                      };
                    });
                  }}
                  required
                />
        </div>
      </div>
      <div className="mt-5 w-full">
        <div className="w-full flex flex-col ">
          <div className="flex gap-2">
            <h1>Add Procedures </h1>
            <Plus
              onClick={addProcedure}
              className=" cursor-pointer"
              size={24}
            />
          </div>
        </div>
        <div className="border p-2 border-gray-900 flex w-full rounded-md gap-3 my-2 flex-wrap">
          {data.Procedures &&
            data.Procedures.map((procedure, index) => (
              <div key={index} className="flex justify-center items-center">
                <input
                  type="text"
                  name="Procedure"
                  className="border border-gray-900   p-2 rounded-md"
                  required
                  onChange={(e) => InputChange(e, index)}
                  value={procedure}
                />
                <Minus
                  className=" cursor-pointer"
                  onClick={() => removeProcedure(index)}
                  size={24}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="flex mt-5">
        <button
          onClick={submithandler}
          className="bg-[blue] text-white px-3 py-1 rounded-md"
        >
          Update Service
        </button>
      </div>
    </div>
  );
};

export default EditService;
