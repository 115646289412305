import React, { useEffect, useState } from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Banner from "../Components/HomePageComponents/Banner";
import "./Homepage.css";
import SpecalityCard from "../Components/HomePageComponents/SpecalityCard";
import Doctorcard from "../Components/HomePageComponents/DoctorCard";
import Testimonials from "../Components/HomePageComponents/Testimonials";
import BlogCard from "../Components/HomePageComponents/BlogCard";
import Footer from "../Components/HomePageComponents/Footer";
import { callback } from "../utils/Forms";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import Authcontext from "../Context/Auth/AuthContext";

const HomePage = () => {
  const host = process.env.REACT_APP_Backend_URL
  const [specialities, setSpecialities] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const { searchvalue, setSearchvalue, location, setLocation } = useContext(Authcontext);
  const { BookingData, setBookingData } = useContext(Authcontext);

  const [doctors, setDoctors] = useState([]);

  const TestimonialsContent = [
    {
      name: "Kohli",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 5,
    },
    {
      name: "Santhosh",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 4,
    },
    {
      name: "John",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 4,
    },
    {
      name: "Jawan",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 4,
    },
    {
      name: "Jane Doe",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 5,
    },
    {
      name: "John Doe",
      content:
        "I have been using this app for a while now and it has been a great experience. I would recommend it to anyone who is looking for a great app to use.",
      rating: 5,
    },
  ];

  const [callbackdetails, setCallbackDetails] = useState({
    source: "/Home",
    subject: {
      name: "",
      phone: "",
      speciality: "",
      location: "",
    },
  });

  const handleChange = (e) => {
    setCallbackDetails({
      ...callbackdetails,
      subject: {
        ...callbackdetails.subject,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    if (
      !callbackdetails.subject.name ||
      !callbackdetails.subject.phone ||
      !callbackdetails.subject.speciality ||
      !callbackdetails.subject.location
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    if (!phoneRegex.test(callbackdetails.subject.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    const response = await callback(callbackdetails);
    if (response.status === "success") {
      toast.success(response.data.message);
      setCallbackDetails({
        source: "/Home",
        subject: {
          name: "",
          phone: "",
          speciality: "",
          location: "",
        },
      });
    } else {
      toast.error(response.message);
    }
    // console.log(callbackdetails);
  };

  const fetchSpecialities = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getallspecialities`
      );
        console.log(response);
        
      if (response) {
        setSpecialities(response.data.filter((speciality) => speciality.Active === true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchnearerDoctors = async () => {
    try {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { longitude, latitude } = position.coords;
        const response = await makeUnauthenticatedGETRequest(
          `${host}/api/auth/getnearerdoctors?latitude=${latitude}&longitude=${longitude}`
        );
        console.log(response);
        if (response) {
          setDoctors(response.data);
        }
      },
      async (error) => {
        // Error callback: location permissions denied or other errors
        console.log("Location permission denied or error:", error.message);

        const defaultLatitude="17.4700"
        const defaultLongitude="78.3534"

        const response = await makeUnauthenticatedGETRequest(
          `${host}/api/auth/getnearerdoctors?latitude=${defaultLatitude}&longitude=${defaultLongitude}`
        );
        console.log(response);
        if (response) {
          setDoctors(response.data);
        }
      }
    );
    } catch (error) {
      console.log(error);
    }
  };

  const getblogs = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getblogs`
      );
      console.log(response);
      if (response) {
        setBlogs(response.data.filter((blog) => blog.Active === true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const requestLocationPermission = () => {
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "denied") {
            alert(
              "Please allow location access in your browser settings for a better experience."
            );
          } 
        });
    } 
  };

  useEffect(() => {
    fetchSpecialities();
    fetchnearerDoctors();
    getblogs();
    setBookingData({ 
      doctor: "",
      date: "",
      slot: "",
      PatientName: "",
      email: "",
      phonenumber: "",
      paymentcost:"",
      saved:false
     });
     setSearchvalue("");
     setLocation("");
     requestLocationPermission();
  }, []);

  return (
    <div>
      <Navbar />
      <section className="bg-">
        <img
          src="/assests/Banner.png"
          alt="Banner"
          className="object-cover w-[100%] "
        />
      </section>
      <section className="flex items-center bg-whiteshade justify-center">
        <Banner />
      </section>
      <div className="px-10 py-3 w-[100%] pt-10 bg-whiteshade">
        <div className="flex items-start flex-col justify-center max-w-6xl w-full mx-auto">
          <h1 className="text-3xl font-bold">Growing Rapidly</h1>
          <div className="h-1 bg-orangeshade w-40 my-2"></div>
        </div>
        <div className="Company-Portfolio max-w-6xl w-full mx-auto">
          <div className="Portfolio-Content">
            <div className="portfolio-item">
              <h1 className="md:text-5xl text-6xl sm:block xs:hidden  font-bold text-lightshade">
                01
              </h1>
              <div className="item-content">
                <img src="/assests/stethoscope.svg" alt="" />
                <h3 className="text-2xl xs:text-lg md:text-xl  font-bold">
                  07 Clinics
                </h3>
              </div>
            </div>
            <hr className="border-t-2 border-lightshade w-full" />
            <div className="portfolio-item">
              <h1 className="md:text-5xl sm:block text-6xl xs:hidden  font-bold text-lightshade">
                02
              </h1>
              <div className="item-content">
                <img src="/assests/syringe.svg" alt="" />
                <h3 className="md:text-xl  text-2xl xs:text-lg font-bold">
                  65K+ Out Patients served
                </h3>
              </div>
            </div>
            <hr className="border-t-2 border-lightshade w-full" />
            <div className="portfolio-item">
              <h1 className="md:text-5xl sm:block text-6xl xs:hidden font-bold text-lightshade">
                03
              </h1>
              <div className="item-content">
                <img src="/assests/HomeSpeciality.svg" alt="" />
                <h3 className="md:text-xl text-2xl xs:text-lg font-bold">
                  10+ Specialities Served
                </h3>
              </div>
            </div>
            <hr className="border-t-2 border-lightshade w-full" />
            <div className="portfolio-item">
              <h1 className="md:text-5xl sm:block text-6xl xs:hidden  font-bold text-lightshade">
                04
              </h1>
              <div className="item-content">
                <img src="/assests/HomeDoctors.svg" alt="" />
                <h3 className="md:text-xl text-2xl xs:text-lg font-bold">
                  60+ Doctors opening currently
                </h3>
              </div>
            </div>
          </div>
          <img className="Portfolio-img" alt="" src="/assests/Portfolio.png" />
        </div>
      </div>
      <div className="px-3 sm:px-10 py-5 w-[100%] mt-12">
        <div className="flex flex-row justify-between items-center max-w-6xl w-full mx-auto">
          <div>
            <h1 className="text-3xl font-bold">Our Specialities</h1>
            <div className="h-1 bg-orangeshade w-36 my-2"></div>
          </div>
          <Link
            className="flex flex-row justify-center items-center rounded-2xl rounded-br-none  sm:bg-blueshade sm:px-2"
            to="/doctors"
          >
            <p className=" hidden sm:block sm:text-sm text-white ">View More</p>
            <img
              className="w-[60px] h-[60px] sm:w-[40px] sm:h-[40px]"
              src="/assests/arrow.svg"
              alt="arrow"
            />
          </Link>
        </div>
        <div className="grid grid-cols-1 max-w-6xl justify-stretch w-full mx-auto lg:grid-cols-2 xl:grid-cols-2 gap-4 my-4">
          {specialities.map((speciality, index) => (
            <SpecalityCard key={index} treatment={speciality} />
          ))}
        </div>
      </div>
      <div className="px-3 sm:px-10 py-5 w-[100%] bg-whiteshade mt-10">
        <div className="flex flex-row justify-between items-center max-w-6xl w-full mx-auto ">
          <div>
            <h1 className="text-3xl font-bold">Our Experienced Doctors</h1>
            <div className="h-1 bg-orangeshade w-44 my-2"></div>
          </div>
          <Link
            className="flex flex-row justify-center items-center rounded-2xl rounded-br-none  sm:bg-blueshade sm:px-2"
            to="/doctors"
          >
            <p className=" hidden sm:block sm:text-sm text-white ">View More</p>
            <img
              className="w-[60px] h-[60px] sm:w-[40px] sm:h-[40px]"
              src="/assests/arrow.svg"
              alt="arrow"
            />
          </Link>
        </div>
        <div className="flex gap-12 flex-wrap justify-between items-stretch  my-8 max-w-6xl w-full mx-auto">
          {doctors.map((doctor, index) => (
            <Doctorcard
              doctor={doctor}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className="px-10 xl:px-0 py-3 max-w-6xl w-full mt-10 mx-auto">
        <div className="flex items-start flex-col justify-center">
          <h1 className="text-3xl font-bold">What People Say</h1>
          <div className="h-1 bg-orangeshade w-40 my-2"></div>
        </div>
        <div>
          <Testimonials data={TestimonialsContent} />
        </div>
      </div>
      <div className="px-3 sm:px-10 py-5 w-[100%] bg-whiteshade mt-10">
        <div className="flex flex-row justify-between items-center max-w-6xl w-full mx-auto">
          <div>
            <h1 className="text-3xl font-bold">Most Popular Reads</h1>
            <div className="h-1 bg-orangeshade w-44 my-2"></div>
          </div>
          <Link
            className="flex flex-row justify-center items-center rounded-2xl rounded-br-none  sm:bg-blueshade sm:px-2"
            to='/blogs'
          >
            <p className=" hidden sm:block sm:text-sm text-white ">View More</p>
            <img
              className="w-[60px] h-[60px] sm:w-[40px] sm:h-[40px]"
              src="/assests/arrow.svg"
              alt="arrow"
            />
          </Link>
        </div>
        <div className="max-w-6xl w-full my-10 mx-auto gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
          {blogs.length!==0 && blogs.map((blog, index) => (
            <BlogCard key={index} blog={blog} />
          ))}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center xl:justify-between xl:px-0 mt-12 p-3 max-w-6xl w-full mx-auto">
        <div className="md:w-[48%] flex flex-col">
          <h1 className="text-3xl font-bold">
            The surgery you need is just a click away!
          </h1>
          <div className="py-3 flex sm:flex-row flex-col justify-between">
            <input
              type="text"
              className="w-[100%] sm:w-[45%] h-[60px] bg-[#ebf3f5] placeholder:text-black rounded-lg p-3 my-2"
              placeholder="Name"
              name="name"
              onChange={handleChange}
              value={callbackdetails.subject.name}
            />
            <input
              type="text"
              className="w-[100%] sm:w-[45%] h-[60px] bg-[#ebf3f5] placeholder:text-black rounded-lg p-3 my-2 "
              placeholder="Phone Number"
              name="phone"
              onChange={handleChange}
              value={callbackdetails.subject.phone}
            />
          </div>
          <div className="py-3 flex sm:flex-row flex-col justify-between">
            <select
              className="w-[100%] sm:w-[45%] h-[60px] bg-[#ebf3f5] rounded-lg p-3 my-2 text-[#]"
              name="speciality"
              onChange={handleChange}
              value={callbackdetails.subject.speciality}
            >
              <option value="">Select Speciality</option>
              <option value="General Physician">General Physician</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Gastrology">Gastrology</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Urology">Urology</option>
              <option value="Neurology">Neurology</option>
            </select>
            <select
              className="w-[100%] sm:w-[45%] h-[60px] bg-[#ebf3f5] rounded-lg p-3 my-2"
              name="location"
              onChange={handleChange}
              value={callbackdetails.subject.location}
            >
              <option value="">Select Location</option>
              <option value="KPHB">KPHB</option>
              <option value="Madinaguda">Madinaguda</option>
              <option value="Manikonda">Manikonda</option>
              <option value="Health Hub">Health Hub</option>
              <option value="Kondapur">Kondapur</option>
              <option value="Alkapur">Alkapur</option>
            </select>
          </div>
          <button className="text-xl font-bold text-white bg-blueshade rounded-lg w-[100%] p-3"
          onClick={handleSubmit}
          >
            Book Consultation
          </button>
        </div>
        <div className="hidden md:block w-[45%] object-cover">
          <img src="/assests/doctorform.png" alt="doctor" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
