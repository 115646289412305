import react from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";
import { callback } from "../utils/Forms";
import { toast } from "react-toastify";

const TreatmentProfile = () => {

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const host = process.env.REACT_APP_Backend_URL;
  let { id } = useParams();

  const [treatment, setTreatment] = useState({
    Treatmentname: "",
    Heading: "",
    Description: "",
    Content: "",
  });

  const checkFooterVisibility = () => {
    const footer = document.getElementsByTagName('footer')[0];
    const nav=document.getElementsByTagName('nav')[0];
    // console.log(footer);
    if (footer) {
      // const footerTop = footer.offsetTop;
      // const footerTop = footer.getBoundingClientRect().top;
      // const scrollPosition = window.scrollY + window.innerHeight;
      // console.log(scrollPosition,window.scrollY ,window.innerHeight,footerTop);
      // setIsFooterVisible(scrollPosition >= footerTop);
      const footerTop = footer.getBoundingClientRect().top; 
      const navTop = nav.getBoundingClientRect().top;
        const isVisible = footerTop <= window.innerHeight;
        console.log(isVisible,navTop,footerTop,window.innerHeight);
        setIsFooterVisible(isVisible);
    }
  };

  useEffect(() => {
    // console.log('useEffect');
    window.addEventListener('scroll', checkFooterVisibility);
    window.addEventListener('resize', checkFooterVisibility);
    checkFooterVisibility();

    return () => {
      window.removeEventListener('scroll', checkFooterVisibility);
      window.removeEventListener('resize', checkFooterVisibility);
    };
  }, [treatment]);
  
  const gettreatment = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/gettreatment?treatmentid=${id}`
      );
      console.log(response);
        
      if (response) {
        setTreatment(response.data);
        setCallbackDetails({...callbackdetails, subject: {
          ...callbackdetails.subject,
          speciality: response.data.Treatmentname,
        }});
        checkFooterVisibility();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    gettreatment();
  }, []);

  const [callbackdetails, setCallbackDetails] = useState({
    source: "/TreatmentProfile",
    subject: {
      name: "",
      phone: "",
      speciality: "",
      location: "",
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(callbackdetails);
    const phoneRegex = /^\d{10}$/;
    if (
      !callbackdetails.subject.name ||
      !callbackdetails.subject.phone ||
      !callbackdetails.subject.speciality ||
      !callbackdetails.subject.location
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    if (!phoneRegex.test(callbackdetails.subject.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    const response = await callback(callbackdetails);
    if (response.status === "success") {
      toast.success(response.data.message);
      setCallbackDetails({
        source: "/TreatmentProfile",
        subject: {
          name: "",
          phone: "",
          speciality: treatment.Treatmentname,
          location: "",
        },
      });
    } else {
      toast.error(response.message);
    }
    // console.log(callbackdetails);
  };

  const handleChange = (e) => {
    setCallbackDetails({
      ...callbackdetails,
      subject: {
        ...callbackdetails.subject,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <div className="min-h-[100vh] flex flex-col relative">
      <Navbar />
      <div className={`${isFooterVisible ? `absolute bottom-[420px] z-2` : `fixed top-[150px]`} border-2  border-blue rounded-xl rounded-br-none bg-whiteshade py-8 px-6  right-[5%] w-[350px] hidden custom:block`}
      >
        <h1 className="text-2xl font-bold text-black">Request a Call Back</h1>
        <p>Speak to one of our representatives by filling the form below.</p>
        <div className="py-4 flex gap-4 flex-col">
          <input
            type="text"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            placeholder="Name"
            onChange={handleChange}
            name="name"
            value={callbackdetails.subject.name}
          />
          <input
            type="tel"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            placeholder="Phone Number"
            onChange={handleChange}
            name="phone"
            value={callbackdetails.subject.phone}
          />
          <select
              className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
              name="location"
              onChange={handleChange}
              value={callbackdetails.subject.location}
            >
              <option value="">Select Location</option>
              <option value="KPHB">KPHB</option>
              <option value="Madinaguda">Madinaguda</option>
              <option value="Manikonda">Manikonda</option>
              <option value="Health Hub">Health Hub</option>
              <option value="Kondapur">Kondapur</option>
              <option value="Alkapur">Alkapur</option>
            </select>
          <input
            type="text"
            className="w-full border placeholder:font-semibold px-4 border-[#aeaeae] p-2 rounded-lg"
            value={treatment.Treatmentname}
            disabled
          />

          <button onClick={handleSubmit} className="bg-blueshade text-center text-white font-semibold p-2 rounded-lg">
            Request a Call Back
          </button>
        </div>
      </div>
      <div className="bg-blueshade">
        <div className="flex flex-col items-start justify-center  gap-2 max-w-6xl w-full m-auto   xl:px-0 p-6">
          <div className="w-[100%] custom:w-[57%] xl:w-[70%]" >

          
          <h1 className="xl:text-4xl mb-2  text-3xl font-bold text-white ">
            {/* Heading */}
            {treatment.Heading}
          </h1>
          <p className="xl:text-lg lg:text-md text-sm text-white">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam dicta
            magni fugiat. Totam suscipit, enim debitis pariatur, sunt eius
            voluptas nostrum aspernatur dolores sit veniam! Dicta, velit.
            Laudantium, tenetur rerum. */}

            {treatment.Description} 
          </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center  gap-2 max-w-6xl w-full m-auto   xl:px-0 p-6">

      <div className="w-[100%] custom:w-[57%] min-h-[500px] xl:w-[70%]" dangerouslySetInnerHTML={{ __html: treatment.Content }} >
        {/* {treatment.Content} */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TreatmentProfile;
