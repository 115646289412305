import React, { useState,useEffect ,useContext} from "react";
import {AdminSidebar} from "../../Components/Admin Components/BasicComponents/AdminSidebar";
import { Outlet } from 'react-router-dom';
import { LayoutDashboard,Activity,UserCircle,CalendarDays,Hospital,BriefcaseMedical,ClipboardPenLine,Headset,MessageCircleMore,NotebookPen ,BookText  } from "lucide-react";
import {SidebarItem} from "../../Components/Admin Components/BasicComponents/AdminSidebar";
import AuthContext from "../../Context/Auth/AuthContext";

const AdminLayoutPage = () => {
  const context = useContext(AuthContext);
  const {role} = context;
  useEffect(() => {
    if(localStorage.getItem('token')===null){
      window.location.href="/admin";
    }
  }, [role])
  return (
    <div className="flex">
      <AdminSidebar>
        <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" active alert path="/admin/dashboard" />
        <SidebarItem icon={<CalendarDays size={20} />} text="Appointments" path="/admin/appointments" />
        <SidebarItem icon={<UserCircle size={20} />} text="Doctors" path="/admin/doctors" />
        <SidebarItem icon={<Hospital size={20} />} text="Clinics" path="/admin/clinics" />
        <SidebarItem icon={<BriefcaseMedical size={20} />} text="Specialities" path="/admin/specialities" />
        <SidebarItem icon={<NotebookPen size={20} />} text="Treatment Category" path="/admin/treatmentcategories" />
        <SidebarItem icon={<Activity size={20} />} text="Treatment" path="/admin/treatment" />
        <SidebarItem icon={<ClipboardPenLine size={20} />} text="Services" path="/admin/services" />
        <SidebarItem icon={<Headset size={20} />} text="Callbacks" path="/admin/callbacks" />
        <SidebarItem icon={<MessageCircleMore size={20} />} text="Queries" path="/admin/queries" />
        <SidebarItem icon={<BookText size={20} />} text="Blogs" path="/admin/blogs" />
      </AdminSidebar>
      <div className="w-full p-5"><Outlet /></div>
    </div>
  );
};

export default AdminLayoutPage;