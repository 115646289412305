import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  makeAuthenticatedPOSTRequest,
  makeUnauthenticatedPOSTRequestmultipart,
} from "../../Services/Servicehelper";
import ToggleButton from "react-toggle-button";
import { toast } from "react-toastify";

const AddSpeciality = () => {
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    Specialityname:"",
    Logo: "",
    Description: "",
    Active: false
  });

  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const imagechange = (e) => {
    const validTypes = ["image/svg+xml"];
    const selectedFile = e.target.files[0];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setImage(selectedFile);
    } else {
      setImage(null);
      toast.error("Please select a valid image file");
      return;
    }
  };

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      toast.error("Please select an image");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setData((prev) => {
          return {
            ...prev,
            Logo: response.data.Url,
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

    const submithandler = async (e) => {
        e.preventDefault();
        try {
            const response = await makeAuthenticatedPOSTRequest(
                `${host}/api/admin/addspeciality`,
                data,
                token
            );
            // console.log(response);

            if (response.status === "success") {
                toast.success("Speciality Added Successfully");
                setData({
                    Specialityname:"",
                    Logo: "",
                    Description: "",
                    Active: false
                });
                navigate("/admin/specialities");
            }
        } catch (err) {
            console.log(err);
        }
    };


  return (
    <form onSubmit={submithandler}>
      <h1 className="text-2xl font-semibold">Add Speciality</h1>
      <div className="mt-5 grid grid-cols-2 gap-2">
        <div className="w-full flex flex-col ">
          <label htmlFor="Specialityname">Speciality Name</label>
          <input
            type="text"
            id="Specialityname"
            name="Specialityname"
            placeholder="Speciality Name"
            className="border border-gray-900 p-2 rounded-md"
            required
            onChange={InputChange}
            value={data.Specialityname}
          />
        </div>
        <div className="w-full flex flex-col items-center justify-center ">
          <label htmlFor="Active">Active</label>
          <ToggleButton
                inactiveLabel={""}
                activeLabel={""}
                value={data.Active}
                onToggle={(value) => {
                    setData((prev) => {
                        return {
                            ...prev,
                            Active: !value,
                        };
                    });
                }}
              />
          
        </div>
      </div>
      <div className="mt-5 flex">
        <div className="w-full">
          <h2 className="text-xl ">Logo Upload</h2>
          {data.Logo!=="" && <img src={data.Logo} alt="logo" className="w-20 h-20" />}
          <div className="flex gap-2 mt-2">
            <input
              type="file"
              className="border border-gray-900 p-2 rounded-md"
              accept="image/svg+xml"
              onChange={imagechange}
              required
            />
            {!loading ? (
              <button
                onClick={upload}
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Upload
              </button>
            ) : (
              <button
                disabled
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Uploading...
              </button>
            )}
          </div>
        </div>
      </div>
        <div className="mt-5">
            <label htmlFor="Description">Description</label>
            <textarea
            id="Description"
            name="Description"
            placeholder="Description"
            className="border border-gray-900 p-2 rounded-md mt-2 w-full"
            required
            cols="30"
            rows="4"
            onChange={InputChange}
            value={data.Description}
            />
        </div>
        <div className="flex mt-5">
          <button
            type="submit"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Speciality
          </button>
        </div>
    </form>
  );
};

export default AddSpeciality;
