import React from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import "./ForDoctors.css";
import { useEffect, useState } from "react";
import PopupModal from "../Components/Fordoctorcomponent/PopupModal";
import {ChevronRight} from 'lucide-react'
import { Link } from "react-router-dom";

const ForDoctors = () => {
  const [box, setBox] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const newBox = document.querySelector(".reviews");
      setBox(newBox);
    };
    const initialBox = document.querySelector(".reviews");
    setBox(initialBox);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const btnpressprev = () => {
    let width;
    if (window.innerWidth < 425) {
        width = '210px';
    } else {
        width = '350px';
    }
    let reviewbox=document.getElementsByClassName('review');
    box.scrollLeft = box.scrollLeft - (reviewbox[0].clientWidth +20) ;
    // console.log(width);
};



const btnpressnext = () => {
  let width;
  if (window.innerWidth < 425) {
    width = '200px' ;
  } else {
    width = '350px';
  }
  let reviewbox=document.getElementsByClassName('review');
    box.scrollLeft = box.scrollLeft + reviewbox[0].clientWidth +20;
  };

  const [modalopen, setModalopen] = useState(false);

  const toggleModal = () => {
    setModalopen(!modalopen);
  };

  const reviews = [
    {
      review:
        "The Organisations Flexible Model Helped Me To Expand My Practice To 3 Locations In Less Than 12 Months",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Organisation Has Helped Me Start My Practice Effortlessly In No Time And Also Taken Away The Burden Of Maintenance And Operations",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Company Is Doing A Wonderful Job In Setting Up Clinics And Bringing Down Infrastructure And Operations Costs Incurred By Doctors",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Organisation Has Helped Me Start My Practice Effortlessly In No Time And Also Taken Away The Burden Of Maintenance And Operations",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Company Is Doing A Wonderful Job In Setting Up Clinics And Bringing Down Infrastructure And Operations Costs Incurred By Doctors",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Organisation Has Helped Me Start My Practice Effortlessly In No Time And Also Taken Away The Burden Of Maintenance And Operations",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Company Is Doing A Wonderful Job In Setting Up Clinics And Bringing Down Infrastructure And Operations Costs Incurred By Doctors",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Organisation Has Helped Me Start My Practice Effortlessly In No Time And Also Taken Away The Burden Of Maintenance And Operations",
      name: "Dr. Srinivas",
    },
    {
      review:
        "The Company Is Doing A Wonderful Job In Setting Up Clinics And Bringing Down Infrastructure And Operations Costs Incurred By Doctors",
      name: "Dr. Srinivas",
    },
  ];

  const locations = [
    {
      name: "Kondapur",
      url :"https://g.co/kgs/4stqzMn"
    },
    {
      name: "Madinaguda",
      url :"https://g.co/kgs/JjJjB6F"
    },
    {
      name: "Kondapur",
      url:"https://g.co/kgs/kYXrkzB"
    },
    {
      name: "Alkapur",
      url :"https://g.co/kgs/nh4xU72"
    },
    {
      name: "KPHB",
      url :"https://g.co/kgs/HNEySC1"
    },
    {
      name: "Manikonda",
      url:"https://g.co/kgs/9zz4WVi"
    }

  ]
  return (
    <div className=" min-h-[100vh]  flex flex-col">
      <Navbar />
      <div className="bg-white max-w-6xl w-full mx-auto py-16 p-5 xl:px-0 flex justify-center xl:justify-between items-center  ">
        <div className="md:w-[50%] flex gap-2 md:gap-4 flex-col">
          <h1 className="lg:text-4xl xl:text-5xl md:text-3xl text-3xl lg:leading-snug text-[#1651C6] font-bold">
            Empowering Doctors to be Independent !!
          </h1>
          <h1 className="lg:text-2xl text-xl  text-black font-bold">
            Building Sustainable Medical Practices Together
          </h1>
          <div className="my-5">
            <button
              className="font-bold text-white bg-orangeshade rounded-[16px] rounded-br-none p-3 "
              onClick={toggleModal}
            >
              Join Our Co-Working Space
            </button>
          </div>
        </div>
        <img
          alt="fordoctors"
          src="/assests/fordoctors.png"
          className="lg:w-[32%] w-[38%] object-cover hidden md:block"
        />
      </div>
      <div className="bg-whiteshade ">
        <div className="max-w-6xl w-full mx-auto  xl:px-0 py-10 p-5 lg:px-20 flex justify-center md:justify-between items-center flex-wrap gap-4   ">

        
        <div className="flex justify-center items-center border-[2px] bg-white border-blueshade rounded-xl rounded-br-none p-3 md:p-6 flex-col gap-2 w-[45%] md:w-[23%] self-stretch">
          <img
            alt="fordoctors"
            src="/assests/fordoctors_stethoscope.svg"
            className="md:w-[45%] w-[30%] xl:w-[35%]"
          />
          <h1 className="text-lg sm:text-xl lg:text-2xl text-black font-bold text-center">
            Expert Doctors
          </h1>
        </div>
        <div className="flex justify-center items-center border-[2px] bg-white border-blueshade rounded-xl rounded-br-none p-3 md:p-6 flex-col gap-2 w-[45%] md:w-[23%] self-stretch">
          <img
            alt="fordoctors"
            src="/assests/fordoctors_injection.svg"
            className="md:w-[45%] w-[30%] xl:w-[35%]"
          />
          <h1 className="text-lg sm:text-xl lg:text-2xl text-black font-bold text-center">
            Hassle Free Experience
          </h1>
        </div>
        <div className="flex justify-center items-center border-[2px] bg-white border-blueshade rounded-xl rounded-br-none p-3 md:p-6 flex-col gap-2 w-[45%] md:w-[23%] self-stretch">
          <img
            alt="fordoctors"
            src="/assests/fordoctors_assistance.svg"
            className="md:w-[45%] w-[30%] xl:w-[35%]"
          />
          <h1 className="text-lg sm:text-xl lg:text-2xl text-black font-bold text-center">
            Personal Assistance
          </h1>
        </div>
        <div className="flex justify-center items-center border-[2px] bg-white border-blueshade rounded-xl rounded-br-none p-3 md:p-6 flex-col gap-2 w-[45%] md:w-[23%] self-stretch">
          <img
            alt="fordoctors"
            src="/assests/fordoctors_hospital.svg"
            className="md:w-[45%] w-[30%] xl:w-[35%]"
          />
          <h1 className="text-lg sm:text-xl lg:text-2xl text-black font-bold text-center">
            At Your Convenience
          </h1>
        </div>
        </div>
      </div>
      <div>
        {modalopen && (
          <PopupModal open={toggleModal}/>
        )}
      </div>
      <div className="py-20 p-5 lg:px-20 bg-[#fff0e5]">
        <div className="flex items-start flex-col justify-center max-w-6xl w-full mx-auto  ">
          <h1 className="text-3xl text-black font-semibold">
            Why Choose Us !!
          </h1>
          <div className="h-[2px] bg-orangeshade w-44 my-2"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 mt-8 max-w-6xl w-full mx-auto  gap-4 my-2">
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/card-reader.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">
              Billing Management
            </h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/staffing.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">Staffing</h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/pharmacy.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">
              In-House Pharmacy
            </h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/booking_management.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">
              Booking Management
            </h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/lab.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">
              Diagnostic Referrals
            </h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/branding.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">Branding</h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/marketing.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">
              Marketing & Legal
            </h1>
          </div>
          <div
            className="flex justify-start items-center gap-5 border-[#F27921] border-[2px] p-3 bg-white
          rounded-[36px] rounded-br-none"
          >
            <img
              alt="fordoctors"
              src="/assests/office.svg"
              className="w-[50px] object-cover"
            />
            <h1 className="text-2xl text-black font-semibold">Office Space</h1>
          </div>
        </div>
      </div>
      <div className="h-[5px] bg-[#f17a20]"></div>
      <div className="py-10 p-5 lg:px-20 bg-blueshade">
        <div className="flex items-start max-w-6xl w-full mx-auto  flex-col justify-center ">
          <h1 className="text-3xl text-white font-semibold">
            Our Locations
          </h1>
          <div className="h-[2px] bg-orangeshade w-60 my-2"></div>
          <div className="flex flex-row  w-full items-center justify-between">
            <img src="/assests/Map.png" className="object-cover hidden lg:block w-[60%]" alt="locations" />
            <div className="lg:w-[250px] flex-grow lg:flex-grow-0 justify-items-stretch grid lg:grid-cols-1 md:grid-cols-3 gap-2">
              {locations.map((location, i) => (
                <a
                  className="flex rounded-[20px] rounded-bl-none justify-between items-center gap-2 my-2 bg-white p-3 "
                  key={i}
                  href={location.url}
                  target="_blank"
                >
                  <div className="flex gap-2">

                  <img src="/assests/location.svg" alt="location" />
                  <h1 className="text-xl" >{location.name}</h1>
                  </div>
                  <ChevronRight size={24}  className="float-right" color="#1651C6" />
                </a>
            ))}   
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 p-5 lg:px-20  bg-whiteshade">
        <div className="flex items-start max-w-6xl w-full mx-auto  flex-col justify-center ">
          <h1 className="text-3xl text-black font-semibold">
            Doctors Testimonials
          </h1>
          <div className="h-[2px] bg-orangeshade w-60 my-2"></div>
        </div>
        <div className="flex max-w-6xl w-full mx-auto  relative">
          <button onClick={btnpressprev} className="z-10">
            <img
              src="/assests/arrowleft.svg"
              alt="arrow"
              className="custom1:w-[80px] w-[50px] absolute top-1/2 left-0 transform -translate-y-1/2"
            />
          </button>

          <div className="my-[25px] mx-[10px] p-3 xl:px-10  py-[30px] custom1:p-[30px] flex scroll-smooth gap-[20px] overflow-hidden reviews">
            {reviews.map((review, i) => (
              <div
                className=" min-w-[200px] custom1:min-w-[350px] bg-white p-3 border-[#1651C6] border-[1px] rounded-2xl shadow-lg relative rounded-br-none review"
                key={i}
              >
                <img
                  src="/assests/reviewicon.svg"
                  className="absolute top-[-25px] left-10 w-[50px] "
                />
                <p className="font-semibold text-sm custom1:text-md leading-7 my-[20px] ">
                  {review.review}
                </p>
                <div className="flex justify-start gap-2 items-center">
                  <div className="h-[2px] bg-orangeshade w-[20px] my-2"></div>
                  <h1 className="font-bold text-xl">{review.name}</h1>
                </div>
              </div>
            ))}
          </div>
          <button onClick={btnpressnext} className="z-10">
            <img
              src="/assests/arrow.svg"
              alt="arrow"
              className="custom1:w-[80px] w-[50px] absolute top-1/2 right-0 transform -translate-y-1/2"
            />
          </button>
        </div>
      </div>
      <div className="py-10 p-5 lg:px-20 ">
        <div className="flex items-start flex-col justify-center max-w-6xl w-full mx-auto  ">
          <h1 className="text-3xl text-black font-semibold">
            Our Partnered Network
          </h1>
          <div className="h-[2px] bg-orangeshade w-60 my-2"></div>
        </div>
        <div className="logos max-w-6xl w-full mx-auto ">
          <div className="logo-track flex justify-center  items-center">
            <img
              alt="logo"
              src="/assests/Partners1.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners2.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners3.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners4.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners5.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners6.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners1.png"
              className="w-[200px] "
            />
            <img
              alt="logo"
              src="/assests/Partners2.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners3.png"
              className="w-[200px] "
            />
            <img
              alt="logo"
              src="/assests/Partners4.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners5.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners6.png"
              className="w-[200px]"
            />
          </div>
        </div>
        <div className="logos max-w-6xl w-full mx-auto ">
          <div className="logo-track1 flex justify-center  items-center">
            <img
              alt="logo"
              src="/assests/Partners7.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners8.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners9.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners10.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners11.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners12.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners7.png"
              className="w-[200px] "
            />
            <img
              alt="logo"
              src="/assests/Partners8.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners9.png"
              className="w-[200px] "
            />
            <img
              alt="logo"
              src="/assests/Partners10.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners11.png"
              className="w-[200px]"
            />
            <img
              alt="logo"
              src="/assests/Partners12.png"
              className="w-[200px]"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForDoctors;
