import react from "react";
import { Link } from "react-router-dom";

const Treatmentcard = ({ treatment }) => {
  return (
    <Link
      to={`/treatments/${treatment._id}`}
      className="flex p-2 flex-row items-center rounded-br-none gap-3 bg-white pr-3  rounded-[20px] border border-[#1651C6] hover:bg-whiteshade cursor-pointer"
    >
      <img
        src={treatment.Logo}
        alt={treatment.Treatmentname}
        className="h-20 object-cover  "
      />
      <div className="mx-1">
        <h1 className="text-[20px] font-bold my-2">
          {treatment.Treatmentname}
        </h1>
        <p className="text-[15px] mb-3">
          {treatment.Description.length > 150
            ? `${treatment.Description.slice(0, 150)}.....`
            : treatment.Description}
        </p>
      </div>
    </Link>
  );
};

export default Treatmentcard;
