import { useState,useEffect } from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import ClinicCard from "../Components/ContactComponents/ClinicCard";
import Footer from "../Components/HomePageComponents/Footer";
import { sendquery } from "../utils/Forms";
import { useNavigate } from "react-router-dom";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";
import { toast } from "react-toastify";


const Contact = () => {
  const [clinics, setclinics] = useState([])
  const host = process.env.REACT_APP_Backend_URL

  const navigate = useNavigate();

  const [querydetails, setQueryDetails] = useState({
    source: "/Contact",
    subject: {
      name: "",
      email: "",
      message: "",
    },
  });

  const handleChange = (e) => {
    setQueryDetails({
      ...querydetails,
      subject: {
        ...querydetails.subject,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !querydetails.subject.name ||
      !querydetails.subject.email ||
      !querydetails.subject.message
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(querydetails.subject.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    // console.log(querydetails);
    const response = await sendquery(querydetails);
    console.log(response);
    if (response.status === "success") {
      toast.success(response.data.message);
      setQueryDetails({
        source: "/Contact",
        subject: {
          name: "",
          email: "",
          message: "",
        },
      });
      navigate("/");
    } else {
      toast.error(response.message);
    }
  };

  const getclinics = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getclinics`
      );
      console.log(response);
        
      if (response) {
        setclinics(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getclinics();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="bg-blueshade py-20 p-5 lg:px-20   ">
        <div className=" flex justify-between items-center max-w-6xl w-full mx-auto">
          <div className="md:w-[60%]">
            <div className="flex items-start flex-col justify-center ">
              <h1 className="text-3xl text-white font-semibold">About Us</h1>
              <div className="h-[3px] bg-orangeshade w-24 my-2"></div>
            </div>
            <p className="text-white text-[18px] leading-7">
              Hale Clinics is a healthcare infrastructure company connecting
              patients and doctors instantly through tele-consultations and
              Primary Healthcare Systems. Started in 2019, Hale Clinics has been
              helping patients assess their medical condition and find the right
              doctor. For health care providers Hale Clinics helps them build
              their independent practice and grow with enhanced patient
              engagement.
            </p>
          </div>
          <img
            src="/assests/About.png"
            className="lg:w-[32%] w-[38%] object-cover hidden md:block"
          />
        </div>
      </div>
      <div className="lg:px-20 p-3 w-[100%]  py-10 bg-whiteshade">
        <div className="flex items-start flex-col max-w-6xl w-full mx-auto justify-center">
          <h1 className="text-3xl font-bold">Our Clinics</h1>
          <div className="h-[3px] bg-orangeshade w-28 my-2"></div>
        </div>
        <div className="grid grid-cols-1 max-w-6xl  w-full mx-auto md:grid-cols-2 lg:grid-cols-3 gap-11 my-4">
          {clinics.map((clinic, i) => (
            <ClinicCard key={i} clinic={clinic} />
          ))}
        </div>
      </div>

      <div className=" w-[100%] flex flex-col md:flex-row justify-between">
        <div className="w-[100%] md:w-[63%] bg-blueshade lg:px-20 xl:pl-40 p-4 relative py-5 lg:py-12">
          <div className="flex items-start flex-col justify-center ">
            <h1 className="text-2xl text-white font-semibold">
              Get In Touch With Us
            </h1>
            <div className="h-[3px] bg-orangeshade w-44 my-2"></div>
          </div>
          <div className="my-5">
            <div className="flex items-center justify-start gap-2">
              <img src="/assests/location.svg" className="w-6 h-6" alt="" />
              <h1 className="font-semibold text-white text-xl">Our Location</h1>
            </div>
            <p className="mx-2 text-white text-md my-2 leading-7">
              D. No. 49-51/3, Santipuram, Opposite Santipuram Park, NGGO’S
              Colony, Vishakhapatnam AP 530016
            </p>
          </div>
          <div className="my-5">
            <div className="flex items-center justify-start gap-2">
              <img src="/assests/PhoneCall.svg" className="w-6 h-6" alt="" />
              <h1 className="font-semibold text-white text-xl">Our Contact</h1>
            </div>
            <p className="mx-2 text-white text-md my-2 leading-7">
              OPD enquiries : +91 123-456-7890 , +91 123-456-7890
            </p>
            <p className="mx-2 text-white text-md my-2 leading-7">
              Pharmacy enquiries : +91 123-456-7890
            </p>
          </div>
          <div className="my-5">
            <div className="flex items-center justify-start gap-2">
              <img src="/assests/timing.svg" className="w-6 h-6" alt="" />
              <h1 className="font-semibold text-white text-xl">Timings</h1>
            </div>
            <p className="mx-2 text-white text-md my-2 leading-7">
              Mon to Sat- 9:00 am to 7:30 pm - SUNDAY Closed
            </p>
          </div>
          <div className="my-5">
            <div className="flex items-center justify-start gap-2">
              <img src="/assests/Email.svg" className="w-6 h-6" alt="" />
              <h1 className="font-semibold text-white text-xl">Email</h1>
            </div>
            <p className="mx-2 text-white text-md my-2 leading-7">
              info@haleclinics.in
            </p>
          </div>
          <img
            src="/assests/contact.png"
            className="absolute bottom-0 right-0 w-[30%] hidden md:block"
            alt=""
          />
        </div>
        <div className=" bg-whiteshade w-[100%] md:w-[37%] xl:pr-32 justify-evenly flex flex-col g:px-20 p-4 py-5 lg:py-12">
          <div className="w-[100%]">
            <h1 className="text-lg font-semibold">Full Name</h1>
            <input
              type="text"
              className="w-[100%] border-[1px] border-blueshade p-2 my-2"
              name="name"
              onChange={handleChange}
              value={querydetails.subject.name}
            />
          </div>
          <div className="w-[100%]">
            <h1 className="text-lg font-semibold">Email</h1>
            <input
              type="email"
              className="w-[100%] border-[1px] border-blueshade p-2 my-2"
              name="email"
              onChange={handleChange}
              value={querydetails.subject.email}
            />
          </div>
          <div className="w-[100%]">
            <h1 className="text-lg font-semibold">Message</h1>
            <textarea
              className="w-[100%] border-[1px] h-[120px] border-blueshade p-2 my-2"
              name="message"
              onChange={handleChange}
              value={querydetails.subject.message}
            />
          </div>
          <button
            onClick={handleSubmit}
            className="bg-indigo my-1 w-[150px]  text-white px-4 py-1 rounded-lg rounded-br-none"
          >
            Submit
          </button>
        </div>
      </div>
      {/* <div className="lg:px-20 p-10">
        <div className=" border-[3px] max-w-6xl w-full mx-auto border-[#1651C6]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2677057339347!2d78.34146787521281!3d17.494729683410863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9306232edf1d%3A0x9e1db25f7301d49b!2sHALE%20CLINICS%20-%20Madinaguda!5e0!3m2!1sen!2sin!4v1713596039972!5m2!1sen!2sin"
            className="w-[100%]"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Contact;
