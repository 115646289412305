import React from "react";
import { useState, useEffect } from "react";
import {makeAuthenticatedPOSTRequest,makeAuthenticatedGETRequest} from "../../Services/Servicehelper";
import { Link,useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";



const AddDoctor = () => {
  const navigate = useNavigate();
  const [userdata, setUserData] = useState({
    name: "",
    email: "",
    phonenumber: "",
    password: "",
    role: "Doctor",
    age:"",
    gender:"Male",
    speciality:"",
    regNo:""
  });

  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const [specialityoptions, setSpecialityOptions] = useState([]);
  const fetchSpecialities = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallspecialities`,
        token
      );
      // console.log(response);
      setSpecialityOptions(
        response.data.map((speciality) => {
          return {
            value: speciality.Specialityname,
            label: speciality.Specialityname,
          };
        })
      );
      
    } catch (error) {
      console.log(error);
    }
  };

  const addDoctor= async (e) => {
    e.preventDefault();
    if(userdata.name===""||userdata.email===""||userdata.phonenumber===""||userdata.password===""||userdata.age==""||userdata.gender===""||userdata.speciality===""||userdata.regNo==""){
      // console.log(userdata);
      toast.error("Please fill all the fields");
      return;
    }

    if(!token || token === "undefined"){
      toast.warn("Please login to continue");
      return;
    }
    try{
    const response = await makeAuthenticatedPOSTRequest(`${host}/api/admin/createDoctor`,userdata,token);
    // console.log(response);
    if(response.statusCode === 201){
      setUserData({name: "",
        email: "",
        phonenumber: "",
        password: "",
        role: "Doctor",
        age:"",
        gender:"Male",
        speciality:"",
        regNo:""})
        toast.success("Doctor Created Successfully")
        navigate("/admin/doctors");
    }
    else{
      toast.error(response.message);
    }
    }
    catch(err){
      console.log(err);
    }

  }

  useEffect(() => {
    fetchSpecialities();
  }, []);



  return (
    <div>
      <h1 className="text-2xl font-semibold">Add Doctor</h1>
      <form onSubmit={addDoctor}>
        <div className="mt-5" >
          <div className="flex flex-col">
            <label htmlFor="name" className="text-sm">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={userdata.name}
              onChange={(e) => setUserData({ ...userdata, name: e.target.value })}
              className="border border-gray-600 p-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="email" className="text-sm">
              Email
            </label>
            <input
              value={userdata.email}
              type="email"
              name="email"
              id="email"
              className="border border-gray-600 p-2 rounded-md"
              onChange={(e) => setUserData({ ...userdata, email: e.target.value })}
              required
            />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="age" className="text-sm">
              Age
            </label>
            <input
              type="number"
              value={userdata.age}
              name="age"
              id="age"
              className="border border-gray-600 p-2 rounded-md"
              required
              onChange={(e) => setUserData({ ...userdata, age: e.target.value })}
            />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="phonenumber" className="text-sm">
              Mobile
            </label>
            <input
              type="text"
              name="phonenumber"
              maxLength={10}
              minLength={10}
              value={userdata.phonenumber}
              id="phonenumber"
                onChange={(e) => setUserData({ ...userdata, phonenumber: e.target.value })}
              className="border border-gray-600 p-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="password" className="text-sm">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={userdata.password}
              id="password"
                onChange={(e) => setUserData({ ...userdata, password: e.target.value })}
              className="border border-gray-600 p-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="gender" className="text-sm">
              Gender
            </label>
            <select
              name="gender"
              required
              value={userdata.gender}
              className="border border-gray-600 p-2 rounded-md"
              onChange={(e) => setUserData({ ...userdata,gender: e.target.value })}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="speciality" className="text-sm">
              Speciality
            </label>
            {/* <input
              type="text"
              value={userdata.speciality}
              name="speciality"
              required
              id="speciality"
              className="border border-gray-600 p-2 rounded-md"
              onChange={(e) => setUserData({ ...userdata, speciality: e.target.value })}
            /> */}
            <Select
                  options={specialityoptions}
                  value={{
                    value: userdata?.speciality,
                    label: userdata?.speciality,
                  }}
                  className="w-full border border-gray-900 rounded-md"
                  onChange={(e) => {
                    setUserData({ ...userdata, speciality: e.value });
                  }}
                  required
                />
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="regno" className="text-sm">
              REG NO.
            </label>
            <input
              type="text"
              required
              name="regno"
              id="regno"
              value={userdata.regNo}
              className="border border-gray-600 p-2 rounded-md"
              onChange={(e) => setUserData({ ...userdata, regNo: e.target.value })}
            />
          </div>

          <button type="submit" className="bg-[blue] text-white px-3 py-2 rounded-md mt-3">
            Create Doctor
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDoctor;
