import React from "react";

const ClinicCard = ({ clinic }) => {
    return (
        <div  className="bg-white border-[#1651C6] border-2 rounded-xl flex flex-col justify-center gap-3 md:justify-between items-center rounded-br-none">
        <img src={`${clinic.imageurl}`} alt={clinic.name} className="  w-[100%]  rounded-xl rounded-b-none object-cover h-[250px] " />
        <div className="flex justify-between items-center w-[100%] mb-2 px-4 gap-2" >
            <h1 className="text-2xl font-bold">{clinic.clinicname}</h1>
            {/* <div className="flex my-1">
            <img src="/assests/location.svg" className="w-5 mr-1" alt="location" />
            <p>{clinic.address}</p>
            </div>
            <div className="flex my-1">
                <img src="/assests/call.svg" className="w-5 mr-1" alt="location" />
            <p>{clinic.phone}</p>
            </div> */}
            <div className="bg-indigo my-1  text-white px-4 py-1 rounded-lg rounded-br-none">
    <a href={`${clinic.directions}`} target="_blank">Directions</a>
</div>

        </div>
        </div>
    );
    };

export default ClinicCard;