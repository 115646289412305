import react from "react";
import Appointmentcard from "../../Components/AccountPageComponents/Appointmentcard";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest } from "../../Services/Servicehelper";
import QueryPopup from "../../Components/AccountPageComponents/QueryPopup";
import Loader from "../../Components/Loader";

const UserAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");
  const getappointments = async () => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/patient/getappointments`,
        token
      );
      console.log(response);
      if (response.data) {
        setAppointments(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getappointments();
  }, []);
  const [modalopen, setModalopen] = useState(false);
  const toggleModal = () => {
    setModalopen(!modalopen);
  };

  return (
    <div>
      <h1 className="font-bold mx-5 text-xl md:text-2xl">Appointments</h1>
      <div className="flex flex-col gap-3 my-2 max-h-[75vh] overflow-scroll px-5 no-scrollbar">
        {loading && (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        )}
        {appointments.length > 0 ? (
          appointments.map((appointment, index) => {
            return <Appointmentcard key={index} appointment={appointment} />;
          })
        ) : (
          <h1 className="text-center">No Appointments</h1>
        )}
        <div>{modalopen && <QueryPopup open={toggleModal} />}</div>
      </div>
      <button
        onClick={toggleModal}
        className="bg-blueshade float-left w-[150px] text-white font-bold py-2 mx-5 text-center rounded-[10px]"
      >
        Raise a Query
      </button>
    </div>
  );
};

export default UserAppointments;
