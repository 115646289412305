import React from "react";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest ,makeAuthenticatedDELETERequest} from "../../Services/Servicehelper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Specialities = () => {
  const [specialities, setSpecialities] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const fetchSpecialities = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallspecialities`,
        token
      );
      // console.log(response);
      setSpecialities(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSpeciality = async (id) => {
    if(!window.confirm("Are you sure you want to delete this speciality?")) return;
    try {
      const response = await makeAuthenticatedDELETERequest(
        `${host}/api/admin/deletespeciality?specialityid=${id}`,
        token
      );
      // console.log(response);
      if(response.status==="success"){
        toast.success("Speciality Deleted Successfully");
        fetchSpecialities();
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    fetchSpecialities();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Specialities</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <a
            href="/admin/addspeciality"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Speciality
          </a>
        </div>
        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
            <thead>
              <tr>
                <th className="border border-gray-900">Speciality</th>
                <th className="border border-gray-900">Active</th>
                <th className="border border-gray-900">Actions</th>
              </tr>
            </thead>
            <tbody>
              {specialities.map((speciality) => (
                <tr key={speciality._id}>
                  <td className="border border-gray-900 p-2 text-center">
                    {speciality.Specialityname}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {speciality.Active ? (
                      <div className="rounded-xl text-[green]">Active</div>
                    ) : (
                      <div className="rounded-xl  text-[red]">Inactive</div>
                    )}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    <Link
                      to={`/admin/editspeciality/${speciality._id}`}
                      className="bg-[blue] text-white px-3 py-1 rounded-md"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteSpeciality(speciality._id)}
                      className="bg-[red] text-white px-3 py-1 rounded-md ml-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Specialities;
