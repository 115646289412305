import React from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Authcontext from "../Context/Auth/AuthContext";
import PatientLogin from "../Components/AuthComponents/PatientLogin";
import DoctorLogin from "../Components/AuthComponents/DoctorLogin";
import { makeUnauthenticatedPOSTRequest } from "../Services/Servicehelper";
import { toast } from "react-toastify";
import Loader from "../Components/Loader";

const Login = () => {
  const [active, setactive] = useState("Patient");
  const [otpsent, setotpsent] = useState(false);
  const [loading, setloading] = useState(false);
  const {PhoneNumber}=useContext(Authcontext);
  const [otp,setotp]=useState("");
  const navigate = useNavigate();

  const VerifyOTP = async () => {
    try {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(PhoneNumber)) {
        toast.error("Please Enter Valid Phone Number");
        return;
      }

      if(otp.length!==6){
        toast.error('Please Enter Valid OTP');
        return;
      }
      setloading(true);
      const response = await makeUnauthenticatedPOSTRequest(
        `${process.env.REACT_APP_Backend_URL}/api/auth/verifyotp`,
        { phonenumber: "91"+PhoneNumber, otp: otp }
      );
      console.log(response);
      if(response.status==='success'){
        localStorage.setItem('token',response.data.token);
        toast.success('Login Successfull');
        navigate(-1);
        setloading(false);
      }
      else{
        toast.error(response.message);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const resendotp = async () => {
    try {
      const response = await makeUnauthenticatedPOSTRequest(
        `${process.env.REACT_APP_Backend_URL}/api/auth/sendotp`,
        { phonenumber: PhoneNumber },
      );
      setotpsent(true);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div>
      <Navbar />
      <div className="p-5">
        <div className="flex mx-auto p-5 md:p-0  my-5 bg-[#F4F6F9]  border-[1px] border-gray-400">
          <img
            src="/assests/Loginbanner.png"
            className="object-cover hidden md:block md:w-[48%] h-[350px] lg:h-[550px]"
            alt=""
          />
          <div className="m-auto ">
            <img
              src="/assests/Logo.png"
              className="h-12 w-auto m-auto"
              alt=""
            />
            {loading && (
              <div className="flex w-[100%] justify-center items-center">
                <Loader />
              </div>
            )}
            {!loading && (!otpsent ? (
              <div className="bg-white flex flex-col items-center justify-center custom1:w-[350px] mt-3 pb-4">
                <div className="flex w-[100%]">
                  <button
                    onClick={() => setactive("Patient")}
                    className={`w-[50%] p-2 ${
                      active === "Patient"
                        ? "border-t-2 border-blueshade"
                        : "bg-[#EFEFEF]"
                    }`}
                  >
                    <h1 className="text-md font-semibold text-center">
                      Patient
                    </h1>
                  </button>
                  <button
                    onClick={() => setactive("Doctor")}
                    className={`w-[50%] p-2 ${
                      active === "Doctor"
                        ? "border-t-2 border-blueshade"
                        : "bg-[#EFEFEF]"
                    }`}
                  >
                    <h1 className="text-md font-semibold  text-center">
                      Doctor
                    </h1>
                  </button>
                </div>
                <div className="flex w-[80%]">
                  {active === "Patient" ? (
                    <PatientLogin setotpsent={setotpsent} setloading={setloading} />
                  ) : (
                    <DoctorLogin />
                  )}
                </div>
                <hr className="border-b-1 border-gray-400 my-2 w-[80%]" />
                {/* <a href="/admin" className=" text-blueshade font-bold">
                  Register New User ?
                </a> */}
              </div>
            ) : (
              <div className="bg-white p-3 rounded-lg flex flex-col items-center w-[350px] justify-center my-4 ">
                <h1 className="text-md font-bold text-center">
                  Please Enter OTP Sent to
                </h1>
                <h1 className="text-xl font-bold text-center">{PhoneNumber}</h1>
                <a href="/login" className=" text-blueshade font-bold">
                  {" "}
                  Change
                </a>
                <input
                  type="text"
                  id="OTP"
                  onChange={(e) => setotp(e.target.value)}
                  className="border-2 rounded-md border-gray-400 w-[100%] p-1 mt-2"
                  placeholder="Enter OTP"
                />
                <button
                  className="w-[100%] bg-[#1651C6] p-2 mt-2 rounded-md text-whiteshade font-bold"
                  onClick={VerifyOTP}
                >
                  Verify OTP
                </button>
                <hr className="border-b-1 border-gray-400 my-2 w-[100%]" />
                <p className="text-gray-500">Not Recieved Your Code ? </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
