import React from "react"

const DoctorLogin = () => {
    return (
        <div className="flex flex-col items-start justify-center my-4 w-[100%]">
        <label className="font-bold" htmlFor="Email">Enter Email</label>
        <input type="text" id="Email" className="border-2 border-gray-400 w-[100%] p-1 mt-2" placeholder="Enter Email"/>
        <label className="font-bold" htmlFor="Password">Enter Password</label>
        <input type="password" id="Password" className="border-2 border-gray-400 w-[100%] p-1 mt-2" placeholder="Enter Password"/>
        <button className="w-[100%] bg-[#1651C6] p-2 mt-2 text-whiteshade font-bold">
            Login
        </button>
        </div>
    )
}

export default DoctorLogin