import React,{useState} from "react";
import { X } from "lucide-react";
import { useRef } from "react";
import { callback } from "../../utils/Forms";
import { toast } from "react-toastify";

const QueryPopup = ({ open }) => {
    const modelref = useRef();
    const closeModal = (e) => {
        if (modelref.current === e.target) {
            open();
        }
    };

    const [callbackdetails, setCallbackDetails] = useState({
        source: "/Home",
        subject: {
          name: "",
          phone: "",
          speciality: "",
          location: "",
        },
      });
    
      const handleChange = (e) => {
        setCallbackDetails({
          ...callbackdetails,
          subject: {
            ...callbackdetails.subject,
            [e.target.name]: e.target.value,
          },
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const phoneRegex = /^\d{10}$/;
        if (
          !callbackdetails.subject.name ||
          !callbackdetails.subject.phone ||
          !callbackdetails.subject.speciality ||
          !callbackdetails.subject.location
        ) {
          toast.error("Please fill all the fields");
          return;
        }
        if (!phoneRegex.test(callbackdetails.subject.phone)) {
          toast.error("Please enter a valid phone number");
          return;
        }
        const response = await callback(callbackdetails);
        if (response.status === "success") {
          toast.success(response.data.message);
          setCallbackDetails({
            source: "/Home",
            subject: {
              name: "",
              phone: "",
              speciality: "",
              location: "",
            },
          });
            open();
        } else {
          toast.error(response.message);
        }
        // console.log(callbackdetails);
      };

      

  return (
    <div ref={modelref} onClick={closeModal} className="fixed inset-0 flex items-center justify-center flex-col m-auto bg-black bg-opacity-30 backdrop-blur-sm z-50">
      <div className="flex w-[300px] md:w-[37%] justify-between items-center  rounded-xl rounded-b-none p-4 bg-blueshade">
        <p className="text-white font-semibold">Book Your Callback</p>
        <button onClick={open}>
          <X size={20} color="white" />
        </button>
      </div>
      <div className="rounded-bl-xl bg-whiteshade w-[300px] md:w-[37%] justify-evenly flex flex-col g:px-20 p-4 py-5 lg:py-12">
          <h1 className="lg:text-3xl text-xl font-bold">
            The surgery you need is just a click away!
          </h1>
          <div className="py-3 flex lg:flex-row flex-col justify-between">
            <input
              type="text"
              className="w-[100%] lg:w-[45%] h-[60px] border-[1px] border-blueshade placeholder:text-black rounded-lg p-3 my-2"
              placeholder="Name"
              name="name"
              onChange={handleChange}
              value={callbackdetails.subject.name}
            />
            <input
              type="text"
              className="w-[100%] lg:w-[45%] h-[60px] border-[1px] border-blueshade placeholder:text-black rounded-lg p-3 my-2 "
              placeholder="Phone Number"
              name="phone"
              onChange={handleChange}
              value={callbackdetails.subject.phone}
            />
          </div>
          <div className="py-3 flex lg:flex-row flex-col justify-between">
            <select
              className="w-[100%] lg:w-[45%] h-[60px] border-[1px] border-blueshade rounded-lg p-3 my-2 text-[#]"
              name="speciality"
              onChange={handleChange}
              value={callbackdetails.subject.speciality}
            >
              <option value="">Select Speciality</option>
              <option value="General Physician">General Physician</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Gastrology">Gastrology</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Urology">Urology</option>
              <option value="Neurology">Neurology</option>
            </select>
            <select
              className="w-[100%] lg:w-[45%] h-[60px] border-[1px] border-blueshade rounded-lg p-3 my-2"
              name="location"
              onChange={handleChange}
              value={callbackdetails.subject.location}
            >
              <option value="">Select Location</option>
              <option value="KPHB">KPHB</option>
              <option value="Madinaguda">Madinaguda</option>
              <option value="Madhapur">Madhapur</option>
              <option value="Miyapur">Miyapur</option>
            </select>
          </div>
          <button className="text-xl font-bold text-white bg-blueshade rounded-lg w-[100%] p-3"
          onClick={handleSubmit}
          >
            Book Consultation
          </button>
        </div>
    </div>
  );
};

export default QueryPopup;
