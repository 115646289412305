import { makeUnauthenticatedPOSTRequest } from "../Services/Servicehelper";

const host = process.env.REACT_APP_Backend_URL;
export const sendquery = async (query) => {
  try {
    const response = await makeUnauthenticatedPOSTRequest(
      `${host}/api/auth/createquery`,
      query
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const callback = async (callback) => {
  try {
    const response = await makeUnauthenticatedPOSTRequest(
      `${host}/api/auth/createcallback`,
      callback
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
