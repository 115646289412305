import React from "react";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest } from "../../Services/Servicehelper";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const getAppointments = async () => {
    const response = await makeAuthenticatedGETRequest(
      `${host}/api/admin/getallappointments`,
      token
    );
    // console.log(response);
    setAppointments(response.data);
  };

  useEffect(() => {
    getAppointments();
  }, []);
  return (
    <div>
      <h1 className="text-2xl font-semibold">Doctors</h1>
      <div className="mt-5">
        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
            <thead>
              <tr>
                <th className="border border-gray-900">Patient Name</th>
                <th className="border border-gray-900">Mobile</th>
                <th className="border border-gray-900">Doctor</th>
                <th className="border border-gray-900">Slot Info</th>
                <th className="border border-gray-900">Action</th>
              </tr>
            </thead>
            <tbody>{
                appointments.map((appointment) => (
                <tr key={appointment._id}>
                  <td className="border border-gray-900 p-2 text-center">{appointment.PatientName}</td>
                  <td className="border border-gray-900 p-2 text-center">{appointment.phonenumber}</td>
                  <td className="border border-gray-900 p-2 text-center">{appointment.doctor.user.name} - ({
                    appointment.doctor.speciality
                  })</td>
                  <td className="border border-gray-900 p-2 text-center">
                    <p>{appointment.slot.date.split("T")[0]} - {appointment.slot.time}</p>
                    <p>{appointment.slot.clinic.clinicname}</p>
                    <p>{appointment.slot.clinic.location}</p>
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    <Link to={`/`} className="bg-[blue] text-white px-3 py-1 rounded-md ml-2">Edit</Link>
                    <Link to={'/'} className="bg-[green] text-white px-3 py-1 rounded-md ml-2">View</Link>
                  </td>
                </tr>
                ))
                
                }</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Appointments;
