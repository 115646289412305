import { useState } from "react";
import AuthContext from "./AuthContext";
import {makeAuthenticatedGETRequest} from "../../Services/Servicehelper";



const AuthState = (props) => {
    const [role, setRole] = useState("Patient");
    const [location, setLocation] = useState("");
    const [searchvalue, setSearchvalue] = useState("");
    const host = process.env.REACT_APP_Backend_URL; 
    const [userdata, setUserData] = useState({
        name: "",
        email: "",
        role: "Patient",
    });
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [BookingData, setBookingData] = useState({
        doctor: "",
        date: "",
        slot: "",
        PatientName: "",
        email: "",
        phonenumber: "",
        paymentcost:"",
        saved:false
    });

    const Logout=()=>{
        console.log("Logging out");
        localStorage.removeItem('token');
        setRole("Patient");
        setUserData({ name: "", email: "", role: "" });
        window.location.href="/";
    }

    const getuser = async () => {
        // console.log(localStorage.getItem('token'));
        if (!localStorage.getItem('token')) {
            console.log("No token found");
            setRole("Patient"); // Update role state
            return; // Exit function early if no token is found
        }
        // console.log(host);
    
        const token = localStorage.getItem('token');
        try{
            const response = await makeAuthenticatedGETRequest(`${host}/api/auth/getUser`, token);
            // console.log(response);
            setRole(response.data[0].role);
            setUserData({ name: response.data[0].name, email: response.data[0].email, role: response.data[0].role });
        }
        catch(err){
            console.log(err);
        }
    }

    return <AuthContext.Provider value={{role,searchvalue,setLocation,setSearchvalue,location, getuser,Logout,PhoneNumber,setPhoneNumber,userdata,setUserData,BookingData,setBookingData}}>{props.children}</AuthContext.Provider>;
}

export default AuthState;