import react, { useEffect } from "react";
import {
  Clock,
  UserRoundCheck,
  BriefcaseBusiness,
  MapPin,
  IndianRupee,
  CalendarClock 
} from "lucide-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext,useState } from "react";
import Authcontext from "../../Context/Auth/AuthContext";

const BookDoctorCard = (props) => {

  const { doctor,bookingbtn } = props;
  const navigate = useNavigate();
  const { BookingData, setBookingData } = useContext(Authcontext);
  const days =["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const [availableToday, setAvailableToday] = useState(false);

  useEffect(() => {
    setAvailableToday(false);
    const today = days[new Date().getDay()];
    if (doctor?.workingClinics?.some(clinic => clinic?.workingdays[today]?.length > 0)) {
      setAvailableToday(true);
    }else {
      setAvailableToday(false); 
    }
  }, [doctor]);

  return (
    <div className="flex border border-[#D1D1D1] md:flex-row flex-col rounded-xl gap-2 p-5 my-4 w-full  rounded-br-none">
      <div className="flex flex-row ">
        <img
          src={doctor.profilepic}
          className="md:w-[225px] md:h-[180px] h-[100px] w-[100px] mr-4 object-cover"
          alt=""
        />
        <div className="md:hidden">
        <h1 className="text-[23px] font-bold text-blueshade mb-2">
            {doctor.user.name}
          </h1>
          <h1 className="text-[20px] font-bold mb-2">{doctor.speciality}</h1>
        </div>
        </div>
      <div className="flex gap-2 w-full justify-between lg:flex-row flex-col">
        <div className="flex flex-col justify-center">
          <h1 className="xl:text-[36px] hidden md:block text-4xl font-bold text-blueshade mb-2">
            {doctor.user.name}
          </h1>
          <h1 className="xl:text-[24px] text-2xl hidden md:block font-bold mb-2">{doctor.speciality}</h1>
          {
            doctor.Qualifications.map((qualification, index) => (
              <p key={index} className="xl:text-lg lg:text-md text-sm  mb-2  ">
                {qualification.course} {qualification.speciality ? ` - ${qualification.speciality}` : ""} 
              </p>
            ))
          }
          <div className="flex gap-2 items-center">
            {/* <img src="/assests/timing.svg" className="w-5 h-5" alt="" /> */}
            <CalendarClock size={20} color="#f17a20" />
            <p className="xl:text-lg lg:text-md text-sm font-semibold">
              {/* Timings : 12 AM to 8PM */}
            </p>
            {availableToday  ? <p className="text-[green] xl:text-lg lg:text-md text-sm font-semibold">Available Today</p> : <p className="text-[red] xl:text-lg lg:text-md text-sm font-semibold">Not Available Today</p>}
          </div>
        </div>
      
      <div className="flex gap-2 flex-col justify-center  lg:px-3">
        <div className="flex gap-2 lg:flex-col flex-row lg:flex-nowrap flex-wrap  lg:justify-evenly">
          <div className="flex gap-2  items-center">
            <img src="/assests/verified.svg" className="w-5 h-5" alt="" />
            <p className=" xl:text-lg lg:text-md text-sm ">
              Medical Registration Verified
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <img src="/assests/experience.svg" className="w-5 h-5" alt="" />
            <p className=" xl:text-lg lg:text-md text-sm ">
              {doctor.experience} Years Experience
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <img src="/assests/location.svg" className="w-5 h-5" alt="" />
            
            <div className="flex  gap-2">
            {doctor.workingClinics.map((clinics, index) => (
              <p key={index} className=" xl:text-lg lg:text-md text-sm ">
                {clinics.clinic.location}
              </p>
            ))    }
            </div>
          </div>
          <div className="flex gap-2 items-center">
            {/* <img src="/assests/fee.svg" className="w-5 h-5" alt="" /> */}
            <IndianRupee size={20} color="#f17a20" />
            <p className=" xl:text-lg lg:text-md text-sm ">
              Consultation Fee : <b>
              {doctor?.workingClinics[0]?.Consultationfee}
              </b>
            </p>
          </div>
        </div>
        <div className="flex gap-2 mt-2 xl:justify-end">
          <Link to={`/doctors/${doctor._id}`} className="xl:text-lg lg:text-md text-sm  w-[150px] border font-semibold border-blueshade p-2 text-center rounded-lg rounded-br-none">
            View Profile
          </Link>
          {bookingbtn && <button onClick={
            () => {
              setBookingData({ 
                doctor: doctor._id,
                date: "",
                slot: "",
                PatientName: "",
                email: "",
                phonenumber: "",
                paymentcost: "",
                saved: false
               });
              navigate("/book-appointment");
            }
          }  className="bg-[#1651C6] xl:text-lg lg:text-md text-sm  w-[150px] font-semibold text-white text-center p-2 rounded-lg rounded-br-none">
            Book Now
          </button>}
        </div>
        </div>
      </div>
    </div>
  );
};

export default BookDoctorCard;
