import react from "react";
import { useContext, useEffect,useState } from "react";
import AuthContext from "../../Context/Auth/AuthContext";
import { Link, Outlet } from "react-router-dom";
import { UserRound ,CalendarClock,MessagesSquare} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "lucide-react";
import { useLocation } from "react-router-dom";

const AccountLayout = () => {
  const context = useContext(AuthContext);
  const { role, userdata, Logout } = context;
  const { setBookingData, setSearchvalue, setLocation } = context;
  const navigate = useNavigate();
  const [activeBar, setActiveBar] = useState("");

  const location = useLocation();

  useEffect(() => {
    setBookingData({ 
      doctor: "",
      date: "",
      slot: "",
      PatientName: "",
      email: "",
      phonenumber: "",
      paymentcost:"",
      saved:false
     });
     setSearchvalue("");
     setLocation("");
  }, []);

  useEffect(() => {
    setActiveBar(location.pathname.split("/")[2]);
  }, [location]);
  

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      if (location.pathname === "/Account") {
        navigate("/Account/Profile");
        setActiveBar("Profile");
      }
    }
  }, [role]);
  return (
    <div>
      <div
        className=" bg-whiteshade z-50 p-5"
        style={{ position: "sticky", top: "0" }}
      >
        <div className="flex max-w-6xl w-full m-auto justify-between items-center">
          <Link to="/" className="-m-1.5 p-1.5">
            <img
              className="sm:h-14 h-10 w-auto"
              src="/assests/Logo.svg"
              alt=""
            />
          </Link>
          <div className="custom1:flex hidden flex-row gap-2 justify-center items-center">
            <h1 className="text-2xl sm:text-md font-bold text-[#1651C6]">
              {userdata.name}
            </h1>
            {/* <UserRound color="#1651C6" size={30} /> */}
          </div>
        </div>
      </div>
      <div className="max-w-6xl w-full m-auto">
        <div className="flex border-2 rounded-[20px] shadow-md items-stretch gap-2 mt-5 xl:mx-0 m-5  p-5 ">
          <div className="flex flex-col gap-3 justify-between sm:w-[30%]">
            <div className="flex flex-col gap-3 w-[100%]" >
            <Link
              to="/Account/Profile"
              className={`p-3 border-2 border-l-8 ${activeBar === "Profile" ? "border-l-[#1651C6]" :"border-l-[#9A9A9A]" } w-[100%] bg-[#F6F8FF] rounded-[10px] border-[#9BBDFF]`}
              onClick={
                () => setActiveBar("Profile")
              }
            >
              <h1 className="font-bold hidden sm:block">Profile</h1>
              <UserRound size={20} className="sm:hidden" color="#1651C6" />
            </Link>
            <Link
              to="/Account/Appointments"
              className={`p-3 border-2 border-l-8 ${activeBar === "Appointments" ? "border-l-[#1651C6]" :"border-l-[#9A9A9A]" } w-[100%] bg-[#F6F8FF] rounded-[10px] border-[#9BBDFF]`}
              onClick={
                () => setActiveBar("Appointments")
              }
            >
              <h1 className="font-bold hidden sm:block">Appointments</h1>
              <CalendarClock size={20} className="sm:hidden" color="#1651C6" />
            </Link>
            <Link
              to="/Account/Queries"
              className={`p-3 border-2 border-l-8 ${activeBar === "Queries" ? "border-l-[#1651C6]" :"border-l-[#9A9A9A]" } w-[100%] bg-[#F6F8FF] rounded-[10px] border-[#9BBDFF]`}
              onClick={
                () => setActiveBar("Queries")
              }
            >
              <h1 className="font-bold hidden sm:block">Queries</h1>
              <MessagesSquare size={20} className="sm:hidden" color="#1651C6" />
            </Link>
            </div>
            <div className="flex flex-col gap-3 sm:w-[100%]">
            <Link
              onClick={() => {
                Logout();
                setActiveBar("");
              }}
              className={`p-3 border-2 border-l-8 ${activeBar === "" ? "border-l-[#1651C6]" :"border-l-[#9A9A9A]" } w-[100%] bg-[#F6F8FF] rounded-[10px] border-[#9BBDFF]`}
            >
              <h1 className="font-bold hidden sm:block">Logout</h1>
              <LogOut size={20} className="sm:hidden" color="#1651C6" />
            </Link>
            </div>
          </div>

          <div className="border-2 border-[#D0D0D0] "></div>

          <div className="flex-grow min-h-[500px] max-h[80vh] overflow-scroll no-scrollbar">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
