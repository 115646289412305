import React from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { useRef } from "react";
import { useEffect, useState } from "react";
import "./Navbar.css";
import { PhoneCall, LogIn, LogOut, CircleUser } from "lucide-react";
import Authcontext from "../../Context/Auth/AuthContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const nav = useRef();
  const showNav = () => {
    nav.current.classList.toggle("show-nav");
  };
  const [logged, setlogged] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setlogged(true);
    }
  }, []);

  
  return (
    <div
      className=" bg-white z-50"
      style={{ position: "sticky", top: "0" }}
    >
      <header className=" z-50 inset-x-0 top-0">
        <nav className="flex flex-wrap items-center justify-between max-w-6xl w-full m-auto xl:px-0 p-3 custom:p-5 xl:py-5 ">
          <div className="flex">
            <Link to="/" className="-m-1.5 p-1.5">
              <img className="sm:h-14 h-10 w-auto" src="/assests/Logo.svg" alt="" />
            </Link>
          </div>

          <div className="flex flex-col gap-3">
            <div className="hidden custom:flex justify-end gap-3 items-center">
              <div className="sm:flex hidden  items-center space-x-2">
                <img src="/assests/PhoneCall.svg" className="h-5" alt="" />
                <a href="tel:8800644744" className="text-black text-lg font-bold">+91 8800644744</a>
              </div>
              {!logged ? (
                <div className="flex items-center space-x-2">
                  <Link to="/login" className="flex items-center gap-2">
                    <img src="/assests/Login.svg" className="h-5" alt="" />
                    <p className="text-black text-lg font-bold"> Login</p>
                  </Link>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Link to="/Account">
                    <CircleUser color="#F17A20" size={25} />
                  </Link>
                  <p className="text-black text-lg font-bold"> Profile</p>
                </div>
              )}
              <div className="flex items-center space-x-2">
                <Link
                  to="/doctors"
                  className="bg-[#F27921] text-white px-3 py-1 rounded-br-none rounded-[18px]"
                >
                  Book Appointment
                </Link>
              </div>
            </div>
            <div
              ref={nav}
              className="hidden custom:flex md:flex-1 md:items-center md:justify-end md:space-x-6 navbar"
            >
              <Link to="/" className="text-black text-xl  hover:font-bold">
                Home
              </Link>
              <Link to="/treatments" className="text-black text-xl  hover:font-bold">
                Treatments
              </Link>
              <Link
                to="/doctors"
                className="text-black text-xl  hover:font-bold"
              >
                Our Doctors
              </Link>
              <Link
                to="/contact"
                className="text-black text-xl  hover:font-bold"
              >
                Contact Us
              </Link>
              <Link
                to="/for-doctors"
                className="text-black text-xl  hover:font-bold"
              >
                For Doctors
              </Link>
              {!logged ? (
                <Link
                to="/login"
                className="text-[orange] text-xl custom:hidden  hover:text-gray-900"
              >
                Login
              </Link>
              ) : (
                <div >
                  <Link to="/Account"
                  className="text-[orange] text-xl custom:hidden  hover:text-gray-900">
                    Profile
                  </Link>
                </div>
              )}
              <div className="custom:hidden">
                <button
                  onClick={showNav}
                  className="p-1.5 text-black text-3xl absolute top-5 right-5 focus:outline-none"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
            <div className="custom:hidden ">
              <button
                onClick={showNav}
                className="p-1.5 text-blueshade text-3xl focus:outline-none"
              >
                <FaBars />
              </button>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
