import React from "react";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest,makeAuthenticatedDELETERequest } from "../../Services/Servicehelper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Adminblogs = () => {
    const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const [Blogs, setBlogs] = useState([]);
  const fetchBlogs = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallblogs`,
        token
      );
      console.log(response);
      setBlogs(response.data);
    } catch (error) {
      console.log(error);
    }
  };

    useEffect(() => {   
    fetchBlogs();
    }, []);

    const deleteBlog = async (id,blogname) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      const enteredName = window.prompt("Please enter the name of the blog to delete");
      if (enteredName!==blogname) {
        toast.info("Correct Blog's name is required to proceed.");
        return;
      }
      const response = await makeAuthenticatedDELETERequest(
        `${host}/api/admin/deleteblog?blogid=${id}`,
        token
      );
      console.log(response);
      if (response.status === "success") {
        toast.success("Blog Deleted Successfully");
        fetchBlogs();
      }
    
    }
  }
  return (
    <div>
      <h1 className="text-2xl font-semibold">Blogs</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <Link
            to="/admin/addblog"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Blog
          </Link>
        </div>
        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
              <thead>
                <tr>
                  <th className="border border-gray-900">Treatment</th>
                  <th className="border border-gray-900">Heading</th>
                  <th className="border border-gray-900">Actions</th>
                </tr>
              </thead>
            <tbody>
              {Blogs.map((blog) => (
                <tr key={blog._id}>
                  <td className="border border-gray-900 p-2 text-center" >
                    {blog.Title}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {blog.Active ? (
                      <div className="rounded-xl text-[green]">Active</div>
                    ) : (
                      <div className="rounded-xl  text-[red]">Inactive</div>
                    )}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    <Link
                      to={`/admin/editblog/${blog._id}`}
                      className="bg-[blue] text-white px-3 py-1 rounded-md"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteBlog(blog._id,blog.Title)}
                      className="bg-[red] text-white px-3 py-1 mx-2 rounded-md"
                    >
                      Delete
                    </button>
                  </td>
                </tr> 
              ))}
                
            </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default Adminblogs;
