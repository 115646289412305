import React from "react";
import { useState, useEffect } from "react";
import {
  makeAuthenticatedGETRequest,
  makeAuthenticatedPATCHRequest,
  makeUnauthenticatedPOSTRequestmultipart,
} from "../../Services/Servicehelper";
import { useNavigate, useParams } from "react-router-dom";
import beautify from "js-beautify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy } from 'lucide-react';
import { toast } from "react-toastify";

const EditTreatment = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    Treatmentname: "",
    Specialityname: "",
    Logo: "",
    Heading: "",
    Description: "",
    Content: "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [treatmentcategories, setTreatmentcategories] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");
  let { id } = useParams();
  const imagechange = (e) => {
    const validTypes = ["image/svg+xml"];
    const selectedFile = e.target.files[0];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setImage(selectedFile);
    } else {
      setImage(null);
      toast.error("Please select a valid image file");
      return;
    }
  };

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      toast.error("Please select an image");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setData((prev) => {
          return {
            ...prev,
            Logo: response.data.Url,
          };
        });
        setNewData((prev) => {
          return {
            ...prev,
            Logo: response.data.Url,
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getdata = async () => {
    if (!token || token === "undefined") {
      toast.warn("Please login to continue");
      return;
    }

    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/gettreatmentdetails?treatmentid=${id}`,
        token
      );
      if (response.statusCode === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getalltreatmentcategories = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getalltreatmentcategories`,
        token
      );
      if (response.statusCode === 200) {
        // console.log(response.data);
        setTreatmentcategories(
          response.data.map((category) => {
            return {
              value: category.categoryname,
              label: category.categoryname,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
    getalltreatmentcategories();
  }, []);

  const InputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setNewData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submithandler = async (e) => {
    if (!token || token === "undefined") {
      toast.warn("Please login to continue");
      return;
    }
    try {
      const response = await makeAuthenticatedPATCHRequest(
        `${host}/api/admin/updatetreatment?treatmentid=${id}`,
        newData,
        token
      );
      if (response.statusCode === 200) {
        toast.success("Treatment Updated Successfully");
        navigate("/admin/treatment");
        // window.location.href = "/admin/clinics";
      }
    } catch (err) {
      console.log(err);
    }
  };


  const codeSnippets = {
    heading: `<p class="xl:text-2xl my-2 text-xl font-bold">What is gynacology</p>`,
    paragraph: `<p class="xl:text-lg lg:text-md text-sm">
 Praesent rutrum commodo sapien, sed porta lorem.
</p>`,
    horizontalLine: `<hr class="border-t-2 my-3 border-lightshade w-[100%]" />`,
    bulletPoints: `<ul role="list" class="marker:text-blueshade list-disc pl-5 space-y-3 text-slate-400">
<li class="xl:text-lg lg:text-md text-sm">Lorem ipsum </li>
<li class="xl:text-lg lg:text-md text-sm">Praesent rutrum commodo </li>
</ul>`,
  };

  const handlecopy = (key) => {
    // e.preventDefault();
    navigator.clipboard.writeText(codeSnippets[key]);
    toast.info("Copied to clipboard");
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold">Edit Treatment</h1>
      <div className="mt-5 grid grid-cols-2 gap-2">
        <div className="w-full flex flex-col ">
          <label htmlFor="Treatmentname">Treatment Name</label>
          <input
            type="text"
            id="Treatmentname"
            name="Treatmentname"
            placeholder="Treatment Name"
            className="border border-gray-900 p-2 rounded-md"
            required
            onChange={InputChange}
            value={data.Treatmentname}
          />
        </div>
        <div className="w-full flex flex-col ">
          <label htmlFor="Specialityname">Treatment Category</label>
          {/* <input
            type="text"
            id="Specialityname"
            name="Specialityname"
            placeholder="Speciality"
            className="border border-gray-900 p-2 rounded-md"
            required
            onChange={InputChange}
            value={data.Specialityname}
          /> */}
          <Select
                  options={treatmentcategories}
                  value={{
                    value: data?.Specialityname,
                    label: data?.Specialityname,
                  }}
                  className="w-full border border-gray-900 rounded-md"
                  onChange={(e) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        Specialityname: e.value,
                      };
                    });
                    setNewData((prev) => {
                      return {
                        ...prev,
                        Specialityname: e.value,
                      };
                    });
                  }}
                  required
                />
        </div>
      </div>
      <div className="mt-5 flex">
        <div className="w-1/2">
          <h2 className="text-xl ">Logo Upload</h2>
          {data.Logo !== "" && (
            <img src={data.Logo} alt="logo" className="w-20 h-20" />
          )}
          <div className="flex gap-2 mt-2">
            <input
              type="file"
              className="border border-gray-900 p-2 rounded-md"
              accept="image/svg+xml"
              onChange={imagechange}
              required
            />
            {!loading ? (
              <button
                onClick={upload}
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Upload
              </button>
            ) : (
              <button
                disabled
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Uploading...
              </button>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <label htmlFor="Heading">Heading</label>
          <input
            type="text"
            id="Heading"
            name="Heading"
            placeholder="Heading"
            className="border border-gray-900 p-2 rounded-md mt-2 w-full"
            required
            onChange={InputChange}
            value={data.Heading}
          />
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="Description">Description</label>
        <textarea
          id="Description"
          name="Description"
          placeholder="Description"
          className="border border-gray-900 p-2 rounded-md mt-2 w-full"
          required
          cols="30"
          rows="4"
          onChange={InputChange}
          value={data.Description}
        />
      </div>
      <div className="mt-5">
        <h1 className="text-xl font-semibold mt-5">HTML</h1>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">

          <p className="text-lg font-semibold">Heading
            
          </p>
          <Copy size={20} className="cursor-pointer" onClick={()=>handlecopy("heading")}/>
          </div>
             <div className="relative overflow-x-auto">
            <SyntaxHighlighter 
              language="html" 
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whiteSpace='pre-wrap'
              >
              {codeSnippets["heading"]}
            </SyntaxHighlighter>
            </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">

          <p className="text-lg font-semibold">Paragraph
            
          </p>
          <Copy size={20} className="cursor-pointer" onClick={()=>handlecopy("paragraph")}/>
          </div>
             <div className="relative overflow-x-auto">
            <SyntaxHighlighter 
              language="html" 
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whiteSpace='pre-wrap'
              >
              {codeSnippets["paragraph"]}
            </SyntaxHighlighter>
            </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">

          <p className="text-lg font-semibold">Horizontal Line
            
          </p>
          <Copy size={20} className="cursor-pointer" onClick={()=>handlecopy("horizontalLine")}/>
          </div>
             <div className="relative overflow-x-auto">
            <SyntaxHighlighter 
              language="html" 
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whiteSpace='pre-wrap'
              >
              {codeSnippets["horizontalLine"]}
            </SyntaxHighlighter>
            </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">

          <p className="text-lg font-semibold">Bullet Points
            
          </p>
          <Copy size={20} className="cursor-pointer" onClick={()=>handlecopy("bulletPoints")}/>
          </div>
             <div className="relative overflow-x-auto">
            <SyntaxHighlighter 
              language="html" 
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whiteSpace='pre-wrap'
              >
              {codeSnippets["bulletPoints"]}
            </SyntaxHighlighter>
            </div>
        </div>
      </div>
      <div className="mt-5">
        <label className="font-semibold" htmlFor="Content">
          Content (Write in HTML)
          {/* <button
            onClick={handleformat}
            className="bg-[blue] ml-5 text-white px-3 py-1 rounded-md"
          >
            Format
          </button> */}
        </label>
        <textarea
          id="Content"
          name="Content"
          placeholder="Content"
          className="border border-gray-900 p-2 rounded-md mt-2 w-full"
          required
          cols="30"
          rows="10"
          onChange={InputChange}
          value={data.Content}
        />
      </div>
      <div className="flex mt-5">
        <button
          onClick={submithandler}
          className="bg-[blue] text-white px-3 py-1 rounded-md"
        >
          Update Treatment
        </button>
      </div>
    </div>
  );
};

export default EditTreatment;
