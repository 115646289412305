import react from "react";
import { Link } from "react-router-dom";

const Appointmentcard = ({ appointment }) => {
  const timeString = appointment.slot.time;
  let [time, period] = timeString.split(" ");
  let [hours, minutes] = time.split(":");
  return (
    <div className="border-2 flex cursor-pointer hover:bg-white flex-col items-start custom:flex-row justify-between custom:items-center border-[#9BBDFF] bg-[#F6F8FF] rounded-[10px] px-3 w-[100%] p-2 my-2">
      <div className="flex flex-row gap-4 items-center">
        <img src="/assests/GeneralPhysician.svg" className="h-16 hidden md:block" alt="" />
        <div>
          <h1 className="text-xl font-bold">{appointment.doctor.user.name}</h1>
          <p className="text-md ">{appointment.doctor.speciality}</p>
        </div>
      </div>
      <div className="flex flex-row my-2">
        <div className="flex flex-col gap-2 custom:mx-0 md:ml-20">
          <div className="flex flex-row gap-3 items-center">
            <img src="/assests/timing.svg" className="h-4 w-5" alt="" />
            <div className="flex sm:flex-row gap-2 flex-col">

            
            <p className="font-bold">
              {new Date(appointment.slot.date)
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\//g, "-")}
            </p>
            <p className="font-bold">{`${hours}:${minutes} ${period.toUpperCase()}`}</p>
            </div>
          </div>
          <div className="flex flex-row gap-3 items-center">
            <img src="/assests/location.svg" className="h-5 w-5" alt="" />
            <p className="font-bold">{appointment.slot.clinic.location}</p>
          </div>
          <Link
            to={`/doctors/${appointment.doctor._id}`}
            className="bg-blueshade text-white font-bold py-1 mx-1 text-center rounded-[10px]"
          >
            View Doctor
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Appointmentcard;
