import React from "react";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest,makeAuthenticatedDELETERequest } from "../../Services/Servicehelper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AdminTreatments = () => {
  const [treatments, setTreatments] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const fetchTreatments = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getalltreatments`,
        token
      );
      // console.log(response);
      setTreatments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTreatments();
  }, []);

  const delTreatment = async (id) => {
    if (window.confirm("Are you sure you want to delete this treatment?")) {
      const response = await makeAuthenticatedDELETERequest(
        `${host}/api/admin/deletetreatment?treatmentid=${id}`,
        token
      );
      // console.log(response);
      if (response.status === "success") {
        toast.success("Treatment Deleted Successfully");
        fetchTreatments();
      }
    }
  };


  return (
    <div>
      <h1 className="text-2xl font-semibold">Treatments</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <Link
            to="/admin/addtreatment"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Treatment
          </Link>
        </div>
        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
              <thead>
                <tr>
                  <th className="border border-gray-900">Treatment</th>
                  <th className="border border-gray-900">Heading</th>
                  <th className="border border-gray-900">Actions</th>
                </tr>
              </thead>
            <tbody>
              {treatments.map((treatment) => (
                <tr key={treatment._id}>
                  <td className="border border-gray-900 p-2 text-center" >
                    {treatment.Treatmentname}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {treatment.Heading}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    <Link
                      to={`/admin/edittreatment/${treatment._id}`}
                      className="bg-[blue] text-white px-3 py-1 m-2 rounded-md"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => delTreatment(treatment._id)}
                      className="bg-[red] text-white px-3 py-1 rounded-md"
                    > Delete
                    </button>
                  </td>
                </tr> 
              ))}
                
            </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default AdminTreatments;
