import react from 'react';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest } from "../../Services/Servicehelper";



const Callbacks = () => {

    const [callbacks, setCallbacks] = useState([]);
    const host = process.env.REACT_APP_Backend_URL;
    const token = localStorage.getItem("token");

    const fetchCallbacks = async () => {
        try {
            const response = await makeAuthenticatedGETRequest(
                `${host}/api/admin/getallcallbacks`,
                token
            );
            console.log(response);
            setCallbacks(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCallbacks();
    }
    , []);

    return (
        <div>
      <h1 className="text-2xl font-semibold">Callbacks</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          {/* <a
            href="/admin/addclinic"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Clinic
          </a> */}
        </div>

        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
              <thead>
                <tr>
                  <th className="border border-gray-900">Path</th>
                  <th className="border border-gray-900">Location</th>
                  <th className="border border-gray-900">Phone Number</th>
                  <th className="border border-gray-900">Location</th>
                  <th className="border border-gray-900">Speciality</th>
                </tr>
              </thead>
            <tbody>
              {callbacks.map((Callback) => (
                <tr key={Callback._id}>
                  <td className="border border-gray-900 p-2 text-center" >
                    {Callback.source}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {Callback.subject.location}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {Callback.subject.phone}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {Callback.subject.location}
                    </td>
                    <td className="border border-gray-900 p-2 text-center">
                    {Callback.subject.speciality}
                    </td>
                  {/* <td className="border border-gray-900 p-2 text-center">
                    <Link
                      to={`/admin/editclinic/${clinic._id}`}
                      className="bg-[blue] text-white px-3 py-1 rounded-md"
                    >
                      Edit
                    </Link>
                    <Link className="bg-[green] text-white px-3 py-1 rounded-md ml-2">
                      View
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    );
}


export default Callbacks;