import React from "react";
import { useState, useEffect } from "react";
import { makeUnauthenticatedGETRequest } from "../../Services/Servicehelper";
import { useContext } from "react";
import Authcontext from "../../Context/Auth/AuthContext";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Specialitybar = (props) => {
  const { fetchDoctors } = props;
  const [specialities, setSpecialities] = useState([]);
  const { setSearchvalue } = useContext(Authcontext);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const host = process.env.REACT_APP_Backend_URL;

  const btnnext = () => {
    let datesslider = document.querySelector(".datesslider");
    let dateboxes = document.querySelectorAll(".datebox");
    datesslider.scrollLeft =
      datesslider.scrollLeft + dateboxes[0].clientWidth + 20;
  };

  const btnprev = () => {
    let datesslider = document.querySelector(".datesslider");
    let dateboxes = document.querySelectorAll(".datebox");
    datesslider.scrollLeft =
      datesslider.scrollLeft - dateboxes[0].clientWidth - 20;
  };

  const fetchSpecialities = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getallspecialities`
      );
      //   console.log(response);
      if (response) {
        setSpecialities(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleclick = async (speciality) => {
    setSearchvalue(speciality);
    setTriggerFetch(true);
  };

  useEffect(() => {
    if (triggerFetch) {
      fetchDoctors(); // Call fetchDoctors when triggerFetch is true
      setTriggerFetch(false); // Reset trigger to avoid repeated calls
    }
  }, [triggerFetch, fetchDoctors]);
  useEffect(() => {
    fetchSpecialities();
  }, []);
  return (
    <div className="specialitybar">
      <h1 className="text-xl font-semibold mt-[10px] mb-[20px]">
        Select Speciality
      </h1>
      <div className="flex justify-center items-start flex-col">
        <div className="flex flex-row w-full items-center justify-center my-6 relative">
          <div onClick={btnprev} className="cursor-pointer">
            <ChevronLeft />
            {/* <img src="/assests/arrowleft.svg"   alt="" /> */}
          </div>
          <div className="flex-grow flex flex-row no-scrollbar overflow-x-scroll overflow-hidden scroll-smooth gap-[20px] datesslider mx-4">
            {specialities.map((speciality) => {
              return (
                <div
                  key={speciality._id}
                  className="datebox flex items-center justify-center flex-col rounded-md p-2 cursor-pointer"
                  onClick={() => {
                    handleclick(speciality.Specialityname);
                  }}
                >
                  <div className="w-[80px] h-[80px] rounded-full bg-[#F6F8FF] flex items-center justify-center">
                    <img
                      src={speciality.Logo}
                      alt=""
                      className="w-20 h-20 object-cover"
                    />
                  </div>
                  <p className="text-md font-semibold m-2 whitespace-nowrap">
                    {speciality.Specialityname}
                  </p>
                </div>
              );
            })}
          </div>
          <div onClick={btnnext} className="cursor-pointer">
            <ChevronRight />
            {/* <img src="/assests/arrow.svg" alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialitybar;
