import react, { useEffect,useState } from 'react';
import { makeAuthenticatedGETRequest,makeAuthenticatedPATCHRequest } from '../../Services/Servicehelper';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../Context/Auth/AuthContext';
import { toast } from 'react-toastify';

const Profile = () => {

    const host = process.env.REACT_APP_Backend_URL;
    const token = localStorage.getItem('token');

    const {setUserData} = useContext(AuthContext);



    const [patient, setPatient] = useState({
        name: '',
        email: '',
        phone: '',
        address: ''
    });

    const navigate = useNavigate();

    const [updateddata, setupdateddata] = useState({});

    const getpatient = async () => {
        try {
            const response = await makeAuthenticatedGETRequest(
                `${host}/api/patient/profile`,  token
      );
            // console.log(response);
            setPatient({
                name: response.data[0]?.user?.name || '',
                email: response.data[0]?.user?.email || '',
                phone: response.data[0]?.user?.phonenumber || '',
                address: response.data[0]?.Address || ''
            
            });
        } catch (error) {
            console.log(error);
        }
    }

    const updateprofile = async () => {
        try {
            if(patient.name === '' ) return toast.error('Please enter your name');
            const response = await makeAuthenticatedPATCHRequest(
                `${host}/api/patient/profile`, updateddata,token
            );
            // console.log(response);
            if (response.status==='success'){
                setUserData({name:response.data.user.name,email:response.data.user.email});
                toast.success('Profile Updated');
                navigate('/Account/Appointments');
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getpatient();
    }, [])

    return (
        <div className='mx-4'>
            <h1 className='font-bold text-xl md:text-2xl'>Profile</h1>
            <input type="text" placeholder="Name" className='border-2 border-gray-300 rounded-md w-full p-2 my-2' value={patient.name} name='name'
            onChange={
                (e) => {setPatient({ ...patient, name: e.target.value })
                setupdateddata({ ...updateddata, name: e.target.value })}
            
            } />
            <input type="email" placeholder="Email" className='border-2 border-gray-300 rounded-md w-full p-2 my-2' value={patient.email}
            onChange={
                (e) => {setPatient({ ...patient, email: e.target.value })
                setupdateddata({ ...updateddata, email: e.target.value })}

            } />
            <input type="text" placeholder="Phone" className='border-2 border-gray-300 rounded-md w-full p-2 my-2'value={patient.phone} disabled/>
            <textarea placeholder="Address" className='border-2 border-gray-300 rounded-md w-full p-2 my-2' value={patient.address} 
            onChange={
                (e) => {setPatient({ ...patient, address: e.target.value })
                setupdateddata({ ...updateddata, address: e.target.value })}
            }
            />
            <button onClick={updateprofile} className='bg-[#1651C6] text-white rounded-md w-full p-2 my-2'>Update</button>
        </div>
    )
}

export default Profile;