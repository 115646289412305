// App.js
import HomePage from "./Pages/HomePage";
import Contact from "./Pages/Contact";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "./Pages/AdminPages/AdminLogin";
import Doctors from "./Pages/Doctors";
import { useContext, useEffect } from "react";
import AuthContext from "./Context/Auth/AuthContext";
import Dashboard from "./Pages/AdminPages/Dashboard";
import AdminLayoutPage from "./Pages/AdminPages/AdminLayoutPage";
import AdminDoctors from "./Pages/AdminPages/AdminDoctors";
import AddDoctor from "./Pages/AdminPages/AddDoctor";
import EditDoctor from "./Pages/AdminPages/EditDoctor";
import Login from "./Pages/Login";
import ForDoctors from "./Pages/ForDoctors";
import Clinics from "./Pages/AdminPages/Clinics";
import AddClinic from "./Pages/AdminPages/AddClinic";
import EditClinic from "./Pages/AdminPages/EditClinic";
import ViewDoctorProfile from "./Pages/ViewDoctorProfile";
import BookAppointment from "./Pages/BookAppointment";
import Appointments from "./Pages/AdminPages/Appointments";
import Treatments from "./Pages/Treatments";
import TreatmentProfile from "./Pages/TreatmentProfile";
import Navbar from "./Components/HomePageComponents/Navbar";
import AccountLayout from "./Pages/UserAccountPages/AccountLayout";
import Profile from "./Components/AccountPageComponents/Profile";
import UserAppointments from "./Pages/UserAccountPages/UserAppointments";
import Queries from "./Pages/UserAccountPages/Queries";
import AdminTreatments from "./Pages/AdminPages/AdminTreatments";
import AddTreatment from "./Pages/AdminPages/AddTreatment";
import EditTreatment from "./Pages/AdminPages/EditTreatment";
import Specialities from "./Pages/AdminPages/Specialities";
import AddSpeciality from "./Pages/AdminPages/AddSpeciality";
import EditSpeciality from "./Pages/AdminPages/EditSpeciality";
import Services from "./Pages/AdminPages/Services";
import Addservice from "./Pages/AdminPages/AddService";
import EditService from "./Pages/AdminPages/EditService";
import Callbacks from "./Pages/AdminPages/Callbacks";
import AdminQueries from "./Pages/AdminPages/AdminQueries";
import TreatmentCategory from "./Pages/AdminPages/TreatmentCategory";
import Blog from "./Pages/Blog";
import ViewBlog from "./Pages/ViewBlog";
import Adminblogs from "./Pages/AdminPages/Adminblogs";
import CreateBlog from "./Pages/AdminPages/CreateBlog";
import EditBlog from "./Pages/AdminPages/EditBlog";
import 'react-image-crop/dist/ReactCrop.css'

function App() {
  const context = useContext(AuthContext);
  const { role, getuser } = context;

  useEffect(() => {
    getuser();
  }, []);

  return (
    <div className="App">
      {/* <Navbar/> */}
      <Routes>
      {role === "Admin" && (
          <Route path="/admin/*" element={<AdminLayoutPage />}>
            <Route exact path="dashboard" element={<Dashboard />} />
            <Route exact path="doctors" element={<AdminDoctors />} />
            <Route path="adddoctor" element={<AddDoctor/>} />
            <Route path="editdoctor/:id" element={<EditDoctor/>} />
            <Route path="clinics" element={<Clinics/>} />
            <Route path="addclinic" element={<AddClinic/>} />
            <Route path="editclinic/:id" element={<EditClinic/>} />
            <Route path="appointments" element={<Appointments/>} />
            <Route path="treatment" element={<AdminTreatments/>} />
            <Route path="addtreatment" element={<AddTreatment/>} />
            <Route path="edittreatment/:id" element={<EditTreatment/>} />
            <Route path="specialities" element={<Specialities/>} />
            <Route path="addspeciality" element={<AddSpeciality/>} />
            <Route path="editspeciality/:id" element={<EditSpeciality/>}/>
            <Route path="services" element={<Services/>} />
            <Route path="addservice" element={<Addservice/>} />
            <Route path="editservice/:id" element={<EditService/>} />
            <Route path="callbacks" element={<Callbacks/>} />
            <Route path="queries" element={<AdminQueries/>} />
            <Route path="treatmentcategories" element={<TreatmentCategory/>} />
            <Route path="blogs" element={<Adminblogs/>} />
            <Route path="addblog" element={<CreateBlog/>} />
            <Route path="editblog/:id" element={<EditBlog/>} />
          </Route>
        )}
        {role==="Patient" && (
          <Route path="/Account/*" element={<AccountLayout/>}>
            <Route exact path="Profile" element={<Profile/>} />
            <Route exact path="Appointments" element={<UserAppointments/>} />
            <Route exact path="Queries" element={<Queries/>} />

          </Route>
        )}
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/doctors" element={<Doctors />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/for-doctors" element={<ForDoctors/>} />
        <Route path="/doctors/:id" element={<ViewDoctorProfile/>} />
        <Route path="/treatments" element={<Treatments/>} />
        <Route path="/treatments/:id" element={<TreatmentProfile/>} />
        <Route path="/blogs" element={<Blog/>} />
        <Route path="/blogs/:id" element={<ViewBlog/>} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
        <Route path="/book-appointment" element={<BookAppointment/>} />
      </Routes>
    </div>
  );
}

export default App;