import react from 'react';
import { useState, useEffect } from 'react';
import { makeAuthenticatedGETRequest } from '../../Services/Servicehelper';

const Queries = () => {
    const host = process.env.REACT_APP_Backend_URL;
    const token = localStorage.getItem('token');
    const [queries, setQueries] = useState([]);
    const getqueries = async () => {
        try {
            const response = await makeAuthenticatedGETRequest(
                `${host}/api/patient/getqueries`, token
            );
            console.log(response);
            setQueries(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getqueries();
    }, []);
    return (
        <div className='max-h-[70vh] overflow-scroll px-5 no-scrollbar'>
            <h1 className='font-bold text-xl mb-3 md:text-2xl'>Queries</h1>
            <div className='flex flex-col gap-2 max-h-[70vh] overflow-scroll no-scrollbar'>
            {queries.map((query,index) => {
                return (
                    <div key={index} className={`border-2 p-2 rounded-lg ${query.status === "Pending" ? 'bg-[#c26b6b]' : 'bg-[#78cb78]'}`}> 
                        <h1>{query.subject.message}</h1>
                    </div>
                )})
            }
            </div>
        </div>
    );
};

export default Queries;