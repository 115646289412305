import React from "react";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest } from "../../Services/Servicehelper";
import { Link } from "react-router-dom";

const Clinics = () => {
  const [clinics, setClinics] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const fetchClinics = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/allclinicdetails`,
        token
      );
      // console.log(response);
      setClinics(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Clinics</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <Link
            to="/admin/addclinic"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Clinic
          </Link>
        </div>

        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
              <thead>
                <tr>
                  <th className="border border-gray-900">Clinic Name</th>
                  <th className="border border-gray-900">Location</th>
                  <th className="border border-gray-900">Phone Number</th>
                  <th className="border border-gray-900">Actions</th>
                </tr>
              </thead>
            <tbody>
              {clinics.map((clinic) => (
                <tr key={clinic._id}>
                  <td className="border border-gray-900 p-2 text-center" >
                    {clinic.clinicname}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {clinic.location}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {clinic.Clinicphone}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    <Link
                      to={`/admin/editclinic/${clinic._id}`}
                      className="bg-[blue] text-white px-3 py-1 rounded-md"
                    >
                      Edit
                    </Link>
                    {/* <Link className="bg-[green] text-white px-3 py-1 rounded-md ml-2">
                      View
                    </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Clinics;
