import React,{useState,useEffect} from "react";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import BlogCard from "../Components/HomePageComponents/BlogCard";
import { Link } from "react-router-dom";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const [featuredBlog, setFeaturedBlog] = useState({
    Title: "",
    Description: "",
    Content: "",
  });

  const getblogs = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getblogs`
      );
      console.log(response);

      if (response) {
        setBlogs(response.data);
        setFeaturedBlog(response.data.filter((blog) => blog.Active === true)[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getblogs();
  }
  , []);

  return (
    <div className=" min-h-[100vh]  flex flex-col">
      <Navbar />
      <div className="bg-whiteshade py-20 p-5 lg:px-20   ">
        <div className=" flex justify-between items-center max-w-6xl w-full mx-auto">
          <img
            src={featuredBlog.Image}
            className="lg:w-[36%] w-[38%] object-cover hidden md:block"
          />
          <div className="md:w-[60%]">
            <div className="flex items-start flex-col justify-center ">
              <h1 className="xl:text-4xl text-2xl  font-semibold">
                {/* Quality Health Care At Your Convenience!! */}
                {featuredBlog.Title}
              </h1>
              <div className="h-[3px] bg-whiteshade w-24 my-2"></div>
            </div>
            <p className="text-black text-[18px] leading-7">
              {featuredBlog.Description}
            </p>
            <div className="mt-2">
              <Link
                className=" text-white bg-blueshade rounded-[10px] px-3 py-2"
                to={`/blogs/${featuredBlog._id}`}
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 p-5 lg:px-10   ">
        <div className=" flex justify-center flex-col items-center max-w-6xl w-full mx-auto">
          <h1 className="xl:text-4xl text-2xl  font-semibold">
            News And Articles
          </h1>{" "}
          <p className="text-black text-[18px] text-center m-3 w-[80%]">
            Get Latest Updates here !!
          </p>
          <div>
          <div className="max-w-6xl w-full my-10 mx-auto gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
          {blogs.map((blog, index) => (
            <BlogCard key={index} blog={blog} />
          ))}
        </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
