import React, { useState, useEffect } from "react";
import {
  makeAuthenticatedGETRequest,
  makeAuthenticatedPATCHRequest,
  makeUnauthenticatedPOSTRequestmultipart,
  makeAuthenticatedPOSTRequest,
} from "../../Services/Servicehelper";
import { useParams } from "react-router-dom";
import { Plus, CircleMinus, BadgeCheck, Minus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import Select from "react-select";
import { toast } from "react-toastify";
import EditImagepopup from "../../Components/Admin Components/BasicComponents/EditImagepopup";

const EditDoctor = () => {
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");
  const [image, setImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [createslotsdata, setcreateslotsdata] = useState({
    doctorId: "",
    clinicId: "",
    date: "",
  });
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    user: {
      name: "",
      email: "",
      phonenumber: "",
    },
    age: "",
    gender: "",
    regNo: "",
    speciality: "",
    about: "",
    profilepic: "",
    Procedures: [],
    Qualifications: [],
    consultationFee: [],
    workingClinics: [],
    WorkExperience: [],
    metatags:[],
    Importantdocs: {
      Aadhar: "",
      PAN: "",
      Registration: "",
    },
    isActive: true,
    experience: "",
  });

  let { id } = useParams();

  const [Education, setEducation] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [workingClinics, setWorkingClinics] = useState([]);
  const [WorkExperience, setWorkExperience] = useState([]);
  const [file, setFile] = useState(null);
  const [services, setservices] = useState([]);
  const [selectedServices, setselectedServices] = useState({
    Procedures: [],
  });
  const [Unavailabilitydata, setUnavailabilitydata] = useState({
    clinicIds: [],
    startdate: "",
    enddate: "",
    doctorId: `${id}`,
  });

  const [specialityoptions, setspecialityoptions] = useState([]);
  const fetchSpecialities = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallspecialities`,
        token
      );
      // console.log(response);
      setspecialityoptions(
        response.data.map((speciality) => {
          return {
            value: speciality.Specialityname,
            label: speciality.Specialityname,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const clinicoptions = workingClinics.map((clinic) => {
    return {
      value: clinic.clinic._id,
      label: clinic.clinic.clinicname,
    };
  });

  const getdetails = async () => {
    const response = await makeAuthenticatedGETRequest(
      `${host}/api/admin/getDoctorDetails?docid=${id}`,
      token
    );
    // console.log(response);
    setUserData(response.data);
    setEducation(response.data.Qualifications);
    setWorkingClinics(response.data.workingClinics);
    setWorkExperience(response.data.WorkExperience);
    setcreateslotsdata({
      ...createslotsdata,
      doctorId: response.data._id,
    });
  };

  const fetchClinics = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/allclinicdetails`,
        token
      );
      // console.log(response);
      setClinics(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchservices = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getallservices`,
        token
      );
      console.log(response);
      setservices(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdetails();
    fetchClinics();
    fetchSpecialities();
    fetchservices();
  }, []);

  const addeducation = () => {
    setEducation([
      ...Education,
      { startdate: "", enddate: "", course: "", college: "", speciality: "" },
    ]);
    setNewData({ ...newData, Qualifications: Education });
  };

  const removeEducation = (index) => {
    const temp = [...Education];
    temp.splice(index, 1);
    setEducation(temp);
    setNewData({ ...newData, Qualifications: temp });
  };

  const addClinic = () => {
    setWorkingClinics([
      ...workingClinics,
      {
        clinic: "",
        slotduration: 10,
        Consultationfee: 200,
        workingdays: {
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
          Sunday: [],
        },
      },
    ]);
  };

  const removeClinic = (index) => {
    const temp = [...workingClinics];
    temp.splice(index, 1);
    setWorkingClinics(temp);
    setNewData({ ...newData, workingClinics: temp });
  };

  const submithandler = () => {
    // Update newData with the current state of Education
    const updatedData = {
      ...newData,
      Qualifications: Education,
      workingClinics: workingClinics,
      WorkExperience: WorkExperience,
    };

    // console.log(updatedData);

    setNewData(updatedData);

    setNewData((prevData) => {
      // console.log(prevData);
      makeAuthenticatedPATCHRequest(
        `${host}/api/admin/updateDoctor?docid=${id}`,
        prevData,
        token
      )
        .then((response) => {
          toast.success("Doctor Updated Successfully");
          navigate("/admin/doctors");
        })
        .catch((error) => {
          console.error("Error updating doctor:", error);
        });

      // Return the updated data
      return prevData;
    });
    // console.log(newData);
  };

  // Use useEffect to log the updated state of newData
  // useEffect(() => {
  //   console.log(newData);
  // }, [newData]);

  const onchagehandler = (e) => {
    const { name, value } = e.target;
    // console.log(name,value)
    if (name.startsWith("user.")) {
      setUserData({
        ...userData,
        user: { ...userData.user, [name.split(".")[1]]: value },
      });
      setNewData({
        ...newData,
        user: { ...userData.user, [name.split(".")[1]]: value },
      });
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
      setNewData({ ...newData, [name]: value });
    }
  };

  const addslot = (day, idx) => {
    return () => {
      const temp = [...workingClinics];
      const tempday = temp[idx].workingdays[day];
      tempday.push({ startTime: "", endTime: "" });
      temp[idx].workingdays[day] = tempday;
      // console.log(temp);
      setWorkingClinics(temp);
    };
  };

  const removeSlot = (day, i, j) => {
    return () => {
      const temp = [...workingClinics];
      const tempday = temp[i].workingdays[day];
      tempday.splice(j, 1);
      temp[i].workingdays[day] = tempday;
      // console.log(temp);
      setWorkingClinics(temp);
    };
  };

  const addWorkExperience = () => {
    setWorkExperience([
      ...WorkExperience,
      {
        startdate: "",
        enddate: "",
        Place: "",
        speciality: "",
        role: "",
        yearsofexperience: "",
      },
    ]);
  };

  const removeWorkExperience = (index) => {
    const temp = [...WorkExperience];
    temp.splice(index, 1);
    setWorkExperience(temp);
    setNewData({ ...newData, WorkExperience: temp });
  };

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      toast.error("Please select an image");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            profilepic: response.data.Url,
          };
        });
        setNewData((prev) => {
          return {
            ...prev,
            profilepic: response.data.Url,
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const imagechange = (e) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const selectedFile = e.target.files[0];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setImage(selectedFile);
    } else {
      setImage(null);
      toast.error("Please select a valid image file jpg and png");
      return;
    }
  };

  const [uploadfilename, setuploadfilename] = useState("");
  const uploadfile = async (e) => {
    e.preventDefault();
    // console.log(uploadfilename)
    setLoading(true);
    if (uploadfilename === "") {
      toast.error("Please select a file name");
      setLoading(false);
      return;
    }
    if (!file) {
      toast.error("Please select an file");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            Importantdocs: {
              ...prev.Importantdocs,
              [uploadfilename]: response.data.Url,
            },
          };
        });
        setNewData((prev) => {
          return {
            ...prev,
            Importantdocs: {
              ...prev.Importantdocs,
              [uploadfilename]: response.data.Url,
            },
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const createslots = async () => {
    if (
      createslotsdata.clinicId === "" ||
      createslotsdata.date === "" ||
      createslotsdata.doctorId === ""
    ) {
      toast.error("Please select clinic and date");
      return;
    }
    const selectedClinic = clinics.find(
      (clinic) => clinic._id === createslotsdata.clinicId
    );

    const confirmation = window.confirm(
      `Are you sure you want to create slots? This action cannot be undone. \n\nDate: ${
        createslotsdata.date
      } \nClinic: ${selectedClinic?.clinicname || "Unknown Clinic"}`
    );
    if (!confirmation) {
      return; // User clicked cancel, exit the function
    }
    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/admin/createslots`,
        createslotsdata,
        token
      );
      // console.log(response);
      if (response.statusCode === 200) {
        toast.success("Slots Created Successfully");
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addUnavailability = async () => {
    if (
      Unavailabilitydata.clinicIds.length === 0 ||
      Unavailabilitydata.startdate === "" ||
      Unavailabilitydata.enddate === ""
    ) {
      toast.error("Please select clinic and date");
      return;
    }

    const confirmation = window.confirm(
      `Are you sure you want to add unavailability for the selected clinic(s) from ${Unavailabilitydata.startdate} to ${Unavailabilitydata.enddate}`
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/admin/addunavailability`,
        Unavailabilitydata,
        token
      );
      console.log(response);
      if (response.statusCode === 200) {
        toast.success("Unavailability Added Successfully");
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addMetatag = () => {
    setUserData({
      ...userData,
      metatags: [...userData.metatags, ""],
    });
  };

  const metatagschange = (e, index) => {
    const value = e.target.value;
    const temp = [...userData.metatags];
    temp[index] = value;
    setUserData({
      ...userData,
      metatags: temp,
    });
    setNewData({
      ...newData, metatags: temp
    });
  };

  const removeMetatag = (index) => {
    const temp = [...userData.metatags];
    temp.splice(index, 1);
    setUserData({
      ...userData,
      metatags: temp,
    });
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold my-3">Edit Doctor Profile</h1>

      <div className="p-3">
        <h2 className="text-xl font-semibold">Basic Profile</h2>
        <div className="flex gap-4 justify-between items-center my-3">
          <div className="flex items-center justify-center flex-col">
            <img
              src={userData.profilepic}
              className="rounded w-[300px]"
              alt=""
            />

            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="file_input"
            >
              Upload file
            </label>
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="file_input"
              type="file"
              accept="image/jpeg, image/png , image/jpg"
              onChange={imagechange}
            />
            {/* <button 
              className="bg-blueshade w-1/2 text-white px-2 py-1 mx-3 my-2 rounded"
              onClick={() => setModal(true)}
            >
              Edit Image
            </button>
            {
              modal && <EditImagepopup open={() => setModal(false)} />
            } */}
            {!loading ? (
              <button
                className="bg-blueshade w-1/2 text-white px-2 py-1 mx-3 my-2 rounded"
                onClick={upload}
              >
                Upload
              </button>
            ) : (
              <button
                disabled
                className="bg-blueshade w-1/2 text-white px-2 py-1 mx-3 my-2 rounded"
              >
                Uploading...
              </button>
            )}
          </div>
          <div className="w-1/2 flex flex-col flex-1">
            <div className="flex justify-between gap-4">
              <div className="w-1/2">
                <label className="block">Name</label>
                <input
                  type="text"
                  value={userData?.user?.name}
                  name="user.name"
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={onchagehandler}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Email</label>
                <input
                  type="text"
                  value={userData?.user?.email}
                  name="user.email"
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={onchagehandler}
                />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-1/2">
                <label className="block mt-5">Phone Number</label>
                <input
                  type="text"
                  value={userData?.user?.phonenumber}
                  name="user.phonenumber"
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={onchagehandler}
                />
              </div>
              <div className="w-1/2">
                <label className="block mt-5">Age</label>
                <input
                  type="text"
                  value={userData?.age}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  name="age"
                  onChange={onchagehandler}
                />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-1/3">
                <label className="block mt-5">Speciality</label>
                {/* <input
                  type="text"
                  value={userData?.speciality}
                  name="speciality"
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={onchagehandler}
                /> */}
                <Select
                  options={specialityoptions}
                  value={{
                    value: userData?.speciality,
                    label: userData?.speciality,
                  }}
                  className="w-full border border-gray-900 rounded-md"
                  onChange={(e) => {
                    setUserData({ ...userData, speciality: e.value });
                    setNewData({ ...newData, speciality: e.value });
                  }}
                />
              </div>
              <div className="w-1/3">
                <label className="block mt-5">Registration Number</label>
                <input
                  type="text"
                  value={userData?.regNo}
                  name="regNo"
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={onchagehandler}
                />
              </div>
              <div className="w-1/3">
                <label className="block mt-5">Gender</label>
                <select
                  value={userData?.gender}
                  name="gender"
                  onChange={onchagehandler}
                  className="w-full border border-gray-900 p-2 rounded-md"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="flex justify-start items-center mt-5 gap-2">
              <label className="block">Experience:</label>
              <input
                type="number"
                value={userData?.experience}
                name="experience"
                className="w-1/4 border border-gray-900 p-2 rounded-md"
                onChange={onchagehandler}
              />
              <label className="block font-bold">IsActive:</label>
              <ToggleButton
                inactiveLabel={""}
                activeLabel={""}
                value={userData.isActive}
                onToggle={(value) => {
                  setUserData({ ...userData, isActive: !value });
                  setNewData({ ...newData, isActive: !value });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Verification</h2>
          {userData.isVerified && <BadgeCheck size={24} color="blue" />}
        </div>
        <div></div>
        <div className="flex gap-4 justify-start items-center my-3">
          <div className="w-1/4 border-gray-900 border rounded-md ">
            <select
              name="uploadfilename"
              className="border-gray-900 p-2 rounded-md w-full"
              id=""
              onChange={(e) => {
                setuploadfilename(e.target.value);
              }}
            >
              <option value="">Select Document</option>
              <option value="Aadhar">Aadhar card</option>
              <option value="PAN">PAN Card</option>
              <option value="Registration">Registration Certificate</option>
            </select>
          </div>
          <div className="w-1/4">
            <input
              type="file"
              className="w-full border border-gray-900 p-1 rounded-md"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </div>
          <div className="w-1/4">
            {!loading ? (
              <button
                onClick={uploadfile}
                className="bg-blueshade w-1/2 text-white px-2 py-1 mx-3 my-2 rounded"
              >
                Upload
              </button>
            ) : (
              <button
                disabled
                className="bg-blueshade w-1/2 text-white px-2 py-1 mx-3 my-2 rounded"
              >
                Uploading...
              </button>
            )}
          </div>
        </div>
        <table className="w-[100%] border-collapse border border-gray-900">
          <thead>
            <tr>
              <th className="border border-gray-900">Documents</th>
              <th className="border border-gray-900">Urls</th>
            </tr>
          </thead>
          <tbody>
            {userData.Importantdocs &&
              Object.keys(userData.Importantdocs).map((key, index) => (
                <tr key={index}>
                  <td className="border border-gray-900 p-2 text-center">
                    {key}
                  </td>
                  <td className="border border-gray-900 p-2 text-center">
                    {userData.Importantdocs[key]}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">About</h2>
        </div>
        <div className="flex gap-4 justify-start items-center my-3">
          <div className="w-full">
            <textarea
              value={userData?.about}
              rows={6}
              name="about"
              className="w-full border border-gray-900 p-2 rounded-md"
              onChange={onchagehandler}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Education</h2>
          <button onClick={addeducation}>
            <Plus />
          </button>
        </div>
        <div>
          {Education.map((item, index) => (
            <div
              key={index}
              className="flex gap-4 justify-center items-center my-3"
            >
              <div className="w-1/2">
                <label className="block">Start Date</label>
                <input
                  type="date"
                  value={
                    item.startdate
                      ? new Date(item.startdate).toISOString().split("T")[0]
                      : ""
                  }
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...Education];
                    temp[index].startdate = e.target.value;
                    setEducation(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">End Date</label>
                <input
                  type="date"
                  value={
                    item.enddate
                      ? new Date(item.enddate).toISOString().split("T")[0]
                      : ""
                  }
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...Education];
                    temp[index].enddate = e.target.value;
                    setEducation(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Course</label>
                <input
                  type="text"
                  value={item.course}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...Education];
                    temp[index].course = e.target.value;
                    setEducation(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">College</label>
                <input
                  type="text"
                  value={item.college}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...Education];
                    temp[index].college = e.target.value;
                    setEducation(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Speciality</label>
                <input
                  type="text"
                  value={item.speciality}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...Education];
                    temp[index].speciality = e.target.value;
                    setEducation(temp);
                  }}
                />
              </div>
              <button onClick={() => removeEducation(index)}>
                <CircleMinus />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Work Experience</h2>
          <button onClick={addWorkExperience}>
            <Plus />
          </button>
        </div>
        <div>
          {WorkExperience.map((item, index) => (
            <div
              key={index}
              className="flex gap-4 justify-center items-center my-3"
            >
              <div className="w-1/2">
                <label className="block">Start Date</label>
                <input
                  type="date"
                  value={
                    item.startdate
                      ? new Date(item.startdate).toISOString().split("T")[0]
                      : ""
                  }
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].startdate = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">End Date</label>
                <input
                  type="date"
                  value={
                    item.enddate
                      ? new Date(item.enddate).toISOString().split("T")[0]
                      : ""
                  }
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].enddate = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Place</label>
                <input
                  type="text"
                  value={item.Place}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].Place = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Speciality</label>
                <input
                  type="text"
                  value={item.speciality}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].speciality = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Role</label>
                <input
                  type="text"
                  value={item.role}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].role = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="block">Years of Experience</label>
                <input
                  type="number"
                  value={item.yearsofexperience}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...WorkExperience];
                    temp[index].yearsofexperience = e.target.value;
                    setWorkExperience(temp);
                  }}
                />
              </div>
              <button onClick={() => removeWorkExperience(index)}>
                <CircleMinus />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="p-3 pb-0">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Clinic(WORK)</h2>
          <button onClick={addClinic}>
            <Plus />
          </button>
        </div>
        {workingClinics.map((item, index) => (
          <div
            key={index}
            className="flex gap-4 justify-center items-center my-3 flex-col"
          >
            <div className="flex gap-4 justify-center items-center w-full">
              <div className="w-1/2">
                <label className="block">Clinic</label>
                <select
                  name="clinic"
                  value={item.clinic._id}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...workingClinics];
                    temp[index].clinic = e.target.value;
                    setWorkingClinics(temp);
                  }}
                >
                  <option value="">Select Clinic</option>
                  {clinics.map((clinic, index) => (
                    <option key={index} value={clinic._id}>
                      {clinic.clinicname}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="block">Slot Duration</label>
                <select
                  name="slotduration"
                  value={item.slotduration}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...workingClinics];
                    temp[index].slotduration = e.target.value;
                    setWorkingClinics(temp);
                  }}
                >
                  <option value={10}>10 min</option>
                  <option value={15}>15 min</option>
                  <option value={30}>30 min</option>
                </select>
              </div>
              <div className="w-1/2">
                <label className="block">Consultation Fee</label>
                <input
                  type="number"
                  value={item.Consultationfee}
                  className="w-full border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const temp = [...workingClinics];
                    temp[index].Consultationfee = e.target.value;
                    setWorkingClinics(temp);
                  }}
                />
              </div>
              <button onClick={() => removeClinic(index)}>
                <CircleMinus />
              </button>
            </div>
            <div className="w-full">
              <label className="block">Working Days</label>
              {Object.keys(item.workingdays).map((day, idx) => (
                <div
                  key={idx}
                  className="flex gap-4 my-2 justify-center items-center"
                >
                  <div className="w-[200px] border border-gray-900 rounded-md p-2 ">
                    <p>{day}</p>
                  </div>
                  <div className="w-full border border-gray-900 rounded-md p-2 flex justify-between  ">
                    <div className="flex gap-2">
                      {item.workingdays[day].map((slot, k) => (
                        <div
                          key={k}
                          className="flex gap-4 justify-center items-center"
                        >
                          <input
                            type="time"
                            value={slot.startTime}
                            className="border border-gray-900 p-1 rounded-md"
                            onChange={(e) => {
                              const temp = [...workingClinics];
                              temp[index].workingdays[day][index].startTime =
                                e.target.value;
                              setWorkingClinics(temp);
                            }}
                          />
                          <input
                            type="time"
                            value={slot.endTime}
                            className="border border-gray-900 p-1 rounded-md"
                            onChange={(e) => {
                              const temp = [...workingClinics];
                              temp[index].workingdays[day][index].endTime =
                                e.target.value;
                              setWorkingClinics(temp);
                            }}
                          />
                          <button onClick={removeSlot(day, index, k)}>
                            <CircleMinus />
                          </button>
                        </div>
                      ))}
                    </div>
                    <button onClick={addslot(day, index)}>
                      <Plus />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Add Services</h2>
        </div>
        {/* <div className="border p-2 border-gray-900 flex w-full rounded-md gap-3 my-2 flex-wrap">
          {userData.Procedures &&
            userData.Procedures.map((procedure, index) => (
              <div key={index} className="flex justify-center items-center">
                <p
                  className="border border-gray-900   p-2 rounded-md"
                >
                  {procedure}
                </p>
                <Minus
                  className=" cursor-pointer"
                  onClick={() =>{
                    const temp = [...userData.Procedures];
                    temp.splice(index, 1);
                    setUserData({ ...userData, Procedures: temp });
                  }}
                  size={24}
                />
              </div>
            ))}
        </div> */}
        <div className="flex gap-2 items-end">
          <div className="w-1/3">
            <select
              className="border border-black rounded-md p-2 w-full "
              onChange={(e) => {
                services.filter((service) => {
                  if (service._id === e.target.value) {
                    setselectedServices(service);
                  }
                });
              }}
            >
              <option value="">Select Service</option>
              {services.map((service, index) => (
                <option key={index} value={service._id}>
                  {service.Speciality}
                </option>
              ))}
            </select>
          </div>
          <Select
            options={selectedServices.Procedures.map((service) => {
              return {
                value: service,
                label: service,
              };
            })}
            isMulti
            className="w-1/3 border border-gray-900 rounded-md"
            value={userData.Procedures.map((procedure) => {
              return {
                value: procedure,
                label: procedure,
              };
            })}
            onChange={(e) => {
              setUserData({
                ...userData,
                Procedures: e.map((procedure) => procedure.value),
              });
              setNewData({
                ...newData,
                Procedures: e.map((procedure) => procedure.value),
              });
            }}
          />
        </div>
      </div>

      <div className="mt-5 w-full p-3">
        <div className="w-full flex flex-col ">
          <div className="flex gap-2">
            <h1 className="text-xl font-semibold">Add Metatags </h1>
            <Plus
              onClick={addMetatag}
              className=" cursor-pointer"
              size={24}
            />
          </div>
        </div>
        <div className="border p-2 border-gray-900 flex w-full rounded-md gap-3 my-2 flex-wrap">
          {userData.metatags &&
            userData.metatags.map((procedure, index) => (
              <div key={index} className="flex justify-center items-center">
                <input
                  type="text"
                  id="Procedure"
                  name="Procedure"
                  className="border border-gray-900   p-2 rounded-md"
                  required
                  onChange={(e) => metatagschange(e, index)}
                  value={procedure}
                />
                <Minus
                  className=" cursor-pointer"
                  onClick={() => removeMetatag(index)}
                  size={24}
                />
              </div>
            ))}
        </div>
      </div>
      {/* <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Create Slots</h2>
        </div>
        <div className="flex gap-2 items-end">
          <div className="w-1/3">
            <label className="block">Clinic</label>
            <select
              name="clinic"
              className="w-full border border-gray-900 p-2 rounded-md"
              onChange={(e) => {
                setcreateslotsdata({
                  ...createslotsdata,
                  clinicId: e.target.value,
                });
              }}
            >
              <option value="">Select Clinic</option>
              {userData.workingClinics.map((clinics, index) => (
                <option key={index} value={clinics.clinic._id}>
                  {clinics.clinic.clinicname}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/3">
            <label className="block">Select Date</label>
            <input
              type="date"
              className="w-full border border-gray-900 p-2 rounded-md"
              onChange={(e) => {
                setcreateslotsdata({
                  ...createslotsdata,
                  date: e.target.value,
                });
              }}
            />
          </div>

          <div className="w-1/3">
            <button onClick={createslots} className="bg-blueshade text-white px-2 py-1 mx-3  rounded">
              Create Slots
            </button>
          </div>
        </div>
      </div> */}

      <div className="p-3">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold">Unavailability Status</h2>
        </div>
        <div className="flex flex-row gap-2 items-end">
          <div className="w-1/3">
            <label className="block">Clinic</label>
            <Select
              options={clinicoptions}
              isMulti
              className="w-full border border-gray-900 rounded-md"
              value={clinics
                .filter((clinic) =>
                  Unavailabilitydata.clinicIds.includes(clinic._id)
                )
                .map((clinic) => ({
                  value: clinic._id,
                  label: clinic.clinicname,
                }))}
              onChange={(e) => {
                setUnavailabilitydata({
                  ...Unavailabilitydata,
                  clinicIds: e.map((clinic) => clinic.value),
                });
              }}
            />
          </div>
          <div className="w-[20%]">
            <label className="block">From</label>
            <input
              type="date"
              className="w-full border border-gray-900 p-2 rounded-md"
              onChange={(e) =>
                setUnavailabilitydata({
                  ...Unavailabilitydata,
                  startdate: e.target.value,
                })
              }
            />
          </div>

          <div className="w-[20%]">
            <label className="block">To</label>
            <input
              type="date"
              className="w-full border border-gray-900 p-2 rounded-md"
              onChange={(e) =>
                setUnavailabilitydata({
                  ...Unavailabilitydata,
                  enddate: e.target.value,
                })
              }
            />
          </div>
          <div>
            <button
              className="bg-blueshade text-white px-2 py-1 mx-3 my-2 rounded"
              onClick={addUnavailability}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <button
        onClick={submithandler}
        className="bg-blueshade text-white px-2 py-1 mx-3 my-2 rounded"
      >
        Update
      </button>
    </div>
  );
};

export default EditDoctor;
