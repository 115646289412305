import React, { useState,useEffect } from "react";
import Footer from "../Components/HomePageComponents/Footer";
import Navbar from "../Components/HomePageComponents/Navbar";
import Treatmentcard from "../Components/Treatment Components/Treatmentcard";
import Callbackpopup from "../Components/Treatment Components/CallbackPopup";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";

const Treatments = () => {
  const [treatments, settreatments] = useState([]);
  const [modalopen, setModalopen] = useState(false);
  const host = process.env.REACT_APP_Backend_URL

  const toggleModal = () => {
    setModalopen(!modalopen);
  };

  const gettreatments = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getalltreatments`
      );
      console.log(response);
        
      if (response) {
        settreatments(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    gettreatments();
  }, []);

  return (
    <div className=" min-h-[100vh]  flex flex-col">
      <Navbar />
      <div className="bg-whiteshade py-20 p-5 lg:px-20   ">
        <div className=" flex justify-between items-center max-w-6xl w-full mx-auto">
          <div className="md:w-[60%]">
            <div className="flex items-start flex-col justify-center ">
              <h1 className="xl:text-5xl text-3xl text-blueshade font-semibold">
                Quality Health Care At Your Convenience!!
              </h1>
              <div className="h-[3px] bg-whiteshade w-24 my-2"></div>
            </div>
            <p className="text-black text-[18px] leading-7">
              We At Hale Clinics With A Dedicated Pannel Of the Most Experienced
              Doctors Covering 8 Core Specialities Provide You The Most Trusted
              And Affordable Care You Need At Your Convenience
            </p>
            <div className="mt-2">
              <button
                className="font-bold text-white bg-blueshade rounded-[10px] rounded-br-none p-3 "
                onClick={toggleModal}
              >
                Book a Callback
              </button>
            </div>
          </div>
          <img
            src="/assests/About.png"
            className="lg:w-[32%] w-[38%] object-cover hidden md:block"
          />
        </div>
      </div>
      <div>{modalopen && <Callbackpopup open={toggleModal} />}</div>
      <div className="lg:px-20 p-3 w-[100%]  py-10 ">
        <div className="flex items-start flex-col max-w-6xl w-full mx-auto justify-center">
          <h1 className="text-3xl font-bold">Our Treatments</h1>
          <div className="h-[3px] bg-orangeshade w-36 my-2"></div>
        </div>
        <div className="grid grid-cols-1 max-w-6xl justify-stretch w-full mx-auto md:grid-cols-2 xl:grid-cols-2 gap-4 my-4">
          {treatments.map((treatment) => (
            <Treatmentcard key={treatment._id} treatment={treatment} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Treatments;
