import React from "react";
import SearchBar from "../Components/DoctorPageComponents/SearchBar";
import Navbar from "../Components/HomePageComponents/Navbar";
import Footer from "../Components/HomePageComponents/Footer";
import { Book } from "lucide-react";
import BookDoctorCard from "../Components/DoctorPageComponents/BookDoctorCard";
import { useContext, useRef, useState, useEffect } from "react";
import Authcontext from "../Context/Auth/AuthContext";
import { makeUnauthenticatedGETRequest } from "../Services/Servicehelper";
import Specialitybar from "../Components/DoctorPageComponents/Specialitybar";
import Loader from "../Components/Loader";

const Doctors = () => {
  const { searchvalue, setSearchvalue, location, setLocation } =
    useContext(Authcontext);
  const { BookingData, setBookingData } = useContext(Authcontext);
  const [doctors, setDoctors] = useState([]);
  const [clinics, setclinics] = useState([])
  const [doctorslocation, setDoctorslocation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showsuggestions, setShowsuggestions] = useState(false);
  const [recommendedDoctors, setRecommendedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const host = process.env.REACT_APP_Backend_URL;
  const searchBarRef = useRef(null);
  const suggestionsRef = useRef(null);
  const fetchSuggestions = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/suggestions?Searchterm=${searchvalue}`
      );
      // console.log(response);
      if (response.data) {
        setSuggestions(response.data);
      }
      // console.log(suggestions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocus = () => {
    setShowsuggestions(true);
  };

  const fetchDoctors = async () => {
    // console.log(searchvalue, location);
    setLoading(true);
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/filterdoctors?searchterm=${searchvalue}&location=${location}`
      );
      console.log(response);
      if (response) {
        setDoctors(response.doctors);
        setRecommendedDoctors(response.recommendations);
        setDoctorslocation(
          clinics.find((clinic) => clinic._id === location)?.clinicname
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, [searchvalue]);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const handleonclick = (e) => {
    // console.log(e.target);
    setSearchvalue(e.target.innerText);
    setShowsuggestions(false);
  };
  useEffect(() => {
    // Function to handle clicks outside of the search bar and suggestions
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowsuggestions(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const myDivRef = useRef(null);

  const scrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getclinics = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getclinics`
      );
      // console.log(response);
        
      if (response) {
        setclinics(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    scrollToDiv();
    getclinics();
    setBookingData({ 
      doctor: "",
      date: "",
      slot: "",
      PatientName: "",
      email: "",
      phonenumber: "",
      paymentcost:"",
      saved:false
     });
  }, []);

  return (
    <div className="min-h-[100vh] flex flex-col">
      <Navbar />
      <section className="bg-">
        <img
          src="/assests/Banner.png"
          alt="Banner"
          className="object-cover w-[100%] "
        />
      </section>
      <div ref={myDivRef} className="max-w-6xl w-full mx-auto">
        <div className="bg-[#F6F8FF] border-[#1651C6] m-6 xl:mx-0  rounded-xl rounded-br-none border-[1px] px-6 py-4 ">
          <h1 className="font-bold text-[20px] ">
            Search from Best Doctors in hyderabad
          </h1>
          <div className="flex justify-between sm:flex-row sm:justify-between items-start flex-col    w-[100%] gap-2 my-3">
            <div
              className="w-[100%] sm:w-[50%] md:w-[60%] custom:w-[65%] relative"
              ref={searchBarRef}
            >
              <input
                type="text"
                name=""
                value={searchvalue}
                onChange={(e) => setSearchvalue(e.target.value)}
                onFocus={handleFocus}
                placeholder="Doctor Name / Speciality"
                className="bg-white p-2 w-[100%] rounded-lg  placeholder:font-[#9A9A9A] border-[1px] border-[#9A9A9A]"
              />
              {showsuggestions && (
                <div
                  className="suggestions absolute w-full bg-white border border-[#a8bce6] rounded-lg rounded-br-none z-10 flex flex-col gap-2 max-h-48 p-2 top-11 overflow-scroll"
                  ref={suggestionsRef}
                >
                  <p className="text-[#aeaeae]">Specialities</p>
                  {suggestions.specialities &&
                    suggestions.specialities.map((speciality, index) => (
                      <div
                        className="suggestion flex items-center gap-2 rounded-lg cursor-pointer"
                        key={index}
                        onClick={handleonclick}
                      >
                        <img
                          src="./assests/search.svg"
                          className="w-4 h-4"
                          alt=""
                        />
                        <p className="text-sm text-black ">{speciality}</p>
                      </div>
                    ))}
                  <p className="text-[#aeaeae]">Doctors</p>
                  {/* <hr /> */}
                  {suggestions.doctors &&
                    suggestions.doctors.map((doctor, index) => (
                      <div
                        className="suggestion flex items-center gap-2 rounded-lg cursor-pointer"
                        key={index}
                        onClick={handleonclick}
                      >
                        <img
                          className="w-4 h-4"
                          src="./assests/doctor.png"
                          alt=""
                        />
                        <p className="text-sm text-black">{doctor.name}</p>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <select
              name="Location"
              className="bg-white p-2 w-[100%]  sm:w-[20%] rounded-lg placeholder:font-semibold placeholder:font-[#9A9A9A] border-[1px] border-[#9A9A9A]"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">Select Location</option>
              {clinics.map((clinic, index) => (
              <option key={index} value={clinic._id}>{clinic.clinicname}</option>
            ))}
            </select>
            <button
              onClick={fetchDoctors}
              className="bg-indigo w-[150px] p-2 rounded-xl rounded-br-none text-white"
            >
              Search
            </button>
          </div>
        </div>
        {
        /* { !searchvalue && !location && */
           (
            <div className="flex xl:mx-0 m-4 flex-col">
            <Specialitybar fetchDoctors={fetchDoctors} />
            </div>
          )
        }
        { 
        // (searchvalue || location) &&
         <div className="flex xl:mx-0 m-4 flex-col">
          <h1 className="text-2xl mt-[10px] mb-[20px]">
            Available Doctors {doctorslocation ? `at ${doctorslocation}` : ""}
          </h1>
          {loading && <div className="flex justify-center items-center">
            <Loader />
          </div>}
          {!loading && doctors.map((doctor, index) => (
            doctor.isActive && <BookDoctorCard bookingbtn={true} key={index} doctor={doctor} />
          ))}
          <h1 className="text-2xl mt-[10px] mb-[20px] text-center font-semibold">
            There are no more to show
          </h1>
        </div>}
        { 
        (recommendedDoctors.length!==0) &&
         <div className="flex xl:mx-0 m-4 flex-col">
          <h1 className="text-2xl mt-[10px] mb-[20px]">
            Doctors NearBy
          </h1>
          {recommendedDoctors.map((doctor, index) => (
            <BookDoctorCard bookingbtn={true} key={index} doctor={doctor} />
          ))}
          {/* <h1 className="text-2xl mt-[10px] mb-[20px] text-center font-semibold">
            There are no more to show
          </h1> */}
        </div>}
      </div>
      <Footer />
    </div>
  );
};

export default Doctors;
