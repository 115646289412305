import React from "react";
import { X } from "lucide-react";
import { useRef ,useState} from "react";
import { sendquery } from "../../utils/Forms";
import { toast } from "react-toastify";

const PopupModal = ({ open }) => {
    const modelref = useRef();
    const closeModal = (e) => {
        if (modelref.current === e.target) {
            open();
        }
    };

    const [querydetails, setQueryDetails] = useState({
      source: "/Contact",
      subject: {
        name: "",
        email: "",
        message: "",
      },
    });
  
    const handleChange = (e) => {
      setQueryDetails({
        ...querydetails,
        subject: {
          ...querydetails.subject,
          [e.target.name]: e.target.value,
        },
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        !querydetails.subject.name ||
        !querydetails.subject.email ||
        !querydetails.subject.message
      ) {
        toast.error("Please fill all the fields");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(querydetails.subject.email)) {
        toast.error("Please enter a valid email address");
        return;
      }
      // console.log(querydetails);
      const response = await sendquery(querydetails);
      console.log(response);
      if (response.status === "success") {
        toast.success(response.data.message);
        setQueryDetails({
          source: "/Fordoctors",
          subject: {
            name: "",
            email: "",
            message: "",
          },
        });
        open();
      } else {
        toast.error(response.message);
      }
    };
  return (
    <div ref={modelref} onClick={closeModal} className="fixed inset-0 flex items-center justify-center flex-col m-auto bg-black bg-opacity-30 backdrop-blur-sm z-50 ">
      <div className="flex w-[300px] md:w-[37%] justify-between items-center  rounded-xl rounded-b-none p-4 bg-blueshade">
        <p className="text-white font-semibold">Join Our Co-Working Space</p>
        <button onClick={open}>
          <X size={20} color="white" />
        </button>
      </div>
      <div className="rounded-bl-xl bg-whiteshade w-[300px] md:w-[37%] justify-evenly flex flex-col g:px-20 p-4 py-5 lg:py-12">
        <div className="w-[100%]">
          <h1 className="text-lg font-semibold">Full Name</h1>
          <input
            type="text"
            className="w-[100%] border-[1px] border-blueshade p-2 my-2"
            name="name"
            value={querydetails.subject.name}
            onChange={handleChange}
          />
        </div>
        <div className="w-[100%]">
          <h1 className="text-lg font-semibold">Email</h1>
          <input
            type="email"
            className="w-[100%] border-[1px] border-blueshade p-2 my-2"
            name="email"
            value={querydetails.subject.email}
            onChange={handleChange}
          />
        </div>
        <div className="w-[100%]">
          <h1 className="text-lg font-semibold">Message</h1>
          <textarea className="w-[100%] border-[1px] h-[120px] border-blueshade p-2 my-2" 
          name="message"
          value={querydetails.subject.message}
          onChange={handleChange}
          />
        </div>
        <button onClick={handleSubmit} className="bg-indigo my-1 w-[150px]  text-white px-4 py-1 rounded-lg rounded-br-none">
          Submit
        </button>
      </div>
    </div>
  );
};

export default PopupModal;
