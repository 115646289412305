import { react } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Copy } from "lucide-react";

import {
  makeAuthenticatedPOSTRequest,
  makeUnauthenticatedPOSTRequestmultipart,
} from "../../Services/Servicehelper";
import { toast } from "react-toastify";

const CreateBlog = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const [data, setData] = useState({
    Title: "",
    Description: "",
    Image: "",
    Content: "",
    Active: false,
    Date: new Date(),
  });

  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const imagechange = (e) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const selectedFile = e.target.files[0];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setImage(selectedFile);
    } else {
      setImage(null);
      toast.error("Please select a valid image file");
      return;
    }
  };

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      toast.error("Please select an image");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setData((prev) => {
          return {
            ...prev,
            Image: response.data.Url,
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

    const submithandler = async (e) => {
    e.preventDefault();
    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/admin/addblog`,
        data,
        token
      );
      // console.log(response);
      if (response.statusCode === 200) {
        toast.success("Blog Added Successfully");
        window.location.href = "/admin/blogs";
      }
    } catch (error) {
      console.log(error);
    }
    };

    const codeSnippets = {
        heading: `<p class="xl:text-2xl my-2 text-xl font-bold">What is gynacology</p>`,
        paragraph: `<p class="xl:text-lg lg:text-md text-sm">
     Praesent rutrum commodo sapien, sed porta lorem.
  </p>`,
        horizontalLine: `<hr class="border-t-2 my-3 border-lightshade w-[100%]" />`,
        bulletPoints: `<ul role="list" class="marker:text-blueshade list-disc pl-5 space-y-3 text-slate-400">
    <li class="xl:text-lg lg:text-md text-sm">Lorem ipsum </li>
    <li class="xl:text-lg lg:text-md text-sm">Praesent rutrum commodo </li>
  </ul>`,
      };
      const handlecopy = (key) => {
        // e.preventDefault();
        navigator.clipboard.writeText(codeSnippets[key]);
        toast.info("Copied to clipboard");
      };


  return (
    <form onSubmit={submithandler}>
      <h1 className="text-2xl font-semibold">Add Blog</h1>
      <div className="mt-5 grid grid-cols-2 gap-2">
        <div className="w-full flex flex-col ">
          <label htmlFor="Treatmentname">Blog Title</label>
          <input
            type="text"
            id="BlogTitle"
            name="Title"
            placeholder="Blog Title"
            className="border border-gray-900 p-2 rounded-md"
            required
            onChange={InputChange}
            value={data.Title}
          />
        </div>
        
      </div>
      <div className="mt-5 flex">
        <div className="w-1/2">
          <h2 className="text-xl ">Image Upload</h2>
          {data.Image !== "" && (
            <img src={data.Image} alt="logo" className="w-20 h-20" />
          )}
          <div className="flex gap-2 mt-2">
            <input
              type="file"
              className="border border-gray-900 p-2 rounded-md"
              accept="image/png, image/jpeg, image/jpg"
              onChange={imagechange}
              required
            />
            {!loading ? (
              <button
                onClick={upload}
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Upload
              </button>
            ) : (
              <button
                disabled
                className="bg-[blue] text-white px-3 py-1 rounded-md"
              >
                Uploading...
              </button>
            )}
          </div>
        </div>
        <div className="w-1/2">
            <label htmlFor="Active">Active</label>
            <ToggleButton
                value={data.Active}
                onToggle={(value) => {
                setData((prev) => {
                    return {
                    ...prev,
                    Active: !value,
                    };
                });
                }}
            />
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="Description">Description</label>
        <textarea
          id="Description"
          name="Description"
          placeholder="Description"
          className="border border-gray-900 p-2 rounded-md mt-2 w-full"
          required
          cols="30"
          rows="4"
          onChange={InputChange}
          value={data.Description}
        />
      </div>
      <div className="mt-5">
        <h1 className="text-xl font-semibold mt-5">HTML</h1>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">
            <p className="text-lg font-semibold">Heading</p>
            <Copy
              size={20}
              className="cursor-pointer"
              onClick={() => handlecopy("heading")}
            />
          </div>
          <div className="relative overflow-x-auto">
            <SyntaxHighlighter
              language="html"
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whitespace="pre-wrap"
            >
              {codeSnippets["heading"]}
            </SyntaxHighlighter>
          </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">
            <p className="text-lg font-semibold">Paragraph</p>
            <Copy
              size={20}
              className="cursor-pointer"
              onClick={() => handlecopy("paragraph")}
            />
          </div>
          <div className="relative overflow-x-auto">
            <SyntaxHighlighter
              language="html"
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whitespace="pre-wrap"
            >
              {codeSnippets["paragraph"]}
            </SyntaxHighlighter>
          </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">
            <p className="text-lg font-semibold">Horizontal Line</p>
            <Copy
              size={20}
              className="cursor-pointer"
              onClick={() => handlecopy("horizontalLine")}
            />
          </div>
          <div className="relative overflow-x-auto">
            <SyntaxHighlighter
              language="html"
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whitespace="pre-wrap"
            >
              {codeSnippets["horizontalLine"]}
            </SyntaxHighlighter>
          </div>
        </div>
        <div className="my-2 ">
          <div className="flex gap-2 items-center">
            <p className="text-lg font-semibold">Bullet Points</p>
            <Copy
              size={20}
              className="cursor-pointer"
              onClick={() => handlecopy("bulletPoints")}
            />
          </div>
          <div className="relative overflow-x-auto">
            <SyntaxHighlighter
              language="html"
              style={oneDark}
              wrapLines={true}
              showLineNumbers={true}
              whitespace="pre-wrap"
            >
              {codeSnippets["bulletPoints"]}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <label className="font-semibold" htmlFor="Content">
          Content (Write in HTML)
        </label>
        <textarea
          id="Content"
          name="Content"
          placeholder="Content"
          className="border border-gray-900 p-2 rounded-md mt-2 w-full"
          required
          cols="30"
          rows="10"
          onChange={InputChange}
          value={data.Content}
        />
      </div>
      <div className="flex mt-5">
        <button
          type="submit"
          className="bg-[blue] text-white px-3 py-1 rounded-md"
        >
          Add Treatment
        </button>
      </div>
    </form>
  );
};

export default CreateBlog;
