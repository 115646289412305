import React from "react";
import { useState, useEffect } from "react";
import { makeAuthenticatedGETRequest } from "../../Services/Servicehelper";
import { Link } from "react-router-dom";
import { makeAuthenticatedDELETERequest } from "../../Services/Servicehelper";
import { toast } from "react-toastify";


const Services = () => {
    const [services, setServices] = useState([]);
    const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");
    const fetchServices = async () => {
        try {
        const response = await makeAuthenticatedGETRequest(
            `${host}/api/admin/getallservices`,
            token
        );
        // console.log(response);
        setServices(response.data);
        } catch (error) {
        console.log(error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const deleteService = async (id,name) => {
        if(!window.confirm("Are you sure you want to delete this service?")) return;
        const enteredservice = window.prompt("Please enter the name of the service to delete");
        if (enteredservice!==name) {
            toast.info("Correct Service's name is required to proceed.");
            return;
        }
        try {
        const response = await makeAuthenticatedDELETERequest(
            `${host}/api/admin/deleteservice?serviceid=${id}`,
            token
        );
        // console.log(response);
        if(response.status==="success"){
            toast.success("Service Deleted Successfully");
            fetchServices();
        }
        } catch (error) {
        console.log(error);
        }
    };

  return (
    <div>
      <h1 className="text-2xl font-semibold">Services</h1>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <Link
            to="/admin/addservice"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Service
          </Link>
        </div>

        <div className="mt-5">
          <table className="w-[100%] border-collapse border border-gray-900">
            <thead>
              <tr>
                <th className="border border-gray-900">Speciality Name</th>
                <th className="border border-gray-900">Services</th>
                <th className="border border-gray-900">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                services.map((service) => (
                    <tr key={service._id}>
                        <td className="border border-gray-900 p-2 text-center" >
                        {service.Speciality}
                        </td>
                        <td className="border border-gray-900 p-2 text-center">
                        {service.Procedures.join(" , ")}
                        </td>
                        <td className="border border-gray-900 p-2 text-center">
                        <Link to={`/admin/editservice/${service._id}`} className="bg-[blue] text-white px-3 py-1 rounded-md ">Edit</Link>
                        <button onClick={()=>deleteService(service._id,service.Speciality)} className="bg-[red] text-white px-3 py-1 rounded-md m-2">Delete</button>
                        </td>
                    </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Services;
