import React from "react";
import "./Banner.css";
import { useState, useEffect,useRef, useContext } from "react";
import Authcontext from "../../Context/Auth/AuthContext";
import { makeUnauthenticatedGETRequest } from "../../Services/Servicehelper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Banner = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [showsuggestions, setShowsuggestions] = useState(false);
  const [clinics, setclinics] = useState([])
  const { searchvalue, setSearchvalue, location, setLocation } = useContext(Authcontext);
  const host = process.env.REACT_APP_Backend_URL;
  const searchBarRef = useRef(null);
  const suggestionsRef = useRef(null);
  const navigate = useNavigate();
  const fetchSuggestions = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/suggestions?Searchterm=${searchvalue}`
      );
      // console.log(response);
      if (response.data) {
        setSuggestions(response.data);
      }
      // console.log(suggestions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocus = () => {
    setShowsuggestions(true);
  };

  const getclinics = async () => {
    try {
      const response = await makeUnauthenticatedGETRequest(
        `${host}/api/auth/getclinics`
      );
      console.log(response);
        
      if (response) {
        setclinics(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuggestions();
    getclinics();
  }, [searchvalue]);

  const handleonclick = (e) => {
    console.log(e.target);
    setSearchvalue(e.target.innerText);
    setShowsuggestions(false);
  }
  useEffect(() => {
    // Function to handle clicks outside of the search bar and suggestions
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowsuggestions(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const search = () => {
    if (searchvalue === "" && location === "") {
      // alert("Please fill the search fields");
      return toast.error("Please fill the search fields");
    }
    navigate("/doctors")
  }




  return (
    <div className="SearchBar">
      <div className="flex items-start justify-start w-[90%]">
        <h1 className="text-sm  font-bold mb-2 text-black">I'm Seeking For</h1>
      </div>
      <div className="Search-content">
        <div className="searchfield">
          <p className="text-[#aeaeae]">Location/Hyderabad</p>
          <select name="Location" className="search-drop" value={location} onChange={
            (e) => setLocation(e.target.value)
          }>
            <option value="">Select Location</option>
            {clinics.map((clinic, index) => (
              <option key={index} value={clinic._id}>{clinic.clinicname}</option>
            ))}
          </select>
        </div>
        <div className="searchfield" ref={searchBarRef}>
          <p className="text-[#aeaeae]">Search Doctor by</p>
          <input
            className="search-input placeholder-[#275eca] "
            type="text"
            placeholder="Doctor Name/Specality"
            value={searchvalue}
            onFocus={handleFocus}
            onChange={(e) => setSearchvalue(e.target.value)}
          />
          {showsuggestions &&
            <div className="suggestions absolute w-full bg-white border border-[#a8bce6] rounded-lg rounded-br-none z-5 flex flex-col gap-2 max-h-48 p-2  top-16 overflow-scroll" ref={suggestionsRef}>
              <p className="text-[#aeaeae]">Specialities</p>
              {suggestions.specialities &&
                suggestions.specialities.map((speciality, index) => (
                  <div
                    className="suggestion flex items-center gap-2 rounded-lg cursor-pointer"
                    key={index}
                    onClick={handleonclick}
                  >
                    <img src="./assests/search.svg" className="w-4 h-4" alt="" />
                    <p className="text-sm text-black ">{speciality}</p>
                  </div>
                ))}
              <p className="text-[#aeaeae]">Doctors</p>
              {/* <hr /> */}
              {suggestions.doctors &&
                suggestions.doctors.map((doctor, index) => (
                  <div
                    className="suggestion flex items-center gap-2 rounded-lg cursor-pointer"
                    key={index}
                    onClick={handleonclick}
                  >
                    <img className="w-4 h-4" src="./assests/doctor.png" alt="" />
                    <p className="text-sm text-black">{doctor.name}</p>
                  </div>
                ))}
            </div>
          }
        </div>
        <button onClick={search} className="w-[95px] px-[20px] py-[10px] border-none rounded-[14px_14px_0_14px] bg-[#1651c6] text-white font-bold cursor-pointer text-center outline-none ml-[13px]">Search</button>
      </div>
    </div>
  );
};

export default Banner;
