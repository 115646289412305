import { useRef, useState } from "react";
import ReactCrop, { centerCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CROP_DIMENSION = 200; // Fixed crop size (200x200)

const ImageCropper = ({ closeModal, updateAvatar }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [error, setError] = useState("");
  const [originalFileName, setOriginalFileName] = useState("");
  const [croppedImageURL, setCroppedImageURL] = useState(null); // Store cropped image URL

  const [crop, setCrop] = useState({
    unit: "px", // Use pixel-based units
    width: CROP_DIMENSION,
    height: CROP_DIMENSION,
    x: 0,
    y: 0,
  });

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setOriginalFileName(file.name);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < CROP_DIMENSION || naturalHeight < CROP_DIMENSION) {
          setError(`Image must be at least ${CROP_DIMENSION} x ${CROP_DIMENSION} pixels.`);
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const centeredCrop = centerCrop(
      {
        unit: "px",
        width: CROP_DIMENSION,
        height: CROP_DIMENSION,
      },
      width,
      height
    );

    setCrop(centeredCrop);
  };

  const getCroppedImage = () => {
    if (!imgRef.current || !previewCanvasRef.current) return;

    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    const { x, y, width, height } = crop;

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(
      imgRef.current,
      x * scaleX,
      y * scaleY,
      width * scaleX,
      height * scaleY,
      0,
      0,
      width,
      height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const fileName = originalFileName.replace(/\.[^/.]+$/, "") + "_cropped.png";
            const file = new File([blob], fileName, { type: "image/png" });

            // Generate a URL for the preview
            const croppedURL = URL.createObjectURL(blob);
            setCroppedImageURL(croppedURL); // Set cropped image preview URL
            resolve(file);
          }
        },
        "image/png",
        1
      );
    });
  };

  const handleCrop = async () => {
    try {
      const croppedImageFile = await getCroppedImage();
      if (croppedImageFile) {
        // updateAvatar(croppedImageFile); // Pass the cropped file to the parent
        // closeModal(); // Close the modal
      } else {
        alert("Error cropping image.");
      }// Generate the cropped image and URL
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <div className="flex flex-col items-start justify-center">
      <p className="py-2 font-semibold">Choose profile photo</p>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 py-2 file:text-xs file:bg-gray-700 file:text-sky-300 hover:file:bg-gray-600"
      />
      {error && <p className="text-red-400 text-xs">{error}</p>}
      {imgSrc && (
        <div className="flex flex-col items-center">
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            keepSelection
            aspect={1}
            locked={true}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button
            className="text-white bg-black font-mono text-xs py-2 px-4 rounded-2xl mt-4 bg-sky-500 hover:bg-sky-600"
            onClick={handleCrop}
          >
            Crop Image
          </button>
        </div>
      )}
      <canvas
        ref={previewCanvasRef}
        className="mt-4"
        style={{
          display: "none",
        }}
      />
      {croppedImageURL && (
        <div className="mt-4">
          <p className="text-sm font-semibold">Cropped Image Preview:</p>
          <img
            src={croppedImageURL}
            alt="Cropped Preview"
            style={{ border: "1px solid black", objectFit: "contain", width: 200, height: 200 }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageCropper;
