import React, { useEffect } from "react";
import { useState,useRef } from "react";
import {
  makeAuthenticatedPOSTRequest,
  makeAuthenticatedGETRequest,
  makeAuthenticatedDELETERequest,
  makeAuthenticatedPATCHRequest
} from "../../Services/Servicehelper";
import { X } from "lucide-react";
import { toast } from "react-toastify";

const TreatmentCategory = () => {
  const [Categoryname, setCategoryname] = useState("");
  const [Categories, setCategories] = useState([]);
  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");
  const [updatepopup, setUpdatepopup] = useState(false);
  const [Updatedata, setUpdatedata] = useState({});
  const modelref = useRef();
  const addCategory = async () => {
    if (Categoryname === "" && Categoryname.length <= 4) {
      toast.error("Please enter the valid category name");
      return;
    }
    const data = {
      categoryname: Categoryname,
    };

    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/admin/addtreatmentcategory`,
        data,
        token
      );
      if (response.statusCode === 200) {
        toast.success("Category Added Successfully");
        setCategoryname("");
        getCategories();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await makeAuthenticatedGETRequest(
        `${host}/api/admin/getalltreatmentcategories`,
        token
      );
      if (response.statusCode === 200) {
        setCategories(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await makeAuthenticatedDELETERequest(
        `${host}/api/admin/deletetreatmentcategory?categoryId=${id}`,
        token
      );
      if (response.statusCode === 200) {
        toast.success("Category Deleted Successfully");
        getCategories();
      }
    } catch (error) {
      console.log(error);
    } 
  };

  const handleUpdate = async (id) => {
    try{
      const response = await makeAuthenticatedPATCHRequest(
        `${host}/api/admin/updatetreatmentcategory?categoryId=${id}`,
        Updatedata,
        token
      );
      if(response.statusCode === 200){
        toast.success("Category Updated Successfully");
        setUpdatedata({});
        setUpdatepopup(false);
        getCategories();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Treatment Category</h1>
      <div className="mt-5 w-full">
        <input
          type="text"
          id="Categoryname"
          name="Categoryname"
          placeholder="Treatment Category Name"
          className="border border-gray-900 p-2 rounded-md w-[40%]"
          value={Categoryname}
          onChange={(e) => {
            setCategoryname(e.target.value);
          }}/>
        <button
          className="bg-[blue] text-white px-3 py-2 rounded-md ml-2"
          onClick={addCategory}>
          Add Category
        </button>
      </div>
      <div>{updatepopup &&
      <div ref={modelref}  className="fixed inset-0 flex items-center justify-center flex-col m-auto bg-black bg-opacity-30 backdrop-blur-sm z-50">
        <div className="flex w-[300px] md:w-[37%] justify-between items-center  rounded-xl rounded-b-none p-4 bg-blueshade">
          <p className="text-white font-semibold">Update Treatment Category</p>
          <button onClick={()=>{setUpdatepopup(false)
          setUpdatedata({})
          }}>
            <X size={20} color="white" />
          </button>
        </div>
        <div className="rounded-bl-xl bg-whiteshade w-[300px] md:w-[37%] justify-evenly flex flex-col g:px-20 p-4 py-5 lg:py-12">
          <div className="w-[100%]">
            <h1 className="text-lg font-semibold">Category Name</h1>
            <input
              type="text"
              className="w-[100%] border-[1px] border-blueshade p-2 my-2"
              name="name"
              value={Updatedata.categoryname}
              onChange={
                (e) => {
                  setUpdatedata({
                    ...Updatedata,
                    categoryname: e.target.value,
                  });
                }
              }
            />
          </div>
          <div className="w-[100%]">
            <button className="bg-[blue] text-white px-3 py-2 rounded-md" onClick={()=>{
              handleUpdate(
                Updatedata._id
              )
            }}>
              Update
            </button>
          </div>
        </div>
        </div>  }
      </div>
      <div className="mt-5">
        <table className="w-[100%] border-collapse border border-gray-900">
          <thead>
            <tr>
              <th className="border border-gray-900">Category</th>
              <th className="border border-gray-900">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Categories.map((category) => (
              <tr key={category._id}>
                <td className="border border-gray-900 p-2 text-center">
                  {category.categoryname}
                </td>
                <td className="border border-gray-900 p-2 text-center">
                  <button className="bg-[green] text-white px-3 py-1 rounded-md"
                  onClick={
                    () => {
                      setUpdatepopup(true);
                      setUpdatedata(category);
                    }
                  }
                  >
                    Update
                  </button>
                  <button className="bg-[red] text-white px-3 py-1 rounded-md ml-2" 
                  onClick={
                    () => {
                      handleDelete(category._id);
                    }
                  }>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TreatmentCategory;
