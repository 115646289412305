import React from "react";
import Authcontext from "../../Context/Auth/AuthContext";
import { useContext } from "react";
import { makeUnauthenticatedPOSTRequest } from "../../Services/Servicehelper";
import { toast } from "react-toastify";

const PatientLogin = (props) => {
  const { setPhoneNumber, PhoneNumber } = useContext(Authcontext);
  const { setotpsent,setloading } = props;

  const sendOTP = async () => {
    const phoneRegex = /^\d{10}$/;
    console.log(PhoneNumber);
    if (!phoneRegex.test(PhoneNumber)) {
      toast.error("Please Enter Valid Phone Number");
      return;
    }
    setloading(true);
    try {
      const response = await makeUnauthenticatedPOSTRequest(
        `${process.env.REACT_APP_Backend_URL}/api/auth/sendotp`,
        { phonenumber: "91" + PhoneNumber }
      );
      console.log(response);
      if (response.status === "success") {
        setotpsent(true);
      }
      else{
        toast.error(response.message);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-center my-4 w-[100%]">
      <label className="font-bold" htmlFor="Phone">
        Enter Phone Number
      </label>
      <input
        type="text"
        maxLength={10}
        id="Phone"
        className="border-2 border-gray-400 w-[100%] p-1 mt-2"
        value={PhoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
        }}
        placeholder="Enter Phone Number"
      />
      <button
        className="w-[100%] bg-[#1651C6] p-2 mt-2 text-whiteshade font-bold"
        onClick={sendOTP}
      >
        Send OTP
      </button>
    </div>
  );
};

export default PatientLogin;
