import React from "react";
import { Plus, CircleMinus } from "lucide-react";
import { useState } from "react";
import Select from "react-select";
import {
  makeAuthenticatedPOSTRequest,
  makeUnauthenticatedPOSTRequestmultipart,
} from "../../Services/Servicehelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddClinic = () => {
  const navigate = useNavigate();
  const [staff, setStaff] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    clinicname: "",
    location: "",
    Clinicphone: "",
    Clinicemail: "",
    directions: "",
    services: [],
    timings: { starttime: "", endtime: "" },
    type: "MultiSpeciality",
    workingdays: [],
    description: "",
    imageurl: "",
  });


  const serviceoptions = [
    { value: "Pulmonologist", label: "Pulmonologist" },
    { value: "Gastro", label: "Gastro" },
    { value: "General physician", label: "General physician" },
    { value: "Dental", label: "Dental" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Cosmetologist", label: "Cosmetologist" },
    { value: "Gynaecologist", label: "Gynaecologist" },
    { value: "Ortho", label: "Ortho" },
    { value: "Cardio", label: "Cardio" },
    { value: "Physiotherapy", label: "Physiotherapy" },
    { value: "Endocrinologist", label: "Endocrinologist" },
    { value: "Audiologist", label: "Audiologist" },
    { value: "ENT", label: "ENT" },
    { value: "Neuro", label: "Neuro" },
    { value: "Urologist", label: "Urologist" },
    { value: "Nephrologist", label: "Nephrologist" },
    { value: "Psychiatrist", label: "Psychiatrist" },
    { value: "Dermatologist", label: "Dermatologist" },
    { value: "Gen Phy", label: "Gen Phy" },
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "General Surgeon", label: "General Surgeon" },
    { value: "Fertility", label: "Fertility" },
];
  const days = [
    { value: "Mon", label: "Mon" },
    { value: "Tue", label: "Tue" },
    { value: "Wed", label: "Wed" },
    { value: "Thu", label: "Thu" },
    { value: "Fri", label: "Fri" },
    { value: "Sat", label: "Sat" },
    { value: "Sun", label: "Sun" },
  ];

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "starttime" || name === "endtime") {
      setData((prev) => {
        return {
          ...prev,
          timings: {
            ...prev.timings,
            [name]: value,
          },
        };
      });
      return;
    }
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const addStaff = () => {
    setStaff([...staff, { name: "", role: "", phonenumber: "", email: "" }]);
  };
  const removeStaff = (index) => {
    setStaff((prev) => {
      const newStaff = [...prev];
      newStaff.splice(index, 1);
      return newStaff;
    });
  };

  const host = process.env.REACT_APP_Backend_URL;
  const token = localStorage.getItem("token");

  const imagechange = (e) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const selectedFile = e.target.files[0];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setImage(selectedFile);
    } else {
      setImage(null);
      toast.error("Please select a valid image file");
      return;
    }
  };

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      toast.error("Please select an image");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await makeUnauthenticatedPOSTRequestmultipart(
        `${host}/api/auth/uploadfile`,
        formData
      );
      // console.log(response);
      if (response.statusCode === 200) {
        setData((prev) => {
          return {
            ...prev,
            imageurl: response.data.Url,
          };
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submithandler = async (e) => {
    e.preventDefault();
    const newdata = {
      ...data,
      staff: staff,
    };
    // console.log(newdata);

    if (!token || token === "undefined") {
      toast.error("Please login to continue");
      return;
    }

    try {
      const response = await makeAuthenticatedPOSTRequest(
        `${host}/api/admin/createclinic`,
        newdata,
        token
      );
      console.log(response);
      if(response.status === "success"){
        toast.success("Clinic Added Successfully");
        navigate("/admin/clinics");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <form onSubmit={submithandler}>
      <h1 className="text-2xl font-semibold">Add Clinic</h1>
      <div className="mt-5">
        <div className="flex flex-col">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            onChange={onInputChange}
            value={data.clinicname}
            id="name"
            name="clinicname"
            placeholder="Name of Clinic"
            className="border border-gray-900 p-2 rounded-md"
            required
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="area">Location</label>
          <input
            type="text"
            value={data.location}
            onChange={onInputChange}
            id="location"
            name="location"
            placeholder="Location"
            className="border border-gray-900 p-2 rounded-md"
            required
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="phone">Clinic Phone Number</label>
          <input
            type="text"
            onChange={onInputChange}
            id="phone"
            value={data.Clinicphone}
            name="Clinicphone"
            placeholder="Phone Number"
            className="border border-gray-900 p-2 rounded-md"
            size={10}
            required
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="Email">Clinic Email</label>
          <input
            id="Email"
            onChange={onInputChange}
            value={data.Clinicemail}
            type="email"
            name="Clinicemail"
            placeholder="Email"
            className="border border-gray-900 p-2 rounded-md"
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="directions">Clinic Directions</label>
          <input
            type="text"
            onChange={onInputChange}
            value={data.directions}
            id="directions"
            name="directions"
            placeholder="Directions"
            className="border border-gray-900 p-2 rounded-md"
            required
          />
        </div>
        <div className="mt-5 grid grid-cols-3 gap-2">
          <div className="w-full">
            <h2 className="text-xl font-semibold">Services</h2>
            <div className="w-full">
              <Select
                options={serviceoptions}
                className="w-full border border-gray-900 rounded-md"
                isMulti
                onChange={(selected) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      services: selected.map((item) => item.value),
                    };
                  });
                }}
                required
              />
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-xl font-semibold">Clinic Timings</h2>
            <div className="flex gap-2 w-full ">
              <input
                type="time"
                value={data.timings.starttime}
                name="starttime"
                onChange={onInputChange}
                className="border w-1/2 border-gray-900 p-2 rounded-md"
                required
              />
              <input
                type="time"
                value={data.timings.endtime}
                name="endtime"
                onChange={onInputChange}
                className="border w-1/2 border-gray-900 p-2 rounded-md"
                required
              />
            </div>
          </div>
          <div>
            <h2 className="text-xl font-semibold">Clinic Type</h2>
            <div className="w-full">
              <select
                name="type"
                value={data.type}
                onChange={onInputChange}
                className="border w-full border-gray-900 p-2 rounded-md"
                required
              >
                <option value="MultiSpeciality">MultiSpeciality</option>
              </select>
            </div>
          </div>
        </div>

        <div className="mt-5 flex">
          <div className="w-1/2">
            <h2 className="text-xl font-semibold">Image Upload</h2>
            <div className="flex gap-2 mt-5">
              <input
                type="file"
                className="border border-gray-900 p-2 rounded-md"
                onChange={imagechange}
                accept="image/jpeg, image/png , image/jpg"
                required
              />
              {!loading ? (
                <button
                  onClick={upload}
                  className="bg-[blue] text-white px-3 py-1 rounded-md"
                >
                  Upload
                </button>
              ) : (
                <button
                  disabled
                  className="bg-[blue] text-white px-3 py-1 rounded-md"
                >
                  Uploading...
                </button>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <h2 className="text-xl font-semibold">Clinic Working Days</h2>
            <div className="mt-5">
              <Select
                options={days}
                className="w-3/4 border border-gray-900 rounded-md"
                isMulti
                onChange={(selected) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      workingdays: selected.map((item) => item.value),
                    };
                  });
                }}
                required
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2 className="text-xl font-semibold mt-5">Clinic Description</h2>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="4"
            placeholder="Description"
            onChange={onInputChange}
            value={data.description}
            className="border border-gray-900 p-2 mt-2 rounded-md w-full"
            required
          ></textarea>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold">Staff</h2>
            <button onClick={addStaff}>
              <Plus />
            </button>
          </div>
          <div className="mt-5 flex flex-col gap-2">
            {staff.map((item, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  className="border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const value = e.target.value;
                    setStaff((prev) => {
                      const newStaff = [...prev];
                      newStaff[index].name = value;
                      return newStaff;
                    });
                  }}
                  required
                />
                <input
                  type="text"
                  placeholder="Role"
                  className="border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const value = e.target.value;
                    setStaff((prev) => {
                      const newStaff = [...prev];
                      newStaff[index].role = value;
                      return newStaff;
                    });
                  }}
                  required
                />
                <input
                  type="text"
                  placeholder="Phone"
                  className="border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const value = e.target.value;
                    setStaff((prev) => {
                      const newStaff = [...prev];
                      newStaff[index].phonenumber = value;
                      return newStaff;
                    });
                  }}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-gray-900 p-2 rounded-md"
                  onChange={(e) => {
                    const value = e.target.value;
                    setStaff((prev) => {
                      const newStaff = [...prev];
                      newStaff[index].email = value;
                      return newStaff;
                    });
                  }}
                />
                <button
                  onClick={() => {
                    removeStaff(index);
                  }}
                >
                  <CircleMinus />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="flex mt-5">
          <button
            type="submit"
            className="bg-[blue] text-white px-3 py-1 rounded-md"
          >
            Add Clinic
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddClinic;
